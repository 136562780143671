.mobile-menu .nav-list .mobile-menu-footer {
  flex-flow: column nowrap;
  position: relative;
  min-height: 60vh;
  flex-grow: 1;

  .links {
    margin-bottom: auto;

    .footer-link {
      padding: 0.3rem 1rem;
      margin: 0;

      &:hover {
        background-color: rgba(44, 44, 44, 0.04);
      }
    }
  }

  .social-links {
    margin: 0rem;
  }

  .fld-col {
    justify-content: space-between;
    flex-grow: 1;
  }

  #download-app {
    color: var(--qq-color-green);
  }

  #app-badges {
    padding: 0 3rem;
  }

  .badge {
    position: absolute;
    bottom: 20%;
  }
}
