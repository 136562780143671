// $font-url: 'https://cdn.dontdrivedirty.com/myqq';

/* Roboto font */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  src: local("Roboto Bold"), url("/assets/fonts/roboto/Roboto-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), url("/assets/fonts/roboto/Roboto-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  src: local("Roboto"), url("/assets/fonts/roboto/Roboto-Italic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto"), url("/assets/fonts/roboto/Roboto-Regular.ttf");
  font-display: swap;
}

/* Asap font */
@font-face {
  font-family: "Asap";
  font-style: normal;
  font-weight: 700;
  src: local("Asap Bold"), url("/assets/fonts/asap/Asap-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Asap";
  font-style: italic;
  font-weight: 400;
  src: local("Asap"), url("/assets/fonts/asap/Asap-Italic.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Asap";
  font-style: normal;
  font-weight: 400;
  src: local("Asap"), url("/assets/fonts/asap/Asap-Regular.ttf");
  font-display: swap;
}
