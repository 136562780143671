/**
* Some alternatives for theming buttons.
* We will use 'primary' for the primary/cta button; 'accent' for the secondary/cancel button;
* and 'warn' in the few places where we have a tertiary/alternate cta button.
* If we decide to use 'warn' for warning/dangerous actions, we can create a tertiary button 'color'
* as per workaround mentioned here https://github.com/angular/components/issues/11888#issuecomment-536509515
*/
@use '@angular/material' as mat;

// Palettes
@import "./qq_green_palette";
@import "./qq_orange_palette";

// Themes
$green-primary: mat.m2-define-palette($md-qq-green);
$green-accent: mat.m2-define-palette($md-qq-green, 10, 500, 800);
$green-warn: mat.m2-define-palette($md-qq-orange, lightest, main, darker);
$green-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $green-primary,
      accent: $green-accent,
      warn: $green-warn,
    ),
  )
);

$orange-primary: mat.m2-define-palette($md-qq-orange, main, lighter, darker);
$orange-accent: mat.m2-define-palette($md-qq-orange, lightest, main, darker);
$orange-warn: mat.m2-define-palette($md-qq-green, 10, 500, 800);
$orange-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $orange-primary,
      accent: $orange-accent,
      warn: $orange-warn,
    ),
  )
);

$multi-primary: mat.m2-define-palette($md-qq-orange, main, lighter, darker);
$multi-accent: mat.m2-define-palette($md-qq-green);
$multi-warn: mat.m2-define-palette($md-qq-orange, lightest, main, darker);
$multi-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $multi-primary,
      accent: $multi-accent,
      warn: $multi-warn,
    ),
  )
);

// Mixin
@mixin buttons($theme: multi) {
  @if $theme == green {
    @include mat.all-component-themes($green-theme);
    button.mat-warn,
    a.mat-warn {
      color: var(--cf-secondary-trans);
    }
  } @else if $theme == orange {
    @include mat.all-component-themes($orange-theme);
    button.mat-accent,
    a.mat-accent {
      color: var(--cf-secondary-trans);
    }
    button.mat-warn,
    a.mat-warn {
      color: var(--cf-primary-rev);
    }
  } @else if $theme == multi {
    @include mat.all-component-themes($multi-theme);
    button.mat-warn,
    a.mat-warn {
      color: var(--cf-secondary-trans);
    }
  }
}

// Select Theme
.qq-buttons {
  // changing the argument to the buttons function will apply the corresponding palette
  // to all buttons across the site.
  @include buttons(green); // green, orange, multi (green + orange - default)
}
