.mat-mdc-form-field.mat-form-field {
  min-width: 50px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
.mat-form-field-infix {
  width: auto !important;
  min-width: 50px !important;
}

/**
 * Used for fabs that are fixed to the bottom right of the screen.
 * https://material.io/components/buttons-floating-action-button
 */
button.fixed-fab {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/**
 * Raise the fabs on desktop to clear the footer
 */
@media screen and (min-width: 768px) {
  button.fixed-fab {
    position: sticky;
    margin-bottom: 10px;
    width: min-content;
    bottom: 10px;
  }
}

mat-spinner,
mat-progress-spinner {
  margin: auto;
}

/**
 * Used in the cart-confirm component
 */
.cc-select {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
  .mat-form-field-infix {
    padding: 0.5em 0 0 1em;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
  &.mat-form-field-should-float .mat-form-field-infix {
    padding: 0.5em 0 1em 1em;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
.mat-form-field-outline {
  background-color: var(--cb-base);
}

.mat-mdc-dialog-container.mat-dialog-container {
  padding: 0;
}

.mat-mdc-dialog-container > .ng-star-inserted {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

// Angular nowraps checkbox labels
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
 */
.mat-checkbox-layout,
.mat-checkbox-label {
  white-space: normal !important;
}
