// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

@import "qq_green_palette.scss";
@import "qq_orange_palette.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$fontConfig: (
  display-4:
    mat.m2-define-typography-level(112px, 112px, 300, "Roboto", -0.0134em),
  display-3:
    mat.m2-define-typography-level(56px, 56px, 400, "Roboto", -0.0089em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, "Roboto", 0em),
  display-1: mat.m2-define-typography-level(34px, 40px, 400, "Roboto", 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 400, "Roboto", 0em),
  title: mat.m2-define-typography-level(20px, 32px, 500, "Roboto", 0.0075em),
  subheading-2:
    mat.m2-define-typography-level(16px, 28px, 400, "Roboto", 0.0094em),
  subheading-1:
    mat.m2-define-typography-level(15px, 24px, 500, "Roboto", 0.0067em),
  body-2: mat.m2-define-typography-level(14px, 24px, 500, "Roboto", 0.0179em),
  body-1: mat.m2-define-typography-level(14px, 20px, 400, "Roboto", 0.0179em),
  button: mat.m2-define-typography-level(14px, 14px, 500, "Roboto", 0.0893em),
  caption: mat.m2-define-typography-level(12px, 20px, 400, "Roboto", 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, "Roboto", 1.5px),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-component-typographies($fontConfig);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette($md-qq-green);
$app-accent: mat.m2-define-palette($md-qq-green);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic & QOS specific CSS Variables **/
:root {
  --qq-color-green: #007550;
  --qq-color-red: #ce0e2d;
  --qq-color-yellow: #ffcf42;
  --qq-color-orange: #f79239;
  --qq-color-light: #dadce0;
  --qq-color-medium: #989aa2;

  /** footer text **/
  --footer-text-color: #ffffff;

  /** breakpoints **/
  --breakpoint-mobile: 768px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
