article.dialog-modal,
article.routed-modal {
  .modal-header {
    .close-btn {
      padding: 4px;
      color: var(--cf-primary);

      .myqq-button-icon {
        margin: 0;
      }
    }
  }

  .modal-footer {
    margin: 0;

    > [modal-footer] {
      display: contents;
    }

    .qq-buttons {
      .myqq-button {
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 15px;
        height: 37px;
        min-width: 77px;
        margin: 0 1rem;

        background-color: var(--cb-base);

        &[color="primary"] {
          background-color: var(--cb-tertiary);
          color: var(--cf-base);
        }

        &.myqq-button-disabled {
          background-color: var(--ce-disabled);
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }
  }

  .update-payment-btn {
    height: 68px !important;
  }

  @media screen and (max-width: 767.9px) {
    .modal-header {
      background-color: var(--cb-base) !important;
      color: var(--cf-base) !important;
      text-align: center !important;

      h3 {
        font-size: 20px;
      }

      .close-btn {
        position: absolute;
        left: 0;
        top: 0;
        background-color: transparent;
        padding: 6px;
        height: 32px;

        .mat-icon {
          border-radius: 50%;
          background-color: var(--qq-color-green, green);
          font-size: 18px;
          height: 24px;
          line-height: 24px;
          width: 24px;
        }
      }
    }

    .modal-footer {
      position: absolute;
      top: 0;
      right: 0;
      width: 120px;
      background-color: transparent !important;
      box-shadow: none;
      border: 0;

      .cancel-btn {
        display: none;
      }

      .qq-buttons .myqq-button {
        font-size: 18px;

        &[color="primary"] {
          background-color: transparent !important;
          color: var(--qq-color-green) !important;
          box-shadow: none !important;
        }

        &.myqq-button-disabled {
          background-color: transparent !important;
          color: var(--cf-disabled) !important;
        }
      }
    }
  }
}
