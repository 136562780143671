#testimonial
  .ng-image-slider
  .ng-image-slider-container
  .main
  .main-inner
  .img-div
  .caption {
  background: white;
  color: black;
  margin: 0;
  padding: 10px 10px 0px 40px;
  text-align: left;
  position-area: center;
}
