/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined&display=swap");

/* Material Theme */
@import "./theme/qq_palette.scss";

/* Utility CSS For Layout */
@import "./theme/utility.scss";

/* Alterations of Material Design and Ionic should go in these files */
@import "./theme/material-fudge.scss";
@import "./theme/ionic-fudge.scss";
@import "./theme/normalize.scss";

/* Standalone CSS Classes */
@import "./theme/list.scss";

:root {
  --myqq-header-height: 56px;
}

body {
  font-family: Roboto;
}

.mat-select-value {
  font-family: Roboto;
  font-size: 15px;
}

.mat-option-text {
  font-family: Roboto;
}

.mdc-button__label {
  font-family: Roboto;
  letter-spacing: normal;
  white-space: nowrap;
}

.mdc-tab__text-label {
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .mat-mdc-paginator-range-actions {
    .mat-mdc-paginator-range-label {
      margin: 0 -2px 0 -2px;
    }
  }

  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {
      justify-content: center !important;
    }
  }
}

.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 68px;
  padding: 0 16px;
}

.mat-mdc-button.mat-unthemed {
  padding: 0 16px;
}

.mat-mdc-card-title {
  color: #000000de;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.mdc-list-item.mdc-list-item--with-one-line {
  min-height: 48px;
}

.mdc-switch__icons {
  display: none;
}

.mat-slide-toggle.mat-primary {
  color: var(--primary-color);
}

ion-title.title-default {
  padding-inline-start: 45px;
  padding-inline-end: 45px;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ff-roboto {
  font-family: Roboto;
}

.ff-asap {
  font-family: "Asap";
}

.color-accent {
  color: var(--qq-color-orange);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mat-toolbar {
  background-color: none;
}

footer {
  --border: 0;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
  width: 100%;

  .mat-mdc-raised-button,
  .mat-mdc-button {
    border-radius: 9px;
    height: 56px;
    min-width: 125px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.mat-mdc-button {
  font-size: 16px;
}

.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button {
  font-size: 20px;
}

[hidden] {
  display: none !important;
}

.myqq-fab {
  .myqq-fab-text {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .myqq-fab {
    width: unset !important;
    padding: 0 20px !important;
    border-radius: 30px !important;
    mat-icon {
      margin-right: 10px;
    }
    .myqq-fab-text {
      display: inline;
      letter-spacing: 0.1em;
    }
  }
}

// dialogs

.cdk-overlay-pane.myqq-alert-dialog {
  position: relative !important;

  @media screen and (max-width: 768px) {
    margin: 5%;
  }
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
}

.cdk-overlay-pane.myqq-update-dialog {
  .myqq-button-content {
    margin: auto;
  }
}

.myqq-generic-alert-dialog .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  border-radius: 10px;
}

#consent {
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  position: fixed;
  width: 600px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 15px;
  padding: 20px;
  z-index: 100;
}

.faq-block {
  ul {
    list-style: circle;
    padding: 10px;
    margin: 0;
  }
}
