/**
 * Utility CSS Classes
 */
:root {
  --border-width-0: calc(var(--fill-size) * 0);
  --border-width-1: calc(var(--fill-size) * 1);
  --border-width-2: calc(var(--fill-size) * 2);
  --border-width-3: calc(var(--fill-size) * 4);
  --border-width-4: calc(var(--fill-size) * 8);
  --border-width-5: calc(var(--fill-size) * 16);
  --border-width-6: calc(var(--fill-size) * 32);
  --border-width-7: calc(var(--fill-size) * 64);
}

:root {
  --border-radius-circle: 50%;
  --border-radius-0: calc(var(--radius-size) * 0);
  --border-radius-1: calc(var(--radius-size) * 1);
  --border-radius-2: calc(var(--radius-size) * 2);
  --border-radius-3: calc(var(--radius-size) * 4);
}

/*
Border

Classes for setting the border an element.

Styleguide Border
*/
/*
Border Width

Classes for setting the border width of an element.

Styleguide Border - Width
*/

/*
.bw*-0

Sets the border of element to css var for 0.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-0 - Sets all borders to 0
.bwx-0 - Sets left and right borders to 0
.bwy-0 - Sets top and bottom borders to 0
.bwt-0 - Sets top border to 0
.bwr-0 - Sets right border to 0
.bwb-0 - Sets bottom border to 0
.bwl-0 - Sets left border to 0

Styleguide Border - Width - 0
*/
.bwt-0,
.bwy-0,
.bwa-0 {
  border-top-style: solid;
  border-top-width: var(--border-width-0);
}

.bwr-0,
.bwx-0,
.bwa-0 {
  border-right-style: solid;
  border-right-width: var(--border-width-0);
}

.bwb-0,
.bwy-0,
.bwa-0 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-0);
}

.bwl-0,
.bwx-0,
.bwa-0 {
  border-left-style: solid;
  border-left-width: var(--border-width-0);
}

/*
.bw*-1

Sets the border of element to css var for 1.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-1 - Sets all borders to 1
.bwx-1 - Sets left and right borders to 1
.bwy-1 - Sets top and bottom borders to 1
.bwt-1 - Sets top border to 1
.bwr-1 - Sets right border to 1
.bwb-1 - Sets bottom border to 1
.bwl-1 - Sets left border to 1

Styleguide Border - Width - 1
*/
.bwt-1,
.bwy-1,
.bwa-1 {
  border-top-style: solid;
  border-top-width: var(--border-width-1);
}

.bwr-1,
.bwx-1,
.bwa-1 {
  border-right-style: solid;
  border-right-width: var(--border-width-1);
}

.bwb-1,
.bwy-1,
.bwa-1 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-1);
}

.bwl-1,
.bwx-1,
.bwa-1 {
  border-left-style: solid;
  border-left-width: var(--border-width-1);
}

/*
.bw*-2

Sets the border of element to css var for 2.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-2 - Sets all borders to 2
.bwx-2 - Sets left and right borders to 2
.bwy-2 - Sets top and bottom borders to 2
.bwt-2 - Sets top border to 2
.bwr-2 - Sets right border to 2
.bwb-2 - Sets bottom border to 2
.bwl-2 - Sets left border to 2

Styleguide Border - Width - 2
*/
.bwt-2,
.bwy-2,
.bwa-2 {
  border-top-style: solid;
  border-top-width: var(--border-width-2);
}

.bwr-2,
.bwx-2,
.bwa-2 {
  border-right-style: solid;
  border-right-width: var(--border-width-2);
}

.bwb-2,
.bwy-2,
.bwa-2 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-2);
}

.bwl-2,
.bwx-2,
.bwa-2 {
  border-left-style: solid;
  border-left-width: var(--border-width-2);
}

/*
.bw*-3

Sets the border of element to css var for 3.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-3 - Sets all borders to 3
.bwx-3 - Sets left and right borders to 3
.bwy-3 - Sets top and bottom borders to 3
.bwt-3 - Sets top border to 3
.bwr-3 - Sets right border to 3
.bwb-3 - Sets bottom border to 3
.bwl-3 - Sets left border to 3

Styleguide Border - Width - 3
*/
.bwt-3,
.bwy-3,
.bwa-3 {
  border-top-style: solid;
  border-top-width: var(--border-width-3);
}

.bwr-3,
.bwx-3,
.bwa-3 {
  border-right-style: solid;
  border-right-width: var(--border-width-3);
}

.bwb-3,
.bwy-3,
.bwa-3 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-3);
}

.bwl-3,
.bwx-3,
.bwa-3 {
  border-left-style: solid;
  border-left-width: var(--border-width-3);
}

/*
.bw*-4

Sets the border of element to css var for 4.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-4 - Sets all borders to 4
.bwx-4 - Sets left and right borders to 4
.bwy-4 - Sets top and bottom borders to 4
.bwt-4 - Sets top border to 4
.bwr-4 - Sets right border to 4
.bwb-4 - Sets bottom border to 4
.bwl-4 - Sets left border to 4

Styleguide Border - Width - 4
*/
.bwt-4,
.bwy-4,
.bwa-4 {
  border-top-style: solid;
  border-top-width: var(--border-width-4);
}

.bwr-4,
.bwx-4,
.bwa-4 {
  border-right-style: solid;
  border-right-width: var(--border-width-4);
}

.bwb-4,
.bwy-4,
.bwa-4 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-4);
}

.bwl-4,
.bwx-4,
.bwa-4 {
  border-left-style: solid;
  border-left-width: var(--border-width-4);
}

/*
.bw*-5

Sets the border of element to css var for 5.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-5 - Sets all borders to 5
.bwx-5 - Sets left and right borders to 5
.bwy-5 - Sets top and bottom borders to 5
.bwt-5 - Sets top border to 5
.bwr-5 - Sets right border to 5
.bwb-5 - Sets bottom border to 5
.bwl-5 - Sets left border to 5

Styleguide Border - Width - 5
*/
.bwt-5,
.bwy-5,
.bwa-5 {
  border-top-style: solid;
  border-top-width: var(--border-width-5);
}

.bwr-5,
.bwx-5,
.bwa-5 {
  border-right-style: solid;
  border-right-width: var(--border-width-5);
}

.bwb-5,
.bwy-5,
.bwa-5 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-5);
}

.bwl-5,
.bwx-5,
.bwa-5 {
  border-left-style: solid;
  border-left-width: var(--border-width-5);
}

/*
.bw*-6

Sets the border of element to css var for 6.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-6 - Sets all borders to 6
.bwx-6 - Sets left and right borders to 6
.bwy-6 - Sets top and bottom borders to 6
.bwt-6 - Sets top border to 6
.bwr-6 - Sets right border to 6
.bwb-6 - Sets bottom border to 6
.bwl-6 - Sets left border to 6

Styleguide Border - Width - 6
*/
.bwt-6,
.bwy-6,
.bwa-6 {
  border-top-style: solid;
  border-top-width: var(--border-width-6);
}

.bwr-6,
.bwx-6,
.bwa-6 {
  border-right-style: solid;
  border-right-width: var(--border-width-6);
}

.bwb-6,
.bwy-6,
.bwa-6 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-6);
}

.bwl-6,
.bwx-6,
.bwa-6 {
  border-left-style: solid;
  border-left-width: var(--border-width-6);
}

/*
.bw*-7

Sets the border of element to css var for 7.

Markup:
<div class="{{modifier_class}}">A div</div>

.bwa-7 - Sets all borders to 7
.bwx-7 - Sets left and right borders to 7
.bwy-7 - Sets top and bottom borders to 7
.bwt-7 - Sets top border to 7
.bwr-7 - Sets right border to 7
.bwb-7 - Sets bottom border to 7
.bwl-7 - Sets left border to 7

Styleguide Border - Width - 7
*/
.bwt-7,
.bwy-7,
.bwa-7 {
  border-top-style: solid;
  border-top-width: var(--border-width-7);
}

.bwr-7,
.bwx-7,
.bwa-7 {
  border-right-style: solid;
  border-right-width: var(--border-width-7);
}

.bwb-7,
.bwy-7,
.bwa-7 {
  border-bottom-style: solid;
  border-bottom-width: var(--border-width-7);
}

.bwl-7,
.bwx-7,
.bwa-7 {
  border-left-style: solid;
  border-left-width: var(--border-width-7);
}

/*
Border Style

Classes for setting the border style of an element.

Styleguide Border - Style
*/
/*
.bs-none

Sets the border style of an element to none

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bs-none - Sets border-style to none

Styleguide Border - Style - none
*/
.bs-none {
  border-style: none;
}

/*
.bs-dotted

Sets the border style of an element to dotted

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bs-dotted - Sets border-style to dotted

Styleguide Border - Style - dotted
*/
.bs-dotted {
  border-style: dotted;
}

/*
.bs-dashed

Sets the border style of an element to dashed

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bs-dashed - Sets border-style to dashed

Styleguide Border - Style - dashed
*/
.bs-dashed {
  border-style: dashed;
}

/*
.bs-solid

Sets the border style of an element to solid

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bs-solid - Sets border-style to solid

Styleguide Border - Style - solid
*/
.bs-solid {
  border-style: solid;
}

/*
Border Radius

Classes for setting the border radius of an element.

Styleguide Border - Radius
*/
/*
.br*-c

Sets the border radius of an element to 50%

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bra-c - Sets border radius for all corners to 50%
.brt-c - Sets border radius for top corners to 50%
.brr-c - Sets border radius for right corners to 50%
.brb-c - Sets border radius for bottom corners to 50%
.brl-c - Sets border radius for left corners to 50%
.brtl-c - Sets border radius for top left corner to 50%
.brtr-c - Sets border radius for top right corner to 50%
.brbr-c - Sets border radius for bottom right corner to 50%
.brbl-c - Sets border radius for bottom left corners to 50%

Styleguide Border - Radius - Circle
*/
.bra-c {
  border-radius: var(--border-radius-circle);
}

.brtl-c,
.brt-c,
.brl-c {
  border-top-left-radius: var(--border-radius-circle);
}

.brtr-c,
.brt-c,
.brr-c {
  border-top-right-radius: var(--border-radius-circle);
}

.brbl-c,
.brb-c,
.brl-c {
  border-bottom-left-radius: var(--border-radius-circle);
}

.brbr-c,
.brb-c,
.brr-c {
  border-bottom-right-radius: var(--border-radius-circle);
}

/*
.br*-0

Sets the border style of an element to 0

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bra-0 - Sets border radius for all corners to 0
.brt-0 - Sets border radius for top corners to 0
.brr-0 - Sets border radius for right corners to 0
.brb-0 - Sets border radius for bottom corners to 0
.brl-0 - Sets border radius for left corners to 0
.brtl-0 - Sets border radius for top left corner to 0
.brtr-0 - Sets border radius for top right corner to 0
.brbr-0 - Sets border radius for bottom right corner to 0
.brbl-0 - Sets border radius for bottom left corners to 0

Styleguide Border - Radius - Circle
*/
.bra-0 {
  border-radius: var(--border-radius-0);
}

.brtl-0,
.brt-0,
.brl-0 {
  border-top-left-radius: var(--border-radius-0);
}

.brtr-0,
.brt-0,
.brr-0 {
  border-top-right-radius: var(--border-radius-0);
}

.brbl-0,
.brb-0,
.brl-0 {
  border-bottom-left-radius: var(--border-radius-0);
}

.brbr-0,
.brb-0,
.brr-0 {
  border-bottom-right-radius: var(--border-radius-0);
}

/*
.br*-1

Sets the border style of an element to 1

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bra-1 - Sets border radius for all corners to 1
.brt-1 - Sets border radius for top corners to 1
.brr-1 - Sets border radius for right corners to 1
.brb-1 - Sets border radius for bottom corners to 1
.brl-1 - Sets border radius for left corners to 1
.brtl-1 - Sets border radius for top left corner to 1
.brtr-1 - Sets border radius for top right corner to 1
.brbr-1 - Sets border radius for bottom right corner to 1
.brbl-1 - Sets border radius for bottom left corners to 1

Styleguide Border - Radius - Circle
*/
.bra-1 {
  border-radius: var(--border-radius-1);
}

.brtl-1,
.brt-1,
.brl-1 {
  border-top-left-radius: var(--border-radius-1);
}

.brtr-1,
.brt-1,
.brr-1 {
  border-top-right-radius: var(--border-radius-1);
}

.brbl-1,
.brb-1,
.brl-1 {
  border-bottom-left-radius: var(--border-radius-1);
}

.brbr-1,
.brb-1,
.brr-1 {
  border-bottom-right-radius: var(--border-radius-1);
}

/*
.br*-2

Sets the border style of an element to 2

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bra-2 - Sets border radius for all corners to 2
.brt-2 - Sets border radius for top corners to 2
.brr-2 - Sets border radius for right corners to 2
.brb-2 - Sets border radius for bottom corners to 2
.brl-2 - Sets border radius for left corners to 2
.brtl-2 - Sets border radius for top left corner to 2
.brtr-2 - Sets border radius for top right corner to 2
.brbr-2 - Sets border radius for bottom right corner to 2
.brbl-2 - Sets border radius for bottom left corners to 2

Styleguide Border - Radius - Circle
*/
.bra-2 {
  border-radius: var(--border-radius-2);
}

.brtl-2,
.brt-2,
.brl-2 {
  border-top-left-radius: var(--border-radius-2);
}

.brtr-2,
.brt-2,
.brr-2 {
  border-top-right-radius: var(--border-radius-2);
}

.brbl-2,
.brb-2,
.brl-2 {
  border-bottom-left-radius: var(--border-radius-2);
}

.brbr-2,
.brb-2,
.brr-2 {
  border-bottom-right-radius: var(--border-radius-2);
}

/*
.br*-3

Sets the border style of an element to 3

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.bra-3 - Sets border radius for all corners to 3
.brt-3 - Sets border radius for top corners to 3
.brr-3 - Sets border radius for right corners to 3
.brb-3 - Sets border radius for bottom corners to 3
.brl-3 - Sets border radius for left corners to 3
.brtl-3 - Sets border radius for top left corner to 3
.brtr-3 - Sets border radius for top right corner to 3
.brbr-3 - Sets border radius for bottom right corner to 3
.brbl-3 - Sets border radius for bottom left corners to 3

Styleguide Border - Radius - Circle
*/
.bra-3 {
  border-radius: var(--border-radius-3);
}

.brtl-3,
.brt-3,
.brl-3 {
  border-top-left-radius: var(--border-radius-3);
}

.brtr-3,
.brt-3,
.brr-3 {
  border-top-right-radius: var(--border-radius-3);
}

.brbl-3,
.brb-3,
.brl-3 {
  border-bottom-left-radius: var(--border-radius-3);
}

.brbr-3,
.brb-3,
.brr-3 {
  border-bottom-right-radius: var(--border-radius-3);
}

/**
  Theme Structure
  A color theme consists of 5 piece of information.
    - A name
    - A foreground color
    - A background
    - An edge color (border)
    - A grouping of psuedo classes to render
 */
:root {
  --cf-base: #111111;
  --cb-base: #ffffff;
  --ce-base: #111111;
  --cf-light: #454545;
  --cb-light: #dedede;
  --ce-light: #cdcdcd;
  --cf-dark: #efefef;
  --cb-dark: #898989;
  --ce-dark: #343434;
  --cf-primary: #ffffff;
  --cb-primary: #007550;
  --ce-primary: #007550;
  --cf-primary-rev: #007550;
  --cb-primary-rev: #ffffff;
  --ce-primary-rev: #007550;
  --cf-secondary: #ffffff;
  --cb-secondary: #f79239;
  --ce-secondary: #f79239;
  --cf-secondary-trans: #f79239;
  --cb-secondary-trans: rgba(255, 255, 255, 0.7);
  --ce-secondary-trans: #f79239;
  --cf-tertiary: #000000;
  --cb-tertiary: #ffcf42;
  --ce-tertiary: #ffcf42;
  --cf-error: #ce0e2d;
  --cb-error: #ffdfdf;
  --ce-error: #ce0e2d;
  --cf-error-rev: #ffffff;
  --cb-error-rev: #ce0e2d;
  --ce-error-rev: #ce0e2d;
  --cf-disabled: #c7c7c7;
  --cb-disabled: #eeeeee;
  --ce-disabled: #c7c7c7;
  --tertiary-inactive-grey: #f4f4f4;
  --tertiary-inactive-grey-text: #6b6b6b;
  --tertiary-active-green: #e8f8f3;
  --cf-dsk: #007550;
  --cb-dsk: #f9f9f9;
  --ce-dsk: #f9f9f9;
  --cf-blue: #1a73e9;
  --cf-ceramic: #d89ff0;
  --cb-ceramic: #74348f;
  --cb-cc: #2f4f4f;
}

/**
  Theme Structure
  A color theme consists of 5 piece of information.
    - A name
    - A foreground color
    - A background
    - An edge color (border)
    - A grouping of psuedo classes to render
 */
/*
Color

Classes for setting the foreground and background color of an element.

Styleguide Color
*/
/*
Color Foreground

Classes for setting the color an element.

Styleguide Color - Foreground
*/
/*
Color Background

Classes for setting the background-color an element.

Styleguide Color - Background
*/
/*
Color Theme

Classes for setting the color theme an element.

Styleguide Color - Theme
*/
/*
.ct-base

Sets the color theme of an element (color, background, border-color).

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-base - Sets color, background, and border-color
.cf-base - Sets color to var(--cf-base)
.cb-base - Sets background to var(--cb-base)
.ce-base - Sets border-color to var(--ce-base)

Styleguide Border - Style - base
*/
.ct-base {
  color: var(--cf-base);
  background: var(--cb-base);
  border-color: var(--ce-base);
}

.ct-light {
  color: var(--cf-light);
  background: var(--cb-light);
  border-color: var(--ce-light);
}

.ct-dark {
  color: var(--cf-dark);
  background: var(--cb-dark);
  border-color: var(--ce-dark);
}

.ct-dsk {
  color: var(--cf-dsk);
  background: var(--cb-dsk);
  border-color: var(--ce-dsk);
}

/*
.ct-primary

Sets the color theme of an element (color, background, border-color).

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-primary - Sets color, background, and border-color
.cf-primary - Sets color to var(--cf-primary)
.cb-primary - Sets background to var(--cb-primary)
.ce-primary - Sets border-color to var(--ce-primary)

Styleguide Border - Style - primary
*/
.ct-primary {
  color: var(--cf-primary) !important;
  background: var(--cb-primary) !important;
  border-color: var(--ce-primary) !important;
}

.ct-primary-rev {
  color: var(--cf-primary-rev) !important;
  background: var(--cb-primary-rev) !important;
  border-color: var(--ce-primary-rev) !important;
}

/*
.ct-secondary

Sets the color theme of an element (color, background, border-color).

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-secondary - Sets color, background, and border-color
.cf-secondary - Sets color to var(--cf-secondary)
.cb-secondary - Sets background to var(--cb-secondary)
.ce-secondary - Sets border-color to var(--ce-secondary)

Styleguide Border - Style - secondary
*/
.ct-secondary {
  color: var(--cf-secondary) !important;
  background: var(--cb-secondary) !important;
  border-color: var(--ce-secondary) !important;
}

.ct-tertiary {
  color: var(--cf-tertiary) !important;
  background: var(--cb-tertiary) !important;
  border-color: var(--ce-tertiary) !important;
}

.ct-secondary-trans {
  color: var(--cf-secondary-trans) !important;
  background: var(--cb-secondary-trans) !important;
  border-color: var(--ce-secondary-trans) !important;
}

/*
.ct-error

Sets the color theme of an element (color, background, border-color).

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-error - Sets color, background, and border-color
.cf-error - Sets color to var(--cf-error)
.cb-error - Sets background to var(--cb-error)
.ce-error - Sets border-color to var(--ce-error)

Styleguide Border - Style - error
*/
.ct-error {
  color: var(--cf-error);
  background: var(--cb-error);
  border-color: var(--ce-error);
}

/*
.ct-error-rev

Sets the color theme of an element (color, background, border-color).

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-error-rev - Sets color, background, and border-color
.cf-error-rev - Sets color to var(--cf-error)
.cb-error-rev - Sets background to var(--cb-error)
.ce-error-rev - Sets border-color to var(--ce-error)

Styleguide Border - Style - error - reverse
*/
.ct-error-rev {
  color: var(--cf-error-rev);
  background: var(--cb-error-rev);
  border-color: var(--ce-error-rev);
}

/*
.ct-error-on-invalid

Sets the color theme of an element (color, background, border-color) on invalid.

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-error-on-invalid - Sets color, background, and border-color on invalid
.cf-error-on-invalid - Sets color to var(--cf-error) on invalid
.cb-error-on-invalid - Sets background to var(--cb-error) on invalid
.ce-error-on-invalid - Sets border-color to var(--ce-error) on invalid

Styleguide Border - Style - error - invalid
*/
.ct-error-on-invalid:invalid {
  color: var(--cf-error);
  background: var(--cb-error);
  border-color: var(--ce-error);
}

/*
.ct-disabled

Sets the color theme of an element (color, background, border-color).

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-disabled - Sets color, background, and border-color
.cf-disabled - Sets color to var(--cf-disabled)
.cb-disabled - Sets background to var(--cb-disabled)
.ce-disabled - Sets border-color to var(--ce-disabled)

Styleguide Border - Style - disabled
*/
.ct-disabled {
  color: var(--cf-disabled);
  background: var(--cb-disabled);
  border-color: var(--ce-disabled);
}

/*
.ct-disabled-on-disabled

Sets the color theme of an element (color, background, border-color) on disabled.

Markup:
<div class="bwa-1 pwa-1 {{modifier_class}}">A div</div>

.ct-disabled-on-disabled - Sets color, background, and border-color on disabled
.cf-disabled-on-disabled - Sets color to var(--cf-disabled) on disabled
.cb-disabled-on-disabled - Sets background to var(--cb-disabled) on disabled
.ce-disabled-on-disabled - Sets border-color to var(--ce-disabled) on disabled

Styleguide Border - Style - disabled - disabled
*/
.ct-disabled-on-disabled:disabled {
  color: var(--cf-disabled);
  background: var(--cb-disabled);
  border-color: var(--ce-disabled);
}

.cf-base {
  color: var(--cf-base);
}

.cb-base {
  background: var(--cb-base);
}

.ce-base {
  border-color: var(--ce-base);
}

.cf-light {
  color: var(--cf-light);
}

.cb-light {
  background: var(--cb-light);
}

.ce-light {
  border-color: var(--ce-light);
}

.cf-dark {
  color: var(--cf-dark);
}

.cb-dark {
  background: var(--cb-dark);
}

.ce-dark {
  border-color: var(--ce-dark);
}

.cf-primary {
  color: var(--cf-primary);
}

.cb-primary {
  background: var(--cb-primary);
}

.ce-primary {
  border-color: var(--ce-primary);
}

.cf-primary-rev {
  color: var(--cf-primary-rev);
}

.cb-primary-rev {
  background: var(--cb-primary-rev);
}

.ce-primary-rev {
  border-color: var(--ce-primary-rev);
}

.cf-secondary {
  color: var(--cf-secondary);
}

.cb-secondary {
  background: var(--cb-secondary);
}

.ce-secondary {
  border-color: var(--ce-secondary);
}

.cf-secondary-trans {
  color: var(--cf-secondary-trans);
}

.cb-secondary-trans {
  background: var(--cb-secondary-trans);
}

.ce-secondary-trans {
  border-color: var(--ce-secondary-trans);
}

.cf-tertiary {
  color: var(--cf-tertiary);
}

.cb-tertiary {
  background: var(--cb-tertiary) !important;
}

.ce-tertiary {
  border-color: var(--ce-tertiary);
}

.cf-error {
  color: var(--cf-error);
}

.cb-error {
  background: var(--cb-error);
}

.ce-error {
  border-color: var(--ce-error);
}

.cf-error-on-invalid:invalid {
  color: var(--cf-error);
}

.cb-error-on-invalid:invalid {
  background: var(--cb-error);
}

.ce-error-on-invalid:invalid {
  border-color: var(--ce-error);
}

.cf-disabled {
  color: var(--cf-disabled);
}

.cb-disabled {
  background: var(--cb-disabled);
}

.ce-disabled {
  border-color: var(--ce-disabled);
}

.cf-disabled-on-disabled:disabled {
  color: var(--cf-disabled);
}

.cb-disabled-on-disabled:disabled {
  background: var(--cb-disabled);
}

.ce-disabled-on-disabled:disabled {
  border-color: var(--ce-disabled);
}

.cf-inherit,
.ct-inherit {
  color: inherit;
}

.cb-inherit,
.ct-inherit {
  background: inherit;
}

.ce-inherit,
.ct-inherit {
  border-color: inherit;
}

/*
Cursor

Classes for setting the mouse cursor while over an element.

Styleguide Cursor
*/
/*
.crsr-auto

Sets the of cursor over an element to auto.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-auto - Sets cursor to auto

Styleguide Cursor - auto
*/
.crsr-auto {
  cursor: auto;
}

/*
.crsr-default

Sets the of cursor over an element to default.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-default - Sets cursor to default

Styleguide Cursor - default
*/
.crsr-default {
  cursor: default;
}

/*
.crsr-none

Sets the of cursor over an element to none.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-none - Sets cursor to none

Styleguide Cursor - none
*/
.crsr-none {
  cursor: none;
}

/*
.crsr-context-menu

Sets the of cursor over an element to context-menu.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-context-menu - Sets cursor to context-menu

Styleguide Cursor - context-menu
*/
.crsr-context-menu {
  cursor: context-menu;
}

/*
.crsr-help

Sets the of cursor over an element to help.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-help - Sets cursor to help

Styleguide Cursor - help
*/
.crsr-help {
  cursor: help;
}

/*
.crsr-pointer

Sets the of cursor over an element to pointer.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-pointer - Sets cursor to pointer

Styleguide Cursor - pointer
*/
.crsr-pointer {
  cursor: pointer;
}

/*
.crsr-progress

Sets the of cursor over an element to progress.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-progress - Sets cursor to progress

Styleguide Cursor - progress
*/
.crsr-progress {
  cursor: progress;
}

/*
.crsr-wait

Sets the of cursor over an element to wait.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-wait - Sets cursor to wait

Styleguide Cursor - wait
*/
.crsr-wait {
  cursor: wait;
}

/*
.crsr-cell

Sets the of cursor over an element to cell.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-cell - Sets cursor to cell

Styleguide Cursor - cell
*/
.crsr-cell {
  cursor: cell;
}

/*
.crsr-crosshair

Sets the of cursor over an element to crosshair.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-crosshair - Sets cursor to crosshair

Styleguide Cursor - crosshair
*/
.crsr-crosshair {
  cursor: crosshair;
}

/*
.crsr-text

Sets the of cursor over an element to text.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-text - Sets cursor to text

Styleguide Cursor - text
*/
.crsr-text {
  cursor: text;
}

/*
.crsr-vertical-text

Sets the of cursor over an element to vertical-text.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-vertical-text - Sets cursor to vertical-text

Styleguide Cursor - vertical-text
*/
.crsr-vertical-text {
  cursor: vertical-text;
}

/*
.crsr-alias

Sets the of cursor over an element to alias.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-alias - Sets cursor to alias

Styleguide Cursor - alias
*/
.crsr-alias {
  cursor: alias;
}

/*
.crsr-copy

Sets the of cursor over an element to copy.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-copy - Sets cursor to copy

Styleguide Cursor - copy
*/
.crsr-copy {
  cursor: copy;
}

/*
.crsr-move

Sets the of cursor over an element to move.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-move - Sets cursor to move

Styleguide Cursor - move
*/
.crsr-move {
  cursor: move;
}

/*
.crsr-no-drop

Sets the of cursor over an element to no-drop.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-no-drop - Sets cursor to no-drop

Styleguide Cursor - no-drop
*/
.crsr-no-drop {
  cursor: no-drop;
}

/*
.crsr-not-allowed

Sets the of cursor over an element to not-allowed.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-not-allowed - Sets cursor to not-allowed

Styleguide Cursor - not-allowed
*/
.crsr-not-allowed {
  cursor: not-allowed;
}

/*
.crsr-e-resize

Sets the of cursor over an element to e-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-e-resize - Sets cursor to e-resize

Styleguide Cursor - e-resize
*/
.crsr-e-resize {
  cursor: e-resize;
}

/*
.crsr-n-resize

Sets the of cursor over an element to n-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-n-resize - Sets cursor to n-resize

Styleguide Cursor - n-resize
*/
.crsr-n-resize {
  cursor: n-resize;
}

/*
.crsr-ne-resize

Sets the of cursor over an element to ne-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-ne-resize - Sets cursor to ne-resize

Styleguide Cursor - ne-resize
*/
.crsr-ne-resize {
  cursor: ne-resize;
}

/*
.crsr-nw-resize

Sets the of cursor over an element to nw-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-nw-resize - Sets cursor to nw-resize

Styleguide Cursor - nw-resize
*/
.crsr-nw-resize {
  cursor: nw-resize;
}

/*
.crsr-s-resize

Sets the of cursor over an element to s-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-s-resize - Sets cursor to s-resize

Styleguide Cursor - s-resize
*/
.crsr-s-resize {
  cursor: s-resize;
}

/*
.crsr-se-resize

Sets the of cursor over an element to se-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-se-resize - Sets cursor to se-resize

Styleguide Cursor - se-resize
*/
.crsr-se-resize {
  cursor: se-resize;
}

/*
.crsr-sw-resize

Sets the of cursor over an element to sw-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-sw-resize - Sets cursor to sw-resize

Styleguide Cursor - sw-resize
*/
.crsr-sw-resize {
  cursor: sw-resize;
}

/*
.crsr-w-resize

Sets the of cursor over an element to w-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-w-resize - Sets cursor to w-resize

Styleguide Cursor - w-resize
*/
.crsr-w-resize {
  cursor: w-resize;
}

/*
.crsr-ew-resize

Sets the of cursor over an element to ew-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-ew-resize - Sets cursor to ew-resize

Styleguide Cursor - ew-resize
*/
.crsr-ew-resize {
  cursor: ew-resize;
}

/*
.crsr-ns-resize

Sets the of cursor over an element to ns-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-ns-resize - Sets cursor to ns-resize

Styleguide Cursor - ns-resize
*/
.crsr-ns-resize {
  cursor: ns-resize;
}

/*
.crsr-nesw-resize

Sets the of cursor over an element to nesw-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-nesw-resize - Sets cursor to nesw-resize

Styleguide Cursor - nesw-resize
*/
.crsr-nesw-resize {
  cursor: nesw-resize;
}

/*
.crsr-nwse-resize

Sets the of cursor over an element to nwse-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-nwse-resize - Sets cursor to nwse-resize

Styleguide Cursor - nwse-resize
*/
.crsr-nwse-resize {
  cursor: nwse-resize;
}

/*
.crsr-col-resize

Sets the of cursor over an element to col-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-col-resize - Sets cursor to col-resize

Styleguide Cursor - col-resize
*/
.crsr-col-resize {
  cursor: col-resize;
}

/*
.crsr-row-resize

Sets the of cursor over an element to row-resize.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-row-resize - Sets cursor to row-resize

Styleguide Cursor - row-resize
*/
.crsr-row-resize {
  cursor: row-resize;
}

/*
.crsr-all-scroll

Sets the of cursor over an element to all-scroll.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-all-scroll - Sets cursor to all-scroll

Styleguide Cursor - all-scroll
*/
.crsr-all-scroll {
  cursor: all-scroll;
}

/*
.crsr-zoom-in

Sets the of cursor over an element to zoom-in.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-zoom-in - Sets cursor to zoom-in

Styleguide Cursor - zoom-in
*/
.crsr-zoom-in {
  cursor: zoom-in;
}

/*
.crsr-zoom-out

Sets the of cursor over an element to zoom-out.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-zoom-out - Sets cursor to zoom-out

Styleguide Cursor - zoom-out
*/
.crsr-zoom-out {
  cursor: zoom-out;
}

/*
.crsr-grab

Sets the of cursor over an element to grab.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-grab - Sets cursor to grab

Styleguide Cursor - grab
*/
.crsr-grab {
  cursor: grab;
}

/*
.crsr-grabbing

Sets the of cursor over an element to grabbing.

Markup:
<div class="{{modifier_class}}">A div</div>

.crsr-grabbing - Sets cursor to grabbing

Styleguide Cursor - grabbing
*/
.crsr-grabbing {
  cursor: grabbing;
}

:root {
  --breakpoint-sm: 340px;
}

:root {
  --base-size: 16px;
  --font-size: calc(var(--base-size) / 1);
  --fill-size: calc(var(--base-size) / 16);
  --radius-size: calc(var(--base-size) / 4);
}

/*
Display

Classes for setting the display property of an element.

Styleguide Display
*/
/*
.ds-in

Sets the display property of an element to inline.

Markup:
<div class="{{modifier_class}}">A div</div>

.ds-in - Sets diplay to inline

Styleguide Display - in
*/
.ds-in {
  display: inline;
}

/*
.ds-ib

Sets the display property of an element to inline-block.

Markup:
<div class="{{modifier_class}}">A div</div>

.ds-ib - Sets diplay to inline-block

Styleguide Display - ib
*/
.ds-ib {
  display: inline-block;
}

/*
.ds-bl

Sets the display property of an element to block.

Markup:
<div class="{{modifier_class}}">A div</div>

.ds-bl - Sets diplay to block

Styleguide Display - bl
*/
.ds-bl {
  display: block;
}

/*
.ds-fl

Sets the display property of an element to flex.

Markup:
<div class="{{modifier_class}}">A div</div>

.ds-fl - Sets diplay to flex

Styleguide Display - fl
*/
.ds-fl {
  display: flex;
}

/*
.ds-gr

Sets the display property of an element to grid.

Markup:
<div class="{{modifier_class}}">A div</div>

.ds-gr - Sets diplay to grid

Styleguide Display - gr
*/
.ds-gr {
  display: grid;
}

/*
.ds-no

Sets the display property of an element to none.

Markup:
<div class="{{modifier_class}}">A div</div>

.ds-no - Sets diplay to none

Styleguide Display - no
*/
.ds-no {
  display: none;
}

@media (min-width: 340px) {
  /*
  .ds-sm-in

  Sets the display property of an element to inline on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ds-sm-in - Sets diplay to inline on sm

  Styleguide Display - in - sm
  */
  .ds-sm-in {
    display: inline;
  }

  /*
  .ds-sm-ib

  Sets the display property of an element to inline-block on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ds-sm-ib - Sets diplay to inline-block on sm

  Styleguide Display - ib - sm
  */
  .ds-sm-ib {
    display: inline-block;
  }

  /*
  .ds-sm-bl

  Sets the display property of an element to block on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ds-sm-bl - Sets diplay to block on sm

  Styleguide Display - bl - sm
  */
  .ds-sm-bl {
    display: block;
  }

  /*
  .ds-sm-fl

  Sets the display property of an element to flex on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ds-sm-fl - Sets diplay to flex on sm

  Styleguide Display - fl - sm
  */
  .ds-sm-fl {
    display: flex;
  }

  /*
  .ds-sm-gr

  Sets the display property of an element to grid on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ds-sm-gr - Sets diplay to grid on sm

  Styleguide Display - gr - sm
  */
  .ds-sm-gr {
    display: grid;
  }

  /*
  .ds-sm-no

  Sets the display property of an element to none on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ds-sm-no - Sets diplay to none on sm

  Styleguide Display - no - sm
  */
  .ds-sm-no {
    display: none;
  }
}
/*
Flex

Classes for setting the flex properties of an element.

Styleguide Flex
*/
/*
Flex Direction

Classes for turning on flex and setting the flex direction of an element.

Styleguide Flex - Direction
*/
/*
.fld-row

Sets display: flex and sets flex-direction of an element to row.

Markup:
<div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

.fld-row - Sets flex-direction to row

Styleguide Flex - Direction - row
*/
.fld-row {
  display: flex;
  flex-direction: row;
}

/*
.fld-col

Sets display: flex and sets flex-direction of an element to column.

Markup:
<div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

.fld-col - Sets flex-direction to column

Styleguide Flex - Direction - col
*/
.fld-col {
  display: flex;
  flex-direction: column;
}

/*
.fld-rowr

Sets display: flex and sets flex-direction of an element to row-reverse.

Markup:
<div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

.fld-rowr - Sets flex-direction to row-reverse

Styleguide Flex - Direction - rowr
*/
.fld-rowr {
  display: flex;
  flex-direction: row-reverse;
}

/*
.fld-colr

Sets display: flex and sets flex-direction of an element to column-reverse.

Markup:
<div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

.fld-colr - Sets flex-direction to column-reverse

Styleguide Flex - Direction - colr
*/
.fld-colr {
  display: flex;
  flex-direction: column-reverse;
}

/*
Flex Gaps

Classes for creating gaps between flex children based on margin sizes.

Styleguide Flex - Gaps
*/
/*
.flg-0

Sets the margin of all children except last to margin var for 0.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-0 - Sets gaps to margin var for 0

Styleguide Flex - Gaps - row
*/
.fld-row.flg-0 > *:not(:last-child) {
  margin-right: var(--margin-width-0);
}

/*
.flg-0

Sets the margin of all children except last to margin var for 0.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-0 - Sets gaps to margin var for 0

Styleguide Flex - Gaps - col
*/
.fld-col.flg-0 > *:not(:last-child) {
  margin-bottom: var(--margin-width-0);
}

/*
.flg-0

Sets the margin of all children except last to margin var for 0.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-0 - Sets gaps to margin var for 0

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-0 > *:not(:last-child) {
  margin-left: var(--margin-width-0);
}

/*
.flg-0

Sets the margin of all children except last to margin var for 0.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-0 - Sets gaps to margin var for 0

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-0 > *:not(:last-child) {
  margin-top: var(--margin-width-0);
}

/*
.flg-1

Sets the margin of all children except last to margin var for 1.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-1 - Sets gaps to margin var for 1

Styleguide Flex - Gaps - row
*/
.fld-row.flg-1 > *:not(:last-child) {
  margin-right: var(--margin-width-1);
}

/*
.flg-1

Sets the margin of all children except last to margin var for 1.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-1 - Sets gaps to margin var for 1

Styleguide Flex - Gaps - col
*/
.fld-col.flg-1 > *:not(:last-child) {
  margin-bottom: var(--margin-width-1);
}

/*
.flg-1

Sets the margin of all children except last to margin var for 1.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-1 - Sets gaps to margin var for 1

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-1 > *:not(:last-child) {
  margin-left: var(--margin-width-1);
}

/*
.flg-1

Sets the margin of all children except last to margin var for 1.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-1 - Sets gaps to margin var for 1

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-1 > *:not(:last-child) {
  margin-top: var(--margin-width-1);
}

/*
.flg-2

Sets the margin of all children except last to margin var for 2.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-2 - Sets gaps to margin var for 2

Styleguide Flex - Gaps - row
*/
.fld-row.flg-2 > *:not(:last-child) {
  margin-right: var(--margin-width-2);
}

/*
.flg-2

Sets the margin of all children except last to margin var for 2.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-2 - Sets gaps to margin var for 2

Styleguide Flex - Gaps - col
*/
.fld-col.flg-2 > *:not(:last-child) {
  margin-bottom: var(--margin-width-2);
}

/*
.flg-2

Sets the margin of all children except last to margin var for 2.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-2 - Sets gaps to margin var for 2

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-2 > *:not(:last-child) {
  margin-left: var(--margin-width-2);
}

/*
.flg-2

Sets the margin of all children except last to margin var for 2.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-2 - Sets gaps to margin var for 2

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-2 > *:not(:last-child) {
  margin-top: var(--margin-width-2);
}

/*
.flg-3

Sets the margin of all children except last to margin var for 3.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-3 - Sets gaps to margin var for 3

Styleguide Flex - Gaps - row
*/
.fld-row.flg-3 > *:not(:last-child) {
  margin-right: var(--margin-width-3);
}

/*
.flg-3

Sets the margin of all children except last to margin var for 3.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-3 - Sets gaps to margin var for 3

Styleguide Flex - Gaps - col
*/
.fld-col.flg-3 > *:not(:last-child) {
  margin-bottom: var(--margin-width-3);
}

/*
.flg-3

Sets the margin of all children except last to margin var for 3.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-3 - Sets gaps to margin var for 3

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-3 > *:not(:last-child) {
  margin-left: var(--margin-width-3);
}

/*
.flg-3

Sets the margin of all children except last to margin var for 3.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-3 - Sets gaps to margin var for 3

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-3 > *:not(:last-child) {
  margin-top: var(--margin-width-3);
}

/*
.flg-4

Sets the margin of all children except last to margin var for 4.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-4 - Sets gaps to margin var for 4

Styleguide Flex - Gaps - row
*/
.fld-row.flg-4 > *:not(:last-child) {
  margin-right: var(--margin-width-4);
}

/*
.flg-4

Sets the margin of all children except last to margin var for 4.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-4 - Sets gaps to margin var for 4

Styleguide Flex - Gaps - col
*/
.fld-col.flg-4 > *:not(:last-child) {
  margin-bottom: var(--margin-width-4);
}

/*
.flg-4

Sets the margin of all children except last to margin var for 4.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-4 - Sets gaps to margin var for 4

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-4 > *:not(:last-child) {
  margin-left: var(--margin-width-4);
}

/*
.flg-4

Sets the margin of all children except last to margin var for 4.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-4 - Sets gaps to margin var for 4

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-4 > *:not(:last-child) {
  margin-top: var(--margin-width-4);
}

/*
.flg-5

Sets the margin of all children except last to margin var for 5.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-5 - Sets gaps to margin var for 5

Styleguide Flex - Gaps - row
*/
.fld-row.flg-5 > *:not(:last-child) {
  margin-right: var(--margin-width-5);
}

/*
.flg-5

Sets the margin of all children except last to margin var for 5.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-5 - Sets gaps to margin var for 5

Styleguide Flex - Gaps - col
*/
.fld-col.flg-5 > *:not(:last-child) {
  margin-bottom: var(--margin-width-5);
}

/*
.flg-5

Sets the margin of all children except last to margin var for 5.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-5 - Sets gaps to margin var for 5

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-5 > *:not(:last-child) {
  margin-left: var(--margin-width-5);
}

/*
.flg-5

Sets the margin of all children except last to margin var for 5.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-5 - Sets gaps to margin var for 5

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-5 > *:not(:last-child) {
  margin-top: var(--margin-width-5);
}

/*
.flg-6

Sets the margin of all children except last to margin var for 6.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-6 - Sets gaps to margin var for 6

Styleguide Flex - Gaps - row
*/
.fld-row.flg-6 > *:not(:last-child) {
  margin-right: var(--margin-width-6);
}

/*
.flg-6

Sets the margin of all children except last to margin var for 6.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-6 - Sets gaps to margin var for 6

Styleguide Flex - Gaps - col
*/
.fld-col.flg-6 > *:not(:last-child) {
  margin-bottom: var(--margin-width-6);
}

/*
.flg-6

Sets the margin of all children except last to margin var for 6.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-6 - Sets gaps to margin var for 6

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-6 > *:not(:last-child) {
  margin-left: var(--margin-width-6);
}

/*
.flg-6

Sets the margin of all children except last to margin var for 6.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-6 - Sets gaps to margin var for 6

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-6 > *:not(:last-child) {
  margin-top: var(--margin-width-6);
}

/*
.flg-7

Sets the margin of all children except last to margin var for 7.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-7 - Sets gaps to margin var for 7

Styleguide Flex - Gaps - row
*/
.fld-row.flg-7 > *:not(:last-child) {
  margin-right: var(--margin-width-7);
}

/*
.flg-7

Sets the margin of all children except last to margin var for 7.

Markup:
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-7 - Sets gaps to margin var for 7

Styleguide Flex - Gaps - col
*/
.fld-col.flg-7 > *:not(:last-child) {
  margin-bottom: var(--margin-width-7);
}

/*
.flg-7

Sets the margin of all children except last to margin var for 7.

Markup:
<div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-7 - Sets gaps to margin var for 7

Styleguide Flex - Gaps - rowr
*/
.fld-rowr.flg-7 > *:not(:last-child) {
  margin-left: var(--margin-width-7);
}

/*
.flg-7

Sets the margin of all children except last to margin var for 7.

Markup:
<div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flg-7 - Sets gaps to margin var for 7

Styleguide Flex - Gaps - colr
*/
.fld-colr.flg-7 > *:not(:last-child) {
  margin-top: var(--margin-width-7);
}

/*
Flex Size

Classes for setting flex-grow and flex-shrink properties on an element.

Styleguide Flex - Size
*/
/*
.fls-0-0

Sets flex-grow to 0 and flex-shrink to 0.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-0-0 - Sets flex-grow to 0 and flex-shrink to 0

Styleguide Flex - Size - 0:0
*/
.fls-0-0 {
  flex-grow: 0;
  flex-shrink: 0;
}

/*
.fls-0-1

Sets flex-grow to 0 and flex-shrink to 1.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-0-1 - Sets flex-grow to 0 and flex-shrink to 1

Styleguide Flex - Size - 0:1
*/
.fls-0-1 {
  flex-grow: 0;
  flex-shrink: 1;
}

/*
.fls-0-2

Sets flex-grow to 0 and flex-shrink to 2.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-0-2 - Sets flex-grow to 0 and flex-shrink to 2

Styleguide Flex - Size - 0:2
*/
.fls-0-2 {
  flex-grow: 0;
  flex-shrink: 2;
}

/*
.fls-0-3

Sets flex-grow to 0 and flex-shrink to 3.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-0-3 - Sets flex-grow to 0 and flex-shrink to 3

Styleguide Flex - Size - 0:3
*/
.fls-0-3 {
  flex-grow: 0;
  flex-shrink: 3;
}

/*
.fls-0-4

Sets flex-grow to 0 and flex-shrink to 4.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-0-4 - Sets flex-grow to 0 and flex-shrink to 4

Styleguide Flex - Size - 0:4
*/
.fls-0-4 {
  flex-grow: 0;
  flex-shrink: 4;
}

/*
.fls-1-0

Sets flex-grow to 1 and flex-shrink to 0.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-1-0 - Sets flex-grow to 1 and flex-shrink to 0

Styleguide Flex - Size - 1:0
*/
.fls-1-0 {
  flex-grow: 1;
  flex-shrink: 0;
}

/*
.fls-1-1

Sets flex-grow to 1 and flex-shrink to 1.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-1-1 - Sets flex-grow to 1 and flex-shrink to 1

Styleguide Flex - Size - 1:1
*/
.fls-1-1 {
  flex-grow: 1;
  flex-shrink: 1;
}

/*
.fls-1-2

Sets flex-grow to 1 and flex-shrink to 2.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-1-2 - Sets flex-grow to 1 and flex-shrink to 2

Styleguide Flex - Size - 1:2
*/
.fls-1-2 {
  flex-grow: 1;
  flex-shrink: 2;
}

/*
.fls-1-3

Sets flex-grow to 1 and flex-shrink to 3.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-1-3 - Sets flex-grow to 1 and flex-shrink to 3

Styleguide Flex - Size - 1:3
*/
.fls-1-3 {
  flex-grow: 1;
  flex-shrink: 3;
}

/*
.fls-1-4

Sets flex-grow to 1 and flex-shrink to 4.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-1-4 - Sets flex-grow to 1 and flex-shrink to 4

Styleguide Flex - Size - 1:4
*/
.fls-1-4 {
  flex-grow: 1;
  flex-shrink: 4;
}

/*
.fls-2-0

Sets flex-grow to 2 and flex-shrink to 0.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-2-0 - Sets flex-grow to 2 and flex-shrink to 0

Styleguide Flex - Size - 2:0
*/
.fls-2-0 {
  flex-grow: 2;
  flex-shrink: 0;
}

/*
.fls-2-1

Sets flex-grow to 2 and flex-shrink to 1.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-2-1 - Sets flex-grow to 2 and flex-shrink to 1

Styleguide Flex - Size - 2:1
*/
.fls-2-1 {
  flex-grow: 2;
  flex-shrink: 1;
}

/*
.fls-2-2

Sets flex-grow to 2 and flex-shrink to 2.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-2-2 - Sets flex-grow to 2 and flex-shrink to 2

Styleguide Flex - Size - 2:2
*/
.fls-2-2 {
  flex-grow: 2;
  flex-shrink: 2;
}

/*
.fls-2-3

Sets flex-grow to 2 and flex-shrink to 3.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-2-3 - Sets flex-grow to 2 and flex-shrink to 3

Styleguide Flex - Size - 2:3
*/
.fls-2-3 {
  flex-grow: 2;
  flex-shrink: 3;
}

/*
.fls-2-4

Sets flex-grow to 2 and flex-shrink to 4.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-2-4 - Sets flex-grow to 2 and flex-shrink to 4

Styleguide Flex - Size - 2:4
*/
.fls-2-4 {
  flex-grow: 2;
  flex-shrink: 4;
}

/*
.fls-3-0

Sets flex-grow to 3 and flex-shrink to 0.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-3-0 - Sets flex-grow to 3 and flex-shrink to 0

Styleguide Flex - Size - 3:0
*/
.fls-3-0 {
  flex-grow: 3;
  flex-shrink: 0;
}

/*
.fls-3-1

Sets flex-grow to 3 and flex-shrink to 1.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-3-1 - Sets flex-grow to 3 and flex-shrink to 1

Styleguide Flex - Size - 3:1
*/
.fls-3-1 {
  flex-grow: 3;
  flex-shrink: 1;
}

/*
.fls-3-2

Sets flex-grow to 3 and flex-shrink to 2.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-3-2 - Sets flex-grow to 3 and flex-shrink to 2

Styleguide Flex - Size - 3:2
*/
.fls-3-2 {
  flex-grow: 3;
  flex-shrink: 2;
}

/*
.fls-3-3

Sets flex-grow to 3 and flex-shrink to 3.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-3-3 - Sets flex-grow to 3 and flex-shrink to 3

Styleguide Flex - Size - 3:3
*/
.fls-3-3 {
  flex-grow: 3;
  flex-shrink: 3;
}

/*
.fls-3-4

Sets flex-grow to 3 and flex-shrink to 4.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-3-4 - Sets flex-grow to 3 and flex-shrink to 4

Styleguide Flex - Size - 3:4
*/
.fls-3-4 {
  flex-grow: 3;
  flex-shrink: 4;
}

/*
.fls-4-0

Sets flex-grow to 4 and flex-shrink to 0.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-4-0 - Sets flex-grow to 4 and flex-shrink to 0

Styleguide Flex - Size - 4:0
*/
.fls-4-0 {
  flex-grow: 4;
  flex-shrink: 0;
}

/*
.fls-4-1

Sets flex-grow to 4 and flex-shrink to 1.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-4-1 - Sets flex-grow to 4 and flex-shrink to 1

Styleguide Flex - Size - 4:1
*/
.fls-4-1 {
  flex-grow: 4;
  flex-shrink: 1;
}

/*
.fls-4-2

Sets flex-grow to 4 and flex-shrink to 2.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-4-2 - Sets flex-grow to 4 and flex-shrink to 2

Styleguide Flex - Size - 4:2
*/
.fls-4-2 {
  flex-grow: 4;
  flex-shrink: 2;
}

/*
.fls-4-3

Sets flex-grow to 4 and flex-shrink to 3.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-4-3 - Sets flex-grow to 4 and flex-shrink to 3

Styleguide Flex - Size - 4:3
*/
.fls-4-3 {
  flex-grow: 4;
  flex-shrink: 3;
}

/*
.fls-4-4

Sets flex-grow to 4 and flex-shrink to 4.

Markup:
<div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

.fls-4-4 - Sets flex-grow to 4 and flex-shrink to 4

Styleguide Flex - Size - 4:4
*/
.fls-4-4 {
  flex-grow: 4;
  flex-shrink: 4;
}

/*
Flex Basis

Classes for setting the flex-basis property on an element.

Styleguide Flex - Basis
*/
/*
.flb-auto

Sets flex-basis to auto.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-auto - Sets flex-basis to auto

Styleguide Flex - Basis - auto
*/
.flb-auto {
  flex-basis: auto;
}

/*
.flb-fill

Sets flex-basis to fill.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-fill - Sets flex-basis to fill

Styleguide Flex - Basis - fill
*/
.flb-fill {
  flex-basis: fill;
}

/*
.flb-max-content

Sets flex-basis to max-content.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-max-content - Sets flex-basis to max-content

Styleguide Flex - Basis - max-content
*/
.flb-max-content {
  flex-basis: max-content;
}

/*
.flb-min-content

Sets flex-basis to min-content.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-min-content - Sets flex-basis to min-content

Styleguide Flex - Basis - min-content
*/
.flb-min-content {
  flex-basis: min-content;
}

/*
.flb-fit-content

Sets flex-basis to fit-content.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-fit-content - Sets flex-basis to fit-content

Styleguide Flex - Basis - fit-content
*/
.flb-fit-content {
  flex-basis: fit-content;
}

/*
.flb-content

Sets flex-basis to content.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-content - Sets flex-basis to content

Styleguide Flex - Basis - content
*/
.flb-content {
  flex-basis: content;
}

/*
.flb-unset

Sets flex-basis to unset.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-unset - Sets flex-basis to unset

Styleguide Flex - Basis - unset
*/
.flb-unset {
  flex-basis: unset;
}

/*
.flb-p0

Sets flex-basis to 0%.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-p0 - Sets flex-basis to 0%

Styleguide Flex - Basis - p0
*/
.flb-p0 {
  flex-basis: 0%;
}

/*
.flb-p25

Sets flex-basis to 25%.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-p25 - Sets flex-basis to 25%

Styleguide Flex - Basis - p25
*/
.flb-p25 {
  flex-basis: 25%;
}

/*
.flb-p50

Sets flex-basis to 50%.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-p50 - Sets flex-basis to 50%

Styleguide Flex - Basis - p50
*/
.flb-p50 {
  flex-basis: 50%;
}

/*
.flb-p75

Sets flex-basis to 75%.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-p75 - Sets flex-basis to 75%

Styleguide Flex - Basis - p75
*/
.flb-p75 {
  flex-basis: 75%;
}

/*
.flb-p100

Sets flex-basis to 100%.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

.flb-p100 - Sets flex-basis to 100%

Styleguide Flex - Basis - p100
*/
.flb-p100 {
  flex-basis: 100%;
}

/*
Flex Wrap

Classes for setting the flex-wrap property on an element. Use of this with flex gaps is not recommended.

Styleguide Flex - Wrap
*/
/*
.flw-nowrap

Sets flex-wrap to nowrap.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flw-nowrap - Sets flex-wrap to nowrap

Styleguide Flex - Wrap - nowrap
*/
.flw-nowrap {
  flex-wrap: nowrap;
}

/*
.flw-wrap

Sets flex-wrap to wrap.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flw-wrap - Sets flex-wrap to wrap

Styleguide Flex - Wrap - wrap
*/
.flw-wrap {
  flex-wrap: wrap;
}

/*
.flw-wrap-reverse

Sets flex-wrap to wrap-reverse.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flw-wrap-reverse - Sets flex-wrap to wrap-reverse

Styleguide Flex - Wrap - wrap-reverse
*/
.flw-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
.flw-unset

Sets flex-wrap to unset.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.flw-unset - Sets flex-wrap to unset

Styleguide Flex - Wrap - unset
*/
.flw-unset {
  flex-wrap: unset;
}

/*
Align Items

Classes for setting the align-items property on an element. Can also be used with css grid.

Styleguide Flex - Align Items
*/
/*
.ai-stc

Sets align-items to stretch.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.ai-stc - Sets align-items to stretch

Styleguide Flex - Align Items - stc
*/
.ai-stc {
  align-items: stretch;
}

/*
.ai-ctr

Sets align-items to center.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.ai-ctr - Sets align-items to center

Styleguide Flex - Align Items - ctr
*/
.ai-ctr {
  align-items: center;
}

/*
.ai-str

Sets align-items to flex-start.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.ai-str - Sets align-items to flex-start

Styleguide Flex - Align Items - str
*/
.ai-str {
  align-items: flex-start;
}

/*
.ai-end

Sets align-items to flex-end.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.ai-end - Sets align-items to flex-end

Styleguide Flex - Align Items - end
*/
.ai-end {
  align-items: flex-end;
}

/*
Justify Content

Classes for setting the justify-content property on an element. Can also be used with css grid.

Styleguide Flex - Justify Content
*/
/*
.jc-ctr

Sets justify-content to center.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.jc-ctr - Sets justify-content to center

Styleguide Flex - Justify Content - ctr
*/
.jc-ctr {
  justify-content: center;
}

/*
.jc-str

Sets justify-content to flex-start.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.jc-str - Sets justify-content to flex-start

Styleguide Flex - Justify Content - str
*/
.jc-str {
  justify-content: flex-start;
}

/*
.jc-end

Sets justify-content to flex-end.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.jc-end - Sets justify-content to flex-end

Styleguide Flex - Justify Content - end
*/
.jc-end {
  justify-content: flex-end;
}

/*
.jc-spb

Sets justify-content to space-between.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.jc-spb - Sets justify-content to space-between

Styleguide Flex - Justify Content - spb
*/
.jc-spb {
  justify-content: space-between;
}

/*
.jc-spa

Sets justify-content to space-around.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.jc-spa - Sets justify-content to space-around

Styleguide Flex - Justify Content - spa
*/
.jc-spa {
  justify-content: space-around;
}

/*
.jc-spe

Sets justify-content to space-evenly.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.jc-spe - Sets justify-content to space-evenly

Styleguide Flex - Justify Content - spe
*/
.jc-spe {
  justify-content: space-evenly;
}

/*
.jc-stc

Sets justify-content to stretch.

Markup:
<div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.jc-stc - Sets justify-content to stretch

Styleguide Flex - Justify Content - stc
*/
.jc-stc {
  justify-content: stretch;
}

/*
Align Self

Classes for setting the align-self property on an element. Can also be used with css grid.

Styleguide Flex - Align Self
*/
/*
.as-stc

Sets align-self to stretch.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.as-stc - Sets align-self to stretch

Styleguide Flex - Align Self - stc
*/
.as-stc {
  align-self: stretch;
}

/*
.as-ctr

Sets align-self to center.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.as-ctr - Sets align-self to center

Styleguide Flex - Align Self - ctr
*/
.as-ctr {
  align-self: center;
}

/*
.as-str

Sets align-self to flex-start.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.as-str - Sets align-self to flex-start

Styleguide Flex - Align Self - str
*/
.as-str {
  align-self: flex-start;
}

/*
.as-end

Sets align-self to flex-end.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.as-end - Sets align-self to flex-end

Styleguide Flex - Align Self - end
*/
.as-end {
  align-self: flex-end;
}

/*
.as-sst

Sets align-self to self-start.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.as-sst - Sets align-self to self-start

Styleguide Flex - Align Self - sst
*/
.as-sst {
  align-self: self-start;
}

/*
.as-sse

Sets align-self to self-end.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.as-sse - Sets align-self to self-end

Styleguide Flex - Align Self - sse
*/
.as-sse {
  align-self: self-end;
}

/*
Justify Self

Classes for setting the justify-self property on an element. Can also be used with css grid.

Styleguide Flex - Justify Self
*/
/*
.js-stc

Sets justify-self to stretch.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.js-stc - Sets justify-self to stretch

Styleguide Flex - Justify Self - stc
*/
.js-stc {
  justify-self: stretch;
}

/*
.js-ctr

Sets justify-self to center.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.js-ctr - Sets justify-self to center

Styleguide Flex - Justify Self - ctr
*/
.js-ctr {
  justify-self: center;
}

/*
.js-str

Sets justify-self to flex-start.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.js-str - Sets justify-self to flex-start

Styleguide Flex - Justify Self - str
*/
.js-str {
  justify-self: flex-start;
}

/*
.js-end

Sets justify-self to flex-end.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.js-end - Sets justify-self to flex-end

Styleguide Flex - Justify Self - end
*/
.js-end {
  justify-self: flex-end;
}

/*
.js-sst

Sets justify-self to self-start.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.js-sst - Sets justify-self to self-start

Styleguide Flex - Justify Self - sst
*/
.js-sst {
  justify-self: self-start;
}

/*
.js-sse

Sets justify-self to self-end.

Markup:
<div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
<div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

.js-sse - Sets justify-self to self-end

Styleguide Flex - Justify Self - sse
*/
.js-sse {
  justify-self: self-end;
}

@media (min-width: 340px) {
  /*
  .fld-sm-row

  Sets display: flex and sets flex-direction of an element to row on sm.

  Markup:
  <div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

  .fld-sm-row - Sets flex-direction to row on sm

  Styleguide Flex - Direction - row - sm
  */
  .fld-sm-row {
    display: flex;
    flex-direction: row;
  }

  /*
  .fld-sm-col

  Sets display: flex and sets flex-direction of an element to column on sm.

  Markup:
  <div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

  .fld-sm-col - Sets flex-direction to column on sm

  Styleguide Flex - Direction - col - sm
  */
  .fld-sm-col {
    display: flex;
    flex-direction: column;
  }

  /*
  .fld-sm-rowr

  Sets display: flex and sets flex-direction of an element to row-reverse on sm.

  Markup:
  <div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

  .fld-sm-rowr - Sets flex-direction to row-reverse on sm

  Styleguide Flex - Direction - rowr - sm
  */
  .fld-sm-rowr {
    display: flex;
    flex-direction: row-reverse;
  }

  /*
  .fld-sm-colr

  Sets display: flex and sets flex-direction of an element to column-reverse on sm.

  Markup:
  <div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

  .fld-sm-colr - Sets flex-direction to column-reverse on sm

  Styleguide Flex - Direction - colr - sm
  */
  .fld-sm-colr {
    display: flex;
    flex-direction: column-reverse;
  }

  /*
  .flg-sm-0

  Sets the margin of all children except last to margin var for 0 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-0 - Sets gaps to margin var for 0 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-0 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-0);
  }

  .fld-row.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-0);
  }

  .fld-sm-row.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-0);
  }

  /*
  .flg-sm-0

  Sets the margin of all children except last to margin var for 0 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-0 - Sets gaps to margin var for 0 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-0 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-0);
  }

  .fld-col.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-0);
  }

  .fld-sm-col.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-0);
  }

  /*
  .flg-sm-0

  Sets the margin of all children except last to margin var for 0 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-0 - Sets gaps to margin var for 0 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-0 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-0);
  }

  .fld-rowr.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-0);
  }

  .fld-sm-rowr.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-0);
  }

  /*
  .flg-sm-0

  Sets the margin of all children except last to margin var for 0 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-0 - Sets gaps to margin var for 0 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-0 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-0);
  }

  .fld-colr.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-0);
  }

  .fld-sm-colr.flg-sm-0 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-0);
  }

  /*
  .flg-sm-1

  Sets the margin of all children except last to margin var for 1 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-1 - Sets gaps to margin var for 1 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-1 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-1);
  }

  .fld-row.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-1);
  }

  .fld-sm-row.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-1);
  }

  /*
  .flg-sm-1

  Sets the margin of all children except last to margin var for 1 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-1 - Sets gaps to margin var for 1 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-1 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-1);
  }

  .fld-col.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-1);
  }

  .fld-sm-col.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-1);
  }

  /*
  .flg-sm-1

  Sets the margin of all children except last to margin var for 1 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-1 - Sets gaps to margin var for 1 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-1 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-1);
  }

  .fld-rowr.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-1);
  }

  .fld-sm-rowr.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-1);
  }

  /*
  .flg-sm-1

  Sets the margin of all children except last to margin var for 1 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-1 - Sets gaps to margin var for 1 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-1 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-1);
  }

  .fld-colr.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-1);
  }

  .fld-sm-colr.flg-sm-1 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-1);
  }

  /*
  .flg-sm-2

  Sets the margin of all children except last to margin var for 2 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-2 - Sets gaps to margin var for 2 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-2 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-2);
  }

  .fld-row.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-2);
  }

  .fld-sm-row.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-2);
  }

  /*
  .flg-sm-2

  Sets the margin of all children except last to margin var for 2 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-2 - Sets gaps to margin var for 2 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-2 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-2);
  }

  .fld-col.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-2);
  }

  .fld-sm-col.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-2);
  }

  /*
  .flg-sm-2

  Sets the margin of all children except last to margin var for 2 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-2 - Sets gaps to margin var for 2 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-2 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-2);
  }

  .fld-rowr.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-2);
  }

  .fld-sm-rowr.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-2);
  }

  /*
  .flg-sm-2

  Sets the margin of all children except last to margin var for 2 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-2 - Sets gaps to margin var for 2 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-2 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-2);
  }

  .fld-colr.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-2);
  }

  .fld-sm-colr.flg-sm-2 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-2);
  }

  /*
  .flg-sm-3

  Sets the margin of all children except last to margin var for 3 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-3 - Sets gaps to margin var for 3 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-3 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-3);
  }

  .fld-row.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-3);
  }

  .fld-sm-row.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-3);
  }

  /*
  .flg-sm-3

  Sets the margin of all children except last to margin var for 3 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-3 - Sets gaps to margin var for 3 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-3 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-3);
  }

  .fld-col.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-3);
  }

  .fld-sm-col.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-3);
  }

  /*
  .flg-sm-3

  Sets the margin of all children except last to margin var for 3 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-3 - Sets gaps to margin var for 3 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-3 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-3);
  }

  .fld-rowr.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-3);
  }

  .fld-sm-rowr.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-3);
  }

  /*
  .flg-sm-3

  Sets the margin of all children except last to margin var for 3 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-3 - Sets gaps to margin var for 3 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-3 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-3);
  }

  .fld-colr.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-3);
  }

  .fld-sm-colr.flg-sm-3 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-3);
  }

  /*
  .flg-sm-4

  Sets the margin of all children except last to margin var for 4 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-4 - Sets gaps to margin var for 4 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-4 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-4);
  }

  .fld-row.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-4);
  }

  .fld-sm-row.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-4);
  }

  /*
  .flg-sm-4

  Sets the margin of all children except last to margin var for 4 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-4 - Sets gaps to margin var for 4 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-4 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-4);
  }

  .fld-col.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-4);
  }

  .fld-sm-col.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-4);
  }

  /*
  .flg-sm-4

  Sets the margin of all children except last to margin var for 4 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-4 - Sets gaps to margin var for 4 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-4 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-4);
  }

  .fld-rowr.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-4);
  }

  .fld-sm-rowr.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-4);
  }

  /*
  .flg-sm-4

  Sets the margin of all children except last to margin var for 4 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-4 - Sets gaps to margin var for 4 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-4 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-4);
  }

  .fld-colr.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-4);
  }

  .fld-sm-colr.flg-sm-4 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-4);
  }

  /*
  .flg-sm-5

  Sets the margin of all children except last to margin var for 5 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-5 - Sets gaps to margin var for 5 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-5 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-5);
  }

  .fld-row.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-5);
  }

  .fld-sm-row.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-5);
  }

  /*
  .flg-sm-5

  Sets the margin of all children except last to margin var for 5 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-5 - Sets gaps to margin var for 5 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-5 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-5);
  }

  .fld-col.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-5);
  }

  .fld-sm-col.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-5);
  }

  /*
  .flg-sm-5

  Sets the margin of all children except last to margin var for 5 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-5 - Sets gaps to margin var for 5 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-5 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-5);
  }

  .fld-rowr.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-5);
  }

  .fld-sm-rowr.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-5);
  }

  /*
  .flg-sm-5

  Sets the margin of all children except last to margin var for 5 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-5 - Sets gaps to margin var for 5 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-5 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-5);
  }

  .fld-colr.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-5);
  }

  .fld-sm-colr.flg-sm-5 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-5);
  }

  /*
  .flg-sm-6

  Sets the margin of all children except last to margin var for 6 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-6 - Sets gaps to margin var for 6 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-6 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-6);
  }

  .fld-row.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-6);
  }

  .fld-sm-row.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-6);
  }

  /*
  .flg-sm-6

  Sets the margin of all children except last to margin var for 6 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-6 - Sets gaps to margin var for 6 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-6 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-6);
  }

  .fld-col.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-6);
  }

  .fld-sm-col.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-6);
  }

  /*
  .flg-sm-6

  Sets the margin of all children except last to margin var for 6 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-6 - Sets gaps to margin var for 6 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-6 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-6);
  }

  .fld-rowr.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-6);
  }

  .fld-sm-rowr.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-6);
  }

  /*
  .flg-sm-6

  Sets the margin of all children except last to margin var for 6 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-6 - Sets gaps to margin var for 6 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-6 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-6);
  }

  .fld-colr.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-6);
  }

  .fld-sm-colr.flg-sm-6 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-6);
  }

  /*
  .flg-sm-7

  Sets the margin of all children except last to margin var for 7 on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-7 - Sets gaps to margin var for 7 on sm

  Styleguide Flex - Gaps - row - sm
  */
  .fld-sm-row.flg-7 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-7);
  }

  .fld-row.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-7);
  }

  .fld-sm-row.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-right: var(--margin-width-7);
  }

  /*
  .flg-sm-7

  Sets the margin of all children except last to margin var for 7 on sm.

  Markup:
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-7 - Sets gaps to margin var for 7 on sm

  Styleguide Flex - Gaps - col - sm
  */
  .fld-sm-col.flg-7 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-7);
  }

  .fld-col.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-7);
  }

  .fld-sm-col.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-bottom: var(--margin-width-7);
  }

  /*
  .flg-sm-7

  Sets the margin of all children except last to margin var for 7 on sm.

  Markup:
  <div class="fld-rowr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-7 - Sets gaps to margin var for 7 on sm

  Styleguide Flex - Gaps - rowr - sm
  */
  .fld-sm-rowr.flg-7 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-7);
  }

  .fld-rowr.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-7);
  }

  .fld-sm-rowr.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-left: var(--margin-width-7);
  }

  /*
  .flg-sm-7

  Sets the margin of all children except last to margin var for 7 on sm.

  Markup:
  <div class="fld-colr {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .flg-sm-7 - Sets gaps to margin var for 7 on sm

  Styleguide Flex - Gaps - colr - sm
  */
  .fld-sm-colr.flg-7 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-7);
  }

  .fld-colr.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-7);
  }

  .fld-sm-colr.flg-sm-7 > *:not(:last-child) {
    margin: 0;
    margin-top: var(--margin-width-7);
  }

  /*
  .fls-sm-0-0

  Sets flex-grow to 0 and flex-shrink to 0 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-0-0 - Sets flex-grow to 0 and flex-shrink to 0 on sm

  Styleguide Flex - Size - 0:0 - sm
  */
  .fls-sm-0-0 {
    flex-grow: 0;
    flex-shrink: 0;
  }

  /*
  .fls-sm-0-1

  Sets flex-grow to 0 and flex-shrink to 1 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-0-1 - Sets flex-grow to 0 and flex-shrink to 1 on sm

  Styleguide Flex - Size - 0:1 - sm
  */
  .fls-sm-0-1 {
    flex-grow: 0;
    flex-shrink: 1;
  }

  /*
  .fls-sm-0-2

  Sets flex-grow to 0 and flex-shrink to 2 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-0-2 - Sets flex-grow to 0 and flex-shrink to 2 on sm

  Styleguide Flex - Size - 0:2 - sm
  */
  .fls-sm-0-2 {
    flex-grow: 0;
    flex-shrink: 2;
  }

  /*
  .fls-sm-0-3

  Sets flex-grow to 0 and flex-shrink to 3 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-0-3 - Sets flex-grow to 0 and flex-shrink to 3 on sm

  Styleguide Flex - Size - 0:3 - sm
  */
  .fls-sm-0-3 {
    flex-grow: 0;
    flex-shrink: 3;
  }

  /*
  .fls-sm-0-4

  Sets flex-grow to 0 and flex-shrink to 4 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-0-4 - Sets flex-grow to 0 and flex-shrink to 4 on sm

  Styleguide Flex - Size - 0:4 - sm
  */
  .fls-sm-0-4 {
    flex-grow: 0;
    flex-shrink: 4;
  }

  /*
  .fls-sm-1-0

  Sets flex-grow to 1 and flex-shrink to 0 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-1-0 - Sets flex-grow to 1 and flex-shrink to 0 on sm

  Styleguide Flex - Size - 1:0 - sm
  */
  .fls-sm-1-0 {
    flex-grow: 1;
    flex-shrink: 0;
  }

  /*
  .fls-sm-1-1

  Sets flex-grow to 1 and flex-shrink to 1 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-1-1 - Sets flex-grow to 1 and flex-shrink to 1 on sm

  Styleguide Flex - Size - 1:1 - sm
  */
  .fls-sm-1-1 {
    flex-grow: 1;
    flex-shrink: 1;
  }

  /*
  .fls-sm-1-2

  Sets flex-grow to 1 and flex-shrink to 2 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-1-2 - Sets flex-grow to 1 and flex-shrink to 2 on sm

  Styleguide Flex - Size - 1:2 - sm
  */
  .fls-sm-1-2 {
    flex-grow: 1;
    flex-shrink: 2;
  }

  /*
  .fls-sm-1-3

  Sets flex-grow to 1 and flex-shrink to 3 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-1-3 - Sets flex-grow to 1 and flex-shrink to 3 on sm

  Styleguide Flex - Size - 1:3 - sm
  */
  .fls-sm-1-3 {
    flex-grow: 1;
    flex-shrink: 3;
  }

  /*
  .fls-sm-1-4

  Sets flex-grow to 1 and flex-shrink to 4 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-1-4 - Sets flex-grow to 1 and flex-shrink to 4 on sm

  Styleguide Flex - Size - 1:4 - sm
  */
  .fls-sm-1-4 {
    flex-grow: 1;
    flex-shrink: 4;
  }

  /*
  .fls-sm-2-0

  Sets flex-grow to 2 and flex-shrink to 0 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-2-0 - Sets flex-grow to 2 and flex-shrink to 0 on sm

  Styleguide Flex - Size - 2:0 - sm
  */
  .fls-sm-2-0 {
    flex-grow: 2;
    flex-shrink: 0;
  }

  /*
  .fls-sm-2-1

  Sets flex-grow to 2 and flex-shrink to 1 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-2-1 - Sets flex-grow to 2 and flex-shrink to 1 on sm

  Styleguide Flex - Size - 2:1 - sm
  */
  .fls-sm-2-1 {
    flex-grow: 2;
    flex-shrink: 1;
  }

  /*
  .fls-sm-2-2

  Sets flex-grow to 2 and flex-shrink to 2 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-2-2 - Sets flex-grow to 2 and flex-shrink to 2 on sm

  Styleguide Flex - Size - 2:2 - sm
  */
  .fls-sm-2-2 {
    flex-grow: 2;
    flex-shrink: 2;
  }

  /*
  .fls-sm-2-3

  Sets flex-grow to 2 and flex-shrink to 3 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-2-3 - Sets flex-grow to 2 and flex-shrink to 3 on sm

  Styleguide Flex - Size - 2:3 - sm
  */
  .fls-sm-2-3 {
    flex-grow: 2;
    flex-shrink: 3;
  }

  /*
  .fls-sm-2-4

  Sets flex-grow to 2 and flex-shrink to 4 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-2-4 - Sets flex-grow to 2 and flex-shrink to 4 on sm

  Styleguide Flex - Size - 2:4 - sm
  */
  .fls-sm-2-4 {
    flex-grow: 2;
    flex-shrink: 4;
  }

  /*
  .fls-sm-3-0

  Sets flex-grow to 3 and flex-shrink to 0 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-3-0 - Sets flex-grow to 3 and flex-shrink to 0 on sm

  Styleguide Flex - Size - 3:0 - sm
  */
  .fls-sm-3-0 {
    flex-grow: 3;
    flex-shrink: 0;
  }

  /*
  .fls-sm-3-1

  Sets flex-grow to 3 and flex-shrink to 1 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-3-1 - Sets flex-grow to 3 and flex-shrink to 1 on sm

  Styleguide Flex - Size - 3:1 - sm
  */
  .fls-sm-3-1 {
    flex-grow: 3;
    flex-shrink: 1;
  }

  /*
  .fls-sm-3-2

  Sets flex-grow to 3 and flex-shrink to 2 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-3-2 - Sets flex-grow to 3 and flex-shrink to 2 on sm

  Styleguide Flex - Size - 3:2 - sm
  */
  .fls-sm-3-2 {
    flex-grow: 3;
    flex-shrink: 2;
  }

  /*
  .fls-sm-3-3

  Sets flex-grow to 3 and flex-shrink to 3 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-3-3 - Sets flex-grow to 3 and flex-shrink to 3 on sm

  Styleguide Flex - Size - 3:3 - sm
  */
  .fls-sm-3-3 {
    flex-grow: 3;
    flex-shrink: 3;
  }

  /*
  .fls-sm-3-4

  Sets flex-grow to 3 and flex-shrink to 4 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-3-4 - Sets flex-grow to 3 and flex-shrink to 4 on sm

  Styleguide Flex - Size - 3:4 - sm
  */
  .fls-sm-3-4 {
    flex-grow: 3;
    flex-shrink: 4;
  }

  /*
  .fls-sm-4-0

  Sets flex-grow to 4 and flex-shrink to 0 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-4-0 - Sets flex-grow to 4 and flex-shrink to 0 on sm

  Styleguide Flex - Size - 4:0 - sm
  */
  .fls-sm-4-0 {
    flex-grow: 4;
    flex-shrink: 0;
  }

  /*
  .fls-sm-4-1

  Sets flex-grow to 4 and flex-shrink to 1 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-4-1 - Sets flex-grow to 4 and flex-shrink to 1 on sm

  Styleguide Flex - Size - 4:1 - sm
  */
  .fls-sm-4-1 {
    flex-grow: 4;
    flex-shrink: 1;
  }

  /*
  .fls-sm-4-2

  Sets flex-grow to 4 and flex-shrink to 2 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-4-2 - Sets flex-grow to 4 and flex-shrink to 2 on sm

  Styleguide Flex - Size - 4:2 - sm
  */
  .fls-sm-4-2 {
    flex-grow: 4;
    flex-shrink: 2;
  }

  /*
  .fls-sm-4-3

  Sets flex-grow to 4 and flex-shrink to 3 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-4-3 - Sets flex-grow to 4 and flex-shrink to 3 on sm

  Styleguide Flex - Size - 4:3 - sm
  */
  .fls-sm-4-3 {
    flex-grow: 4;
    flex-shrink: 3;
  }

  /*
  .fls-sm-4-4

  Sets flex-grow to 4 and flex-shrink to 4 on sm.

  Markup:
  <div class="fld-row"><div class="{{modifier_class}}">A div</div><div class="{{modifier_class}}">B div</div><div class="{{modifier_class}}">C div</div></div>

  .fls-sm-4-4 - Sets flex-grow to 4 and flex-shrink to 4 on sm

  Styleguide Flex - Size - 4:4 - sm
  */
  .fls-sm-4-4 {
    flex-grow: 4;
    flex-shrink: 4;
  }

  /*
  .flb-sm-auto

  Sets flex-basis to auto on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-auto - Sets flex-basis to auto on sm

  Styleguide Flex - Basis - auto - sm
  */
  .flb-sm-auto {
    flex-basis: auto;
  }

  /*
  .flb-sm-fill

  Sets flex-basis to fill on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-fill - Sets flex-basis to fill on sm

  Styleguide Flex - Basis - fill - sm
  */
  .flb-sm-fill {
    flex-basis: fill;
  }

  /*
  .flb-sm-max-content

  Sets flex-basis to max-content on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-max-content - Sets flex-basis to max-content on sm

  Styleguide Flex - Basis - max-content - sm
  */
  .flb-sm-max-content {
    flex-basis: max-content;
  }

  /*
  .flb-sm-min-content

  Sets flex-basis to min-content on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-min-content - Sets flex-basis to min-content on sm

  Styleguide Flex - Basis - min-content - sm
  */
  .flb-sm-min-content {
    flex-basis: min-content;
  }

  /*
  .flb-sm-fit-content

  Sets flex-basis to fit-content on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-fit-content - Sets flex-basis to fit-content on sm

  Styleguide Flex - Basis - fit-content - sm
  */
  .flb-sm-fit-content {
    flex-basis: fit-content;
  }

  /*
  .flb-sm-content

  Sets flex-basis to content on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-content - Sets flex-basis to content on sm

  Styleguide Flex - Basis - content - sm
  */
  .flb-sm-content {
    flex-basis: content;
  }

  /*
  .flb-sm-unset

  Sets flex-basis to unset on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-unset - Sets flex-basis to unset on sm

  Styleguide Flex - Basis - unset - sm
  */
  .flb-sm-unset {
    flex-basis: unset;
  }

  /*
  .flb-sm-p0

  Sets flex-basis to 0% on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-p0 - Sets flex-basis to 0% on sm

  Styleguide Flex - Basis - p0 - sm
  */
  .flb-sm-p0 {
    flex-basis: 0%;
  }

  /*
  .flb-sm-p25

  Sets flex-basis to 25% on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-p25 - Sets flex-basis to 25% on sm

  Styleguide Flex - Basis - p25 - sm
  */
  .flb-sm-p25 {
    flex-basis: 25%;
  }

  /*
  .flb-sm-p50

  Sets flex-basis to 50% on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-p50 - Sets flex-basis to 50% on sm

  Styleguide Flex - Basis - p50 - sm
  */
  .flb-sm-p50 {
    flex-basis: 50%;
  }

  /*
  .flb-sm-p75

  Sets flex-basis to 75% on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-p75 - Sets flex-basis to 75% on sm

  Styleguide Flex - Basis - p75 - sm
  */
  .flb-sm-p75 {
    flex-basis: 75%;
  }

  /*
  .flb-sm-p100

  Sets flex-basis to 100% on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1 {{modifier_class}}">B div</div><div class="fls-1-1 {{modifier_class}}">C div</div></div>

  .flb-sm-p100 - Sets flex-basis to 100% on sm

  Styleguide Flex - Basis - p100 - sm
  */
  .flb-sm-p100 {
    flex-basis: 100%;
  }

  /*
  .ai-sm-stc

  Sets align-items to stretch on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .ai-sm-stc - Sets align-items to stretch on sm

  Styleguide Flex - Align Items - stc - sm
  */
  .ai-sm-stc {
    align-items: stretch;
  }

  /*
  .ai-sm-ctr

  Sets align-items to center on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .ai-sm-ctr - Sets align-items to center on sm

  Styleguide Flex - Align Items - ctr - sm
  */
  .ai-sm-ctr {
    align-items: center;
  }

  /*
  .ai-sm-str

  Sets align-items to flex-start on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .ai-sm-str - Sets align-items to flex-start on sm

  Styleguide Flex - Align Items - str - sm
  */
  .ai-sm-str {
    align-items: flex-start;
  }

  /*
  .ai-sm-end

  Sets align-items to flex-end on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .ai-sm-end - Sets align-items to flex-end on sm

  Styleguide Flex - Align Items - end - sm
  */
  .ai-sm-end {
    align-items: flex-end;
  }

  /*
  .jc-sm-ctr

  Sets justify-content to center on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-sm-ctr - Sets justify-content to center on sm

  Styleguide Flex - Justify Content - ctr - sm
  */
  .jc-sm-ctr {
    justify-content: center;
  }

  /*
  .jc-sm-str

  Sets justify-content to flex-start on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-sm-str - Sets justify-content to flex-start on sm

  Styleguide Flex - Justify Content - str - sm
  */
  .jc-sm-str {
    justify-content: flex-start;
  }

  /*
  .jc-sm-end

  Sets justify-content to flex-end on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-sm-end - Sets justify-content to flex-end on sm

  Styleguide Flex - Justify Content - end - sm
  */
  .jc-sm-end {
    justify-content: flex-end;
  }

  /*
  .jc-sm-spb

  Sets justify-content to space-between on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-sm-spb - Sets justify-content to space-between on sm

  Styleguide Flex - Justify Content - spb - sm
  */
  .jc-sm-spb {
    justify-content: space-between;
  }

  /*
  .jc-sm-spa

  Sets justify-content to space-around on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-sm-spa - Sets justify-content to space-around on sm

  Styleguide Flex - Justify Content - spa - sm
  */
  .jc-sm-spa {
    justify-content: space-around;
  }

  /*
  .jc-sm-spe

  Sets justify-content to space-evenly on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-sm-spe - Sets justify-content to space-evenly on sm

  Styleguide Flex - Justify Content - spe - sm
  */
  .jc-sm-spe {
    justify-content: space-evenly;
  }

  /*
  .jc-sm-stc

  Sets justify-content to stretch on sm.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-sm-stc - Sets justify-content to stretch on sm

  Styleguide Flex - Justify Content - stc - sm
  */
  .jc-sm-stc {
    justify-content: stretch;
  }

  /*
  .as-sm-stc

  Sets align-self to stretch on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-sm-stc - Sets align-self to stretch on sm

  Styleguide Flex - Align Self - stc - sm
  */
  .as-sm-stc {
    align-self: stretch;
  }

  /*
  .as-sm-ctr

  Sets align-self to center on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-sm-ctr - Sets align-self to center on sm

  Styleguide Flex - Align Self - ctr - sm
  */
  .as-sm-ctr {
    align-self: center;
  }

  /*
  .as-sm-str

  Sets align-self to flex-start on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-sm-str - Sets align-self to flex-start on sm

  Styleguide Flex - Align Self - str - sm
  */
  .as-sm-str {
    align-self: flex-start;
  }

  /*
  .as-sm-end

  Sets align-self to flex-end on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-sm-end - Sets align-self to flex-end on sm

  Styleguide Flex - Align Self - end - sm
  */
  .as-sm-end {
    align-self: flex-end;
  }

  /*
  .as-sm-sst

  Sets align-self to self-start on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-sm-sst - Sets align-self to self-start on sm

  Styleguide Flex - Align Self - sst - sm
  */
  .as-sm-sst {
    align-self: self-start;
  }

  /*
  .as-sm-sse

  Sets align-self to self-end on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-sm-sse - Sets align-self to self-end on sm

  Styleguide Flex - Align Self - sse - sm
  */
  .as-sm-sse {
    align-self: self-end;
  }

  /*
  .js-sm-stc

  Sets justify-self to stretch on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .js-sm-stc - Sets justify-self to stretch on sm

  Styleguide Flex - Justify Self - stc - sm
  */
  .js-sm-stc {
    justify-self: stretch;
  }

  /*
  .js-sm-ctr

  Sets justify-self to center on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .js-sm-ctr - Sets justify-self to center on sm

  Styleguide Flex - Justify Self - ctr - sm
  */
  .js-sm-ctr {
    justify-self: center;
  }

  /*
  .js-sm-str

  Sets justify-self to flex-start on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .js-sm-str - Sets justify-self to flex-start on sm

  Styleguide Flex - Justify Self - str - sm
  */
  .js-sm-str {
    justify-self: flex-start;
  }

  /*
  .js-sm-end

  Sets justify-self to flex-end on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .js-sm-end - Sets justify-self to flex-end on sm

  Styleguide Flex - Justify Self - end - sm
  */
  .js-sm-end {
    justify-self: flex-end;
  }

  /*
  .js-sm-sst

  Sets justify-self to self-start on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .js-sm-sst - Sets justify-self to self-start on sm

  Styleguide Flex - Justify Self - sst - sm
  */
  .js-sm-sst {
    justify-self: self-start;
  }

  /*
  .js-sm-sse

  Sets justify-self to self-end on sm.

  Markup:
  <div class="fld-row"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col"><div class="fls-1-1 {{modifier_class}}">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .js-sm-sse - Sets justify-self to self-end on sm

  Styleguide Flex - Justify Self - sse - sm
  */
  .js-sm-sse {
    justify-self: self-end;
  }
}
/**
 * Font Size Scale
 * Based on https://blog.envylabs.com/responsive-typographic-scales-in-css-b9f60431d1c4
 */
/**
 * Font Weight Scale
 * Requires associated font weights to be included
 */
:root {
  --font-size-u5: 2em;
  --font-size-u4: 1.7511em;
  --font-size-u3: 1.5157em;
  --font-size-u2: 1.3195em;
  --font-size-u1: 1.1487em;
  --font-size-0: 1em;
  --font-size-d1: 0.8706em;
  --font-size-d2: 0.7579em;
  --font-size-d3: 0.6599em;
  --font-size-d4: 0.5745em;
  --font-size-d5: 0.5em;
}

:root {
  --font-weight-u4: 900;
  --font-weight-u3: 700;
  --font-weight-u2: 600;
  --font-weight-u1: 500;
  --font-weight-0: 400;
  --font-weight-d1: 300;
  --font-weight-d2: 200;
  --font-weight-d3: 100;
}

/**
 * Font Size Scale
 * Based on https://blog.envylabs.com/responsive-typographic-scales-in-css-b9f60431d1c4
 */
/**
 * Font Weight Scale
 * Requires associated font weights to be included
 */
/*
Font

Classes for setting the font properties of an element.

Styleguide Font
*/
/*
Font Size

Classes for setting the font-size of an element.

Styleguide Font - Size
*/
/*
.fs-u5

Sets the font-size an element to the variable for u5.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-u5 - Sets the font-size an element to the variable for u5

Styleguide Font - Size - u5
*/
.fs-u5 {
  font-size: var(--font-size-u5);
}

/*
.fs-u4

Sets the font-size an element to the variable for u4.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-u4 - Sets the font-size an element to the variable for u4

Styleguide Font - Size - u4
*/
.fs-u4 {
  font-size: var(--font-size-u4);
}

/*
.fs-u3

Sets the font-size an element to the variable for u3.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-u3 - Sets the font-size an element to the variable for u3

Styleguide Font - Size - u3
*/
.fs-u3 {
  font-size: var(--font-size-u3);
}

/*
.fs-u2

Sets the font-size an element to the variable for u2.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-u2 - Sets the font-size an element to the variable for u2

Styleguide Font - Size - u2
*/
.fs-u2 {
  font-size: var(--font-size-u2);
}

/*
.fs-u1

Sets the font-size an element to the variable for u1.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-u1 - Sets the font-size an element to the variable for u1

Styleguide Font - Size - u1
*/
.fs-u1 {
  font-size: var(--font-size-u1);
}

/*
.fs-0

Sets the font-size an element to the variable for 0.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-0 - Sets the font-size an element to the variable for 0

Styleguide Font - Size - 0
*/
.fs-0 {
  font-size: var(--font-size-0);
}

/*
.fs-d1

Sets the font-size an element to the variable for d1.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-d1 - Sets the font-size an element to the variable for d1

Styleguide Font - Size - d1
*/
.fs-d1 {
  font-size: var(--font-size-d1);
}

/*
.fs-d2

Sets the font-size an element to the variable for d2.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-d2 - Sets the font-size an element to the variable for d2

Styleguide Font - Size - d2
*/
.fs-d2 {
  font-size: var(--font-size-d2);
}

/*
.fs-d3

Sets the font-size an element to the variable for d3.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-d3 - Sets the font-size an element to the variable for d3

Styleguide Font - Size - d3
*/
.fs-d3 {
  font-size: var(--font-size-d3);
}

/*
.fs-d4

Sets the font-size an element to the variable for d4.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-d4 - Sets the font-size an element to the variable for d4

Styleguide Font - Size - d4
*/
.fs-d4 {
  font-size: var(--font-size-d4);
}

/*
.fs-d5

Sets the font-size an element to the variable for d5.

Markup:
<div class="{{modifier_class}}">A div</div>

.fs-d5 - Sets the font-size an element to the variable for d5

Styleguide Font - Size - d5
*/
.fs-d5 {
  font-size: var(--font-size-d5);
}

/*
Font Weight

Classes for setting the font-weight of an element.

Styleguide Font - Weight
*/

/*
.fw-u4

Sets the font-weight an element to the variable for u4.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-u4 - Sets the font-weight an element to the variable for u4

Styleguide Font - Weight - u4
*/
.fw-u4 {
  font-weight: var(--font-weight-u4);
}

/*
.fw-u3

Sets the font-weight an element to the variable for u3.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-u3 - Sets the font-weight an element to the variable for u3

Styleguide Font - Weight - u3
*/
.fw-u3 {
  font-weight: var(--font-weight-u3);
}

/*
.fw-u2

Sets the font-weight an element to the variable for u2.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-u2 - Sets the font-weight an element to the variable for u2

Styleguide Font - Weight - u2
*/
.fw-u2 {
  font-weight: var(--font-weight-u2);
}

/*
.fw-u1

Sets the font-weight an element to the variable for u1.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-u1 - Sets the font-weight an element to the variable for u1

Styleguide Font - Weight - u1
*/
.fw-u1 {
  font-weight: var(--font-weight-u1);
}

/*
.fw-0

Sets the font-weight an element to the variable for 0.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-0 - Sets the font-weight an element to the variable for 0

Styleguide Font - Weight - 0
*/
.fw-0 {
  font-weight: var(--font-weight-0);
}

/*
.fw-d1

Sets the font-weight an element to the variable for d1.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-d1 - Sets the font-weight an element to the variable for d1

Styleguide Font - Weight - d1
*/
.fw-d1 {
  font-weight: var(--font-weight-d1);
}

/*
.fw-d2

Sets the font-weight an element to the variable for d2.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-d2 - Sets the font-weight an element to the variable for d2

Styleguide Font - Weight - d2
*/
.fw-d2 {
  font-weight: var(--font-weight-d2);
}

/*
.fw-d3

Sets the font-weight an element to the variable for d3.

Markup:
<div class="{{modifier_class}}">A div</div>

.fw-d3 - Sets the font-weight an element to the variable for d3

Styleguide Font - Weight - d3
*/
.fw-d3 {
  font-weight: var(--font-weight-d3);
}

/**
 * Font Size Scale
 * Based on https://blog.envylabs.com/responsive-typographic-scales-in-css-b9f60431d1c4
 */
/**
 * Font Weight Scale
 * Requires associated font weights to be included
 */
@media (min-width: 340px) {
  /*
  Font

  Classes for setting the font properties of an element.

  Styleguide Font
  */
  /*
  Font Size

  Classes for setting the font-size of an element.

  Styleguide Font - Size - sm
  */
  /*
  .fs-sm-u5

  Sets the font-size an element to the variable for u5.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-u5 - Sets the font-size an element to the variable for u5

  Styleguide Font - Size - u5 - sm
  */
  .fs-sm-u5 {
    font-size: var(--font-size-u5);
  }

  /*
  .fs-sm-u4

  Sets the font-size an element to the variable for u4.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-u4 - Sets the font-size an element to the variable for u4

  Styleguide Font - Size - u4 - sm
  */
  .fs-sm-u4 {
    font-size: var(--font-size-u4);
  }

  /*
  .fs-sm-u3

  Sets the font-size an element to the variable for u3.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-u3 - Sets the font-size an element to the variable for u3

  Styleguide Font - Size - u3 - sm
  */
  .fs-sm-u3 {
    font-size: var(--font-size-u3);
  }

  /*
  .fs-sm-u2

  Sets the font-size an element to the variable for u2.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-u2 - Sets the font-size an element to the variable for u2

  Styleguide Font - Size - u2 - sm
  */
  .fs-sm-u2 {
    font-size: var(--font-size-u2);
  }

  /*
  .fs-sm-u1

  Sets the font-size an element to the variable for u1.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-u1 - Sets the font-size an element to the variable for u1

  Styleguide Font - Size - u1 - sm
  */
  .fs-sm-u1 {
    font-size: var(--font-size-u1);
  }

  /*
  .fs-sm-0

  Sets the font-size an element to the variable for 0.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-0 - Sets the font-size an element to the variable for 0

  Styleguide Font - Size - 0 - sm
  */
  .fs-sm-0 {
    font-size: var(--font-size-0);
  }

  /*
  .fs-sm-d1

  Sets the font-size an element to the variable for d1.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-d1 - Sets the font-size an element to the variable for d1

  Styleguide Font - Size - d1 - sm
  */
  .fs-sm-d1 {
    font-size: var(--font-size-d1);
  }

  /*
  .fs-sm-d2

  Sets the font-size an element to the variable for d2.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-d2 - Sets the font-size an element to the variable for d2

  Styleguide Font - Size - d2 - sm
  */
  .fs-sm-d2 {
    font-size: var(--font-size-d2);
  }

  /*
  .fs-sm-d3

  Sets the font-size an element to the variable for d3.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-d3 - Sets the font-size an element to the variable for d3

  Styleguide Font - Size - d3 - sm
  */
  .fs-sm-d3 {
    font-size: var(--font-size-d3);
  }

  /*
  .fs-sm-d4

  Sets the font-size an element to the variable for d4.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-d4 - Sets the font-size an element to the variable for d4

  Styleguide Font - Size - d4 - sm
  */
  .fs-sm-d4 {
    font-size: var(--font-size-d4);
  }

  /*
  .fs-sm-d5

  Sets the font-size an element to the variable for d5.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fs-sm-d5 - Sets the font-size an element to the variable for d5

  Styleguide Font - Size - d5 - sm
  */
  .fs-sm-d5 {
    font-size: var(--font-size-d5);
  }

  /*
  Font Weight

  Classes for setting the font-weight of an element.

  Styleguide Font - Weight - sm
  */
  /*
  .fw-sm-u3

  Sets the font-weight an element to the variable for u3.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fw-sm-u3 - Sets the font-weight an element to the variable for u3

  Styleguide Font - Weight - u3 - sm
  */
  .fw-sm-u3 {
    font-weight: var(--font-weight-u3);
  }

  /*
  .fw-sm-u2

  Sets the font-weight an element to the variable for u2.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fw-sm-u2 - Sets the font-weight an element to the variable for u2

  Styleguide Font - Weight - u2 - sm
  */
  .fw-sm-u2 {
    font-weight: var(--font-weight-u2);
  }

  /*
  .fw-sm-u1

  Sets the font-weight an element to the variable for u1.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fw-sm-u1 - Sets the font-weight an element to the variable for u1

  Styleguide Font - Weight - u1 - sm
  */
  .fw-sm-u1 {
    font-weight: var(--font-weight-u1);
  }

  /*
  .fw-sm-0

  Sets the font-weight an element to the variable for 0.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fw-sm-0 - Sets the font-weight an element to the variable for 0

  Styleguide Font - Weight - 0 - sm
  */
  .fw-sm-0 {
    font-weight: var(--font-weight-0);
  }

  /*
  .fw-sm-d1

  Sets the font-weight an element to the variable for d1.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fw-sm-d1 - Sets the font-weight an element to the variable for d1

  Styleguide Font - Weight - d1 - sm
  */
  .fw-sm-d1 {
    font-weight: var(--font-weight-d1);
  }

  /*
  .fw-sm-d2

  Sets the font-weight an element to the variable for d2.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fw-sm-d2 - Sets the font-weight an element to the variable for d2

  Styleguide Font - Weight - d2 - sm
  */
  .fw-sm-d2 {
    font-weight: var(--font-weight-d2);
  }

  /*
  .fw-sm-d3

  Sets the font-weight an element to the variable for d3.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .fw-sm-d3 - Sets the font-weight an element to the variable for d3

  Styleguide Font - Weight - d3 - sm
  */
  .fw-sm-d3 {
    font-weight: var(--font-weight-d3);
  }
}
:root {
  --height-p25: 25%;
  --height-p50: 50%;
  --height-p60: 60%;
  --height-p75: 75%;
  --height-p100: 100%;
  --height-vh25: 25vh;
  --height-vh40: 40vh;
  --height-vh50: 50vh;
  --height-vh60: 60vh;
  --height-vh75: 75vh;
  --height-vh100: 100vh;
}

:root {
  --height-0: calc(var(--fill-size) * 0);
  --height-1: calc(var(--fill-size) * 32);
  --height-2: calc(var(--fill-size) * 48);
  --height-3: calc(var(--fill-size) * 64);
  --height-4: calc(var(--fill-size) * 128);
}

/*
Height

Classes for setting the height, max-height, an min-height of an element (or its children).

Styleguide Height
*/
/*
.vh[mx,mn]-p25

Sets the height, max-height, or min-height of element to css var for p25.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-p25 - Sets height to p25
.vhmn-p25 - Sets min-height to p25
.vhmx-p25 - Sets max-height to p25

Styleguide Height - p25
*/
.vh-p25 {
  height: var(--height-p25);
}

.vhmn-p25 {
  min-height: var(--height-p25);
}

.vhmx-p25 {
  max-height: var(--height-p25);
}

/*
.vh[mx,mn]-p50

Sets the height, max-height, or min-height of element to css var for p50.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-p50 - Sets height to p50
.vhmn-p50 - Sets min-height to p50
.vhmx-p50 - Sets max-height to p50

Styleguide Height - p50
*/
.vh-p50 {
  height: var(--height-p50);
}

.vhmn-p50 {
  min-height: var(--height-p50);
}

.vhmx-p50 {
  max-height: var(--height-p50);
}

/*
.vh[mx,mn]-p75

Sets the height, max-height, or min-height of element to css var for p75.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-p75- Sets height to p75
.vhmn-p75 - Sets min-height to p75
.vhmx-p75 - Sets max-height to p75

Styleguide Height - p75
*/
.vh-p75 {
  height: var(--height-p75);
}

.vhmn-p75 {
  min-height: var(--height-p75);
}

.vhmx-p75 {
  max-height: var(--height-p75);
}

/*
.vh[mx,mn]-p60

Sets the height, max-height, or min-height of element to css var for p60.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-p60- Sets height to p60
.vhmn-p60 - Sets min-height to p60
.vhmx-p60 - Sets max-height to p60

Styleguide Height - p60
*/
.vh-p60 {
  height: var(--height-p60);
}

.vhmn-p60 {
  min-height: var(--height-p60);
}

.vhmx-p60 {
  max-height: var(--height-p60);
}

/*
.vh[mx,mn]-p100

Sets the height, max-height, or min-height of element to css var for p100.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-p100 - Sets height to p100
.vhmn-p100 - Sets min-height to p100
.vhmx-p100 - Sets max-height to p100

Styleguide Height - p100
*/
.vh-p100 {
  height: var(--height-p100);
}

.vhmn-p100 {
  min-height: var(--height-p100);
}

.vhmx-p100 {
  max-height: var(--height-p100);
}

/*
Styleguide Child Height - p100
*/
.vh-p100,
.vhc-p100 > * {
  height: var(--height-p100);
}

.vhmn-p100,
.vhcmn-p100 > * {
  min-height: var(--height-p100);
}

.vhmx-p100,
.vhcmx-p100 > * {
  max-height: var(--height-p100);
}

/*
.vh[mx,mn]-vh25

Sets the height, max-height, or min-height of element to css var for vh25.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-vh25 - Sets height to vh25
.vhmn-vh25 - Sets min-height to vh25
.vhmx-vh25 - Sets max-height to vh25

Styleguide Height - vh25
*/
.vh-vh25 {
  height: var(--height-vh25);
}

.vhmn-vh25 {
  min-height: var(--height-vh25);
}

.vhmx-vh25 {
  max-height: var(--height-vh25);
}

/*
.vh[mx,mn]-vh40

Sets the height, max-height, or min-height of element to css var for vh40.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-vh40 - Sets height to vh40
.vhmn-vh40 - Sets min-height to vh40
.vhmx-vh40 - Sets max-height to vh40

Styleguide Height - vh40
*/
.vh-vh40 {
  height: var(--height-vh40);
}

.vhmn-vh40 {
  min-height: var(--height-vh40);
}

.vhmx-vh40 {
  max-height: var(--height-vh40);
}

/*
.vh[mx,mn]-vh50

Sets the height, max-height, or min-height of element to css var for vh50.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-vh50 - Sets height to vh50
.vhmn-vh50 - Sets min-height to vh50
.vhmx-vh50 - Sets max-height to vh50

Styleguide Height - vh50
*/
.vh-vh50 {
  height: var(--height-vh50);
}

.vhmn-vh50 {
  min-height: var(--height-vh50);
}

.vhmx-vh50 {
  max-height: var(--height-vh50);
}

/*
.vh[mx,mn]-vh60

Sets the height, max-height, or min-height of element to css var for vh60.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-vh60 - Sets height to vh60
.vhmn-vh60 - Sets min-height to vh60
.vhmx-vh60 - Sets max-height to vh60

Styleguide Height - vh60
*/
.vh-vh60 {
  height: var(--height-vh60);
}

.vhmn-vh60 {
  min-height: var(--height-vh60);
}

.vhmx-vh60 {
  max-height: var(--height-vh60);
}

/*
.vh[mx,mn]-vh75

Sets the height, max-height, or min-height of element to css var for vh75.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-vh75 - Sets height to vh75
.vhmn-vh75 - Sets min-height to vh75
.vhmx-vh75 - Sets max-height to vh75

Styleguide Height - vh75
*/
.vh-vh75 {
  height: var(--height-vh75);
}

.vhmn-vh75 {
  min-height: var(--height-vh75);
}

.vhmx-vh75 {
  max-height: var(--height-vh75);
}

/*
.vh[mx,mn]-vh100

Sets the height, max-height, or min-height of element to css var for vh100.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-vh100 - Sets height to vh100
.vhmn-vh100 - Sets min-height to vh100
.vhmx-vh100 - Sets max-height to vh100

Styleguide Height - vh100
*/
.vh-vh100 {
  height: var(--height-vh100);
}

.vhmn-vh100 {
  min-height: var(--height-vh100);
}

.vhmx-vh100 {
  max-height: var(--height-vh100);
}

/*
.vh[mx,mn]-0

Sets the height, max-height, or min-height of element to css var for 0.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-0 - Sets height to 0
.vhmn-0 - Sets min-height to 0
.vhmx-0 - Sets max-height to 0

Styleguide Height - 0
*/
.vh-0 {
  height: var(--height-0);
}

.vhmn-0 {
  min-height: var(--height-0);
}

.vhmx-0 {
  max-height: var(--height-0);
}

/*
.vh[mx,mn]-1

Sets the height, max-height, or min-height of element to css var for 1.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-1 - Sets height to 1
.vhmn-1 - Sets min-height to 1
.vhmx-1 - Sets max-height to 1

Styleguide Height - 1
*/
.vh-1 {
  height: var(--height-1);
}

.vhmn-1 {
  min-height: var(--height-1);
}

.vhmx-1 {
  max-height: var(--height-1);
}

/*
.vh[mx,mn]-2

Sets the height, max-height, or min-height of element to css var for 2.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-2 - Sets height to 2
.vhmn-2 - Sets min-height to 2
.vhmx-2 - Sets max-height to 2

Styleguide Height - 2
*/
.vh-2 {
  height: var(--height-2);
}

.vhmn-2 {
  min-height: var(--height-2);
}

.vhmx-2 {
  max-height: var(--height-2);
}

/*
.vh[mx,mn]-3

Sets the height, max-height, or min-height of element to css var for 3.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-3 - Sets height to 3
.vhmn-3 - Sets min-height to 3
.vhmx-3 - Sets max-height to 3

Styleguide Height - 3
*/
.vh-3 {
  height: var(--height-3);
}

.vhmn-3 {
  min-height: var(--height-3);
}

.vhmx-3 {
  max-height: var(--height-3);
}

/*
.vh[mx,mn]-4

Sets the height, max-height, or min-height of element to css var for 4.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vh-4 - Sets height to 4
.vhmn-4 - Sets min-height to 4
.vhmx-4 - Sets max-height to 4

Styleguide Height - 4
*/
.vh-4 {
  height: var(--height-4);
}

.vhmn-4 {
  min-height: var(--height-4);
}

.vhmx-4 {
  max-height: var(--height-4);
}

@media (min-width: 340px) {
  /*
  .vh[mn,mx]-sm-p25

  Sets the height, max-height, or min-height of element to css var for p25 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-p25 - Sets height to p25 on sm
  .vhmn-sm-p25 - Sets min-height to p25 on sm
  .vhmx-sm-p25 - Sets max-height to p25 on sm

  Styleguide height - p25 - sm
  */
  .vh-sm-p25 {
    height: var(--height-p25);
  }

  .vhmn-sm-p25 {
    min-height: var(--height-p25);
  }

  .vhmx-sm-p25 {
    max-height: var(--height-p25);
  }

  /*
  .vh[mn,mx]-sm-p50

  Sets the height, max-height, or min-height of element to css var for p50 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-p50 - Sets height to p50 on sm
  .vhmn-sm-p50 - Sets min-height to p50 on sm
  .vhmx-sm-p50 - Sets max-height to p50 on sm

  Styleguide height - p50 - sm
  */
  .vh-sm-p50 {
    height: var(--height-p50);
  }

  .vhmn-sm-p50 {
    min-height: var(--height-p50);
  }

  .vhmx-sm-p50 {
    max-height: var(--height-p50);
  }

  /*
  .vh[mn,mx]-sm-p100

  Sets the height, max-height, or min-height of element to css var for p100 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-p100 - Sets height to p100 on sm
  .vhmn-sm-p100 - Sets min-height to p100 on sm
  .vhmx-sm-p100 - Sets max-height to p100 on sm

  Styleguide height - p100 - sm
  */
  .vh-sm-p100 {
    height: var(--height-p100);
  }

  .vhmn-sm-p100 {
    min-height: var(--height-p100);
  }

  .vhmx-sm-p100 {
    max-height: var(--height-p100);
  }

  /*
  .vh[mn,mx]-sm-vh25

  Sets the height, max-height, or min-height of element to css var for vh25 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-vh25 - Sets height to vh25 on sm
  .vhmn-sm-vh25 - Sets min-height to vh25 on sm
  .vhmx-sm-vh25 - Sets max-height to vh25 on sm

  Styleguide height - vh25 - sm
  */
  .vh-sm-vh25 {
    height: var(--height-vh25);
  }

  .vhmn-sm-vh25 {
    min-height: var(--height-vh25);
  }

  .vhmx-sm-vh25 {
    max-height: var(--height-vh25);
  }

  /*
  .vh[mn,mx]-sm-vh40

  Sets the height, max-height, or min-height of element to css var for vh40 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-vh40 - Sets height to vh40 on sm
  .vhmn-sm-vh40 - Sets min-height to vh40 on sm
  .vhmx-sm-vh40 - Sets max-height to vh40 on sm

  Styleguide height - vh40 - sm
  */
  .vh-sm-vh40 {
    height: var(--height-vh40);
  }

  .vhmn-sm-vh40 {
    min-height: var(--height-vh40);
  }

  .vhmx-sm-vh40 {
    max-height: var(--height-vh40);
  }

  /*
  .vh[mn,mx]-sm-vh50

  Sets the height, max-height, or min-height of element to css var for vh50 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-vh50 - Sets height to vh50 on sm
  .vhmn-sm-vh50 - Sets min-height to vh50 on sm
  .vhmx-sm-vh50 - Sets max-height to vh50 on sm

  Styleguide height - vh50 - sm
  */
  .vh-sm-vh50 {
    height: var(--height-vh50);
  }

  .vhmn-sm-vh50 {
    min-height: var(--height-vh50);
  }

  .vhmx-sm-vh50 {
    max-height: var(--height-vh50);
  }

  /*
  .vh[mn,mx]-sm-vh60

  Sets the height, max-height, or min-height of element to css var for vh60 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-vh60 - Sets height to vh60 on sm
  .vhmn-sm-vh60 - Sets min-height to vh60 on sm
  .vhmx-sm-vh60 - Sets max-height to vh60 on sm

  Styleguide height - vh60 - sm
  */
  .vh-sm-vh60 {
    height: var(--height-vh60);
  }

  .vhmn-sm-vh60 {
    min-height: var(--height-vh60);
  }

  .vhmx-sm-vh60 {
    max-height: var(--height-vh60);
  }

  /*
  .vh[mn,mx]-sm-vh75

  Sets the height, max-height, or min-height of element to css var for vh75 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-vh75 - Sets height to vh75 on sm
  .vhmn-sm-vh75 - Sets min-height to vh75 on sm
  .vhmx-sm-vh75 - Sets max-height to vh75 on sm

  Styleguide height - vh75 - sm
  */
  .vh-sm-vh75 {
    height: var(--height-vh75);
  }

  .vhmn-sm-vh75 {
    min-height: var(--height-vh75);
  }

  .vhmx-sm-vh75 {
    max-height: var(--height-vh75);
  }

  /*
  .vh[mn,mx]-sm-vh100

  Sets the height, max-height, or min-height of element to css var for vh100 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vh-sm-vh100 - Sets height to vh100 on sm
  .vhmn-sm-vh100 - Sets min-height to vh100 on sm
  .vhmx-sm-vh100 - Sets max-height to vh100 on sm

  Styleguide height - vh100 - sm
  */
  .vh-sm-vh100 {
    height: var(--height-vh100);
  }

  .vhmn-sm-vh100 {
    min-height: var(--height-vh100);
  }

  .vhmx-sm-vh100 {
    max-height: var(--height-vh100);
  }

  /*
    .vh[mx,mn]-0

    Sets the height, max-height, or min-height of element to css var for 0.

    Markup:
    <div class="bwa-1 {{modifier_class}}">A div</div>

    .vh-0 - Sets height to 0
    .vhmn-0 - Sets min-height to 0
    .vhmx-0 - Sets max-height to 0

    Styleguide Height - 0 - sm
    */
  .vh-sm-0 {
    height: var(--height-0);
  }

  .vhmn-sm-0 {
    min-height: var(--height-0);
  }

  .vhmx-sm-0 {
    max-height: var(--height-0);
  }

  /*
    .vh[mx,mn]-1

    Sets the height, max-height, or min-height of element to css var for 1.

    Markup:
    <div class="bwa-1 {{modifier_class}}">A div</div>

    .vh-1 - Sets height to 1
    .vhmn-1 - Sets min-height to 1
    .vhmx-1 - Sets max-height to 1

    Styleguide Height - 1 - sm
    */
  .vh-sm-1 {
    height: var(--height-1);
  }

  .vhmn-sm-1 {
    min-height: var(--height-1);
  }

  .vhmx-sm-1 {
    max-height: var(--height-1);
  }

  /*
    .vh[mx,mn]-2

    Sets the height, max-height, or min-height of element to css var for 2.

    Markup:
    <div class="bwa-1 {{modifier_class}}">A div</div>

    .vh-2 - Sets height to 2
    .vhmn-2 - Sets min-height to 2
    .vhmx-2 - Sets max-height to 2

    Styleguide Height - 2 - sm
    */
  .vh-sm-2 {
    height: var(--height-2);
  }

  .vhmn-sm-2 {
    min-height: var(--height-2);
  }

  .vhmx-sm-2 {
    max-height: var(--height-2);
  }

  /*
    .vh[mx,mn]-3

    Sets the height, max-height, or min-height of element to css var for 3.

    Markup:
    <div class="bwa-1 {{modifier_class}}">A div</div>

    .vh-3 - Sets height to 3
    .vhmn-3 - Sets min-height to 3
    .vhmx-3 - Sets max-height to 3

    Styleguide Height - 3 - sm
    */
  .vh-sm-3 {
    height: var(--height-3);
  }

  .vhmn-sm-3 {
    min-height: var(--height-3);
  }

  .vhmx-sm-3 {
    max-height: var(--height-3);
  }

  /*
    .vh[mx,mn]-4

    Sets the height, max-height, or min-height of element to css var for 4.

    Markup:
    <div class="bwa-1 {{modifier_class}}">A div</div>

    .vh-4 - Sets height to 4
    .vhmn-4 - Sets min-height to 4
    .vhmx-4 - Sets max-height to 4

    Styleguide Height - 4 - sm
    */
  .vh-sm-4 {
    height: var(--height-4);
  }

  .vhmn-sm-4 {
    min-height: var(--height-4);
  }

  .vhmx-sm-4 {
    max-height: var(--height-4);
  }
}
:root {
  --margin-width-0: calc(var(--fill-size) * 0);
  --margin-width-1: calc(var(--fill-size) * 1);
  --margin-width-2: calc(var(--fill-size) * 2);
  --margin-width-3: calc(var(--fill-size) * 4);
  --margin-width-4: calc(var(--fill-size) * 8);
  --margin-width-5: calc(var(--fill-size) * 16);
  --margin-width-6: calc(var(--fill-size) * 32);
  --margin-width-7: calc(var(--fill-size) * 64);
  --margin-width-p10: 10%;
  --margin-width-p20: 20%;
  --margin-width-p25: 25%;
  --margin-width-p30: 30%;
  --margin-width-p40: 40%;
  --margin-width-p50: 50%;
  --margin-width-p60: 60%;
  --margin-width-p70: 70%;
  --margin-width-p75: 75%;
  --margin-width-p80: 80%;
  --margin-width-p90: 90%;
  --margin-width-p100: 100%;
}

/*
Margin

Classes for setting the margin an element.

Styleguide Margin
*/
/*
Margin Width

Classes for setting the margin width of an element.

Styleguide Margin - Width
*/
/*
.mw*-0

Sets the margin of element to css var for 0.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-0 - Sets all margins to 0
.mwx-0 - Sets left and right margins to 0
.mwy-0 - Sets top and bottom margins to 0
.mwt-0 - Sets top margin to 0
.mwr-0 - Sets right margin to 0
.mwb-0 - Sets bottom margin to 0
.mwl-0 - Sets left margin to 0

Styleguide Margin - Width - 0
*/
.mwt-0,
.mwy-0,
.mwa-0 {
  margin-top: var(--margin-width-0);
}

.mwr-0,
.mwx-0,
.mwa-0 {
  margin-right: var(--margin-width-0);
}

.mwb-0,
.mwy-0,
.mwa-0 {
  margin-bottom: var(--margin-width-0);
}

.mwl-0,
.mwx-0,
.mwa-0 {
  margin-left: var(--margin-width-0);
}

/*
.mw*r-0

Sets the margin of element to the negative value of the css var for 0.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-0 - Sets all margins to m0
.mwxr-0 - Sets left and right margins to m0
.mwyr-0 - Sets top and bottom margins to m0
.mwtr-0 - Sets top margin to m0
.mwrr-0 - Sets right margin to m0
.mwbr-0 - Sets bottom margin to m0
.mwlr-0 - Sets left margin to m0

Styleguide Margin - Width - 0
*/
.mwtr-0,
.mwyr-0,
.mwar-0 {
  margin-top: calc(-1 * var(--margin-width-0));
}

.mwrr-0,
.mwxr-0,
.mwar-0 {
  margin-right: calc(-1 * var(--margin-width-0));
}

.mwbr-0,
.mwyr-0,
.mwar-0 {
  margin-bottom: calc(-1 * var(--margin-width-0));
}

.mwlr-0,
.mwxr-0,
.mwar-0 {
  margin-left: calc(-1 * var(--margin-width-0));
}

/*
.mw*-1

Sets the margin of element to css var for 1.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-1 - Sets all margins to 1
.mwx-1 - Sets left and right margins to 1
.mwy-1 - Sets top and bottom margins to 1
.mwt-1 - Sets top margin to 1
.mwr-1 - Sets right margin to 1
.mwb-1 - Sets bottom margin to 1
.mwl-1 - Sets left margin to 1

Styleguide Margin - Width - 1
*/
.mwt-1,
.mwy-1,
.mwa-1 {
  margin-top: var(--margin-width-1);
}

.mwr-1,
.mwx-1,
.mwa-1 {
  margin-right: var(--margin-width-1);
}

.mwb-1,
.mwy-1,
.mwa-1 {
  margin-bottom: var(--margin-width-1);
}

.mwl-1,
.mwx-1,
.mwa-1 {
  margin-left: var(--margin-width-1);
}

/*
.mw*r-1

Sets the margin of element to the negative value of the css var for 1.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-1 - Sets all margins to m1
.mwxr-1 - Sets left and right margins to m1
.mwyr-1 - Sets top and bottom margins to m1
.mwtr-1 - Sets top margin to m1
.mwrr-1 - Sets right margin to m1
.mwbr-1 - Sets bottom margin to m1
.mwlr-1 - Sets left margin to m1

Styleguide Margin - Width - 1
*/
.mwtr-1,
.mwyr-1,
.mwar-1 {
  margin-top: calc(-1 * var(--margin-width-1));
}

.mwrr-1,
.mwxr-1,
.mwar-1 {
  margin-right: calc(-1 * var(--margin-width-1));
}

.mwbr-1,
.mwyr-1,
.mwar-1 {
  margin-bottom: calc(-1 * var(--margin-width-1));
}

.mwlr-1,
.mwxr-1,
.mwar-1 {
  margin-left: calc(-1 * var(--margin-width-1));
}

/*
.mw*-2

Sets the margin of element to css var for 2.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-2 - Sets all margins to 2
.mwx-2 - Sets left and right margins to 2
.mwy-2 - Sets top and bottom margins to 2
.mwt-2 - Sets top margin to 2
.mwr-2 - Sets right margin to 2
.mwb-2 - Sets bottom margin to 2
.mwl-2 - Sets left margin to 2

Styleguide Margin - Width - 2
*/
.mwt-2,
.mwy-2,
.mwa-2 {
  margin-top: var(--margin-width-2);
}

.mwr-2,
.mwx-2,
.mwa-2 {
  margin-right: var(--margin-width-2);
}

.mwb-2,
.mwy-2,
.mwa-2 {
  margin-bottom: var(--margin-width-2);
}

.mwl-2,
.mwx-2,
.mwa-2 {
  margin-left: var(--margin-width-2);
}

/*
.mw*r-2

Sets the margin of element to the negative value of the css var for 2.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-2 - Sets all margins to m2
.mwxr-2 - Sets left and right margins to m2
.mwyr-2 - Sets top and bottom margins to m2
.mwtr-2 - Sets top margin to m2
.mwrr-2 - Sets right margin to m2
.mwbr-2 - Sets bottom margin to m2
.mwlr-2 - Sets left margin to m2

Styleguide Margin - Width - 2
*/
.mwtr-2,
.mwyr-2,
.mwar-2 {
  margin-top: calc(-1 * var(--margin-width-2));
}

.mwrr-2,
.mwxr-2,
.mwar-2 {
  margin-right: calc(-1 * var(--margin-width-2));
}

.mwbr-2,
.mwyr-2,
.mwar-2 {
  margin-bottom: calc(-1 * var(--margin-width-2));
}

.mwlr-2,
.mwxr-2,
.mwar-2 {
  margin-left: calc(-1 * var(--margin-width-2));
}

/*
.mw*-3

Sets the margin of element to css var for 3.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-3 - Sets all margins to 3
.mwx-3 - Sets left and right margins to 3
.mwy-3 - Sets top and bottom margins to 3
.mwt-3 - Sets top margin to 3
.mwr-3 - Sets right margin to 3
.mwb-3 - Sets bottom margin to 3
.mwl-3 - Sets left margin to 3

Styleguide Margin - Width - 3
*/
.mwt-3,
.mwy-3,
.mwa-3 {
  margin-top: var(--margin-width-3);
}

.mwr-3,
.mwx-3,
.mwa-3 {
  margin-right: var(--margin-width-3);
}

.mwb-3,
.mwy-3,
.mwa-3 {
  margin-bottom: var(--margin-width-3);
}

.mwl-3,
.mwx-3,
.mwa-3 {
  margin-left: var(--margin-width-3);
}

/*
.mw*r-3

Sets the margin of element to the negative value of the css var for 3.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-3 - Sets all margins to m3
.mwxr-3 - Sets left and right margins to m3
.mwyr-3 - Sets top and bottom margins to m3
.mwtr-3 - Sets top margin to m3
.mwrr-3 - Sets right margin to m3
.mwbr-3 - Sets bottom margin to m3
.mwlr-3 - Sets left margin to m3

Styleguide Margin - Width - 3
*/
.mwtr-3,
.mwyr-3,
.mwar-3 {
  margin-top: calc(-1 * var(--margin-width-3));
}

.mwrr-3,
.mwxr-3,
.mwar-3 {
  margin-right: calc(-1 * var(--margin-width-3));
}

.mwbr-3,
.mwyr-3,
.mwar-3 {
  margin-bottom: calc(-1 * var(--margin-width-3));
}

.mwlr-3,
.mwxr-3,
.mwar-3 {
  margin-left: calc(-1 * var(--margin-width-3));
}

/*
.mw*-4

Sets the margin of element to css var for 4.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-4 - Sets all margins to 4
.mwx-4 - Sets left and right margins to 4
.mwy-4 - Sets top and bottom margins to 4
.mwt-4 - Sets top margin to 4
.mwr-4 - Sets right margin to 4
.mwb-4 - Sets bottom margin to 4
.mwl-4 - Sets left margin to 4

Styleguide Margin - Width - 4
*/
.mwt-4,
.mwy-4,
.mwa-4 {
  margin-top: var(--margin-width-4);
}

.mwr-4,
.mwx-4,
.mwa-4 {
  margin-right: var(--margin-width-4);
}

.mwb-4,
.mwy-4,
.mwa-4 {
  margin-bottom: var(--margin-width-4);
}

.mwl-4,
.mwx-4,
.mwa-4 {
  margin-left: var(--margin-width-4);
}

/*
.mw*r-4

Sets the margin of element to the negative value of the css var for 4.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-4 - Sets all margins to m4
.mwxr-4 - Sets left and right margins to m4
.mwyr-4 - Sets top and bottom margins to m4
.mwtr-4 - Sets top margin to m4
.mwrr-4 - Sets right margin to m4
.mwbr-4 - Sets bottom margin to m4
.mwlr-4 - Sets left margin to m4

Styleguide Margin - Width - 4
*/
.mwtr-4,
.mwyr-4,
.mwar-4 {
  margin-top: calc(-1 * var(--margin-width-4));
}

.mwrr-4,
.mwxr-4,
.mwar-4 {
  margin-right: calc(-1 * var(--margin-width-4));
}

.mwbr-4,
.mwyr-4,
.mwar-4 {
  margin-bottom: calc(-1 * var(--margin-width-4));
}

.mwlr-4,
.mwxr-4,
.mwar-4 {
  margin-left: calc(-1 * var(--margin-width-4));
}

/*
.mw*-5

Sets the margin of element to css var for 5.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-5 - Sets all margins to 5
.mwx-5 - Sets left and right margins to 5
.mwy-5 - Sets top and bottom margins to 5
.mwt-5 - Sets top margin to 5
.mwr-5 - Sets right margin to 5
.mwb-5 - Sets bottom margin to 5
.mwl-5 - Sets left margin to 5

Styleguide Margin - Width - 5
*/
.mwt-5,
.mwy-5,
.mwa-5 {
  margin-top: var(--margin-width-5);
}

.mwr-5,
.mwx-5,
.mwa-5 {
  margin-right: var(--margin-width-5);
}

.mwb-5,
.mwy-5,
.mwa-5 {
  margin-bottom: var(--margin-width-5);
}

.mwl-5,
.mwx-5,
.mwa-5 {
  margin-left: var(--margin-width-5);
}

/*
.mw*r-5

Sets the margin of element to the negative value of the css var for 5.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-5 - Sets all margins to m5
.mwxr-5 - Sets left and right margins to m5
.mwyr-5 - Sets top and bottom margins to m5
.mwtr-5 - Sets top margin to m5
.mwrr-5 - Sets right margin to m5
.mwbr-5 - Sets bottom margin to m5
.mwlr-5 - Sets left margin to m5

Styleguide Margin - Width - 5
*/
.mwtr-5,
.mwyr-5,
.mwar-5 {
  margin-top: calc(-1 * var(--margin-width-5));
}

.mwrr-5,
.mwxr-5,
.mwar-5 {
  margin-right: calc(-1 * var(--margin-width-5));
}

.mwbr-5,
.mwyr-5,
.mwar-5 {
  margin-bottom: calc(-1 * var(--margin-width-5));
}

.mwlr-5,
.mwxr-5,
.mwar-5 {
  margin-left: calc(-1 * var(--margin-width-5));
}

/*
.mw*-6

Sets the margin of element to css var for 6.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-6 - Sets all margins to 6
.mwx-6 - Sets left and right margins to 6
.mwy-6 - Sets top and bottom margins to 6
.mwt-6 - Sets top margin to 6
.mwr-6 - Sets right margin to 6
.mwb-6 - Sets bottom margin to 6
.mwl-6 - Sets left margin to 6

Styleguide Margin - Width - 6
*/
.mwt-6,
.mwy-6,
.mwa-6 {
  margin-top: var(--margin-width-6);
}

.mwr-6,
.mwx-6,
.mwa-6 {
  margin-right: var(--margin-width-6);
}

.mwb-6,
.mwy-6,
.mwa-6 {
  margin-bottom: var(--margin-width-6);
}

.mwl-6,
.mwx-6,
.mwa-6 {
  margin-left: var(--margin-width-6);
}

/*
.mw*r-6

Sets the margin of element to the negative value of the css var for 6.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-6 - Sets all margins to m6
.mwxr-6 - Sets left and right margins to m6
.mwyr-6 - Sets top and bottom margins to m6
.mwtr-6 - Sets top margin to m6
.mwrr-6 - Sets right margin to m6
.mwbr-6 - Sets bottom margin to m6
.mwlr-6 - Sets left margin to m6

Styleguide Margin - Width - 6
*/
.mwtr-6,
.mwyr-6,
.mwar-6 {
  margin-top: calc(-1 * var(--margin-width-6));
}

.mwrr-6,
.mwxr-6,
.mwar-6 {
  margin-right: calc(-1 * var(--margin-width-6));
}

.mwbr-6,
.mwyr-6,
.mwar-6 {
  margin-bottom: calc(-1 * var(--margin-width-6));
}

.mwlr-6,
.mwxr-6,
.mwar-6 {
  margin-left: calc(-1 * var(--margin-width-6));
}

/*
.mw*-7

Sets the margin of element to css var for 7.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-7 - Sets all margins to 7
.mwx-7 - Sets left and right margins to 7
.mwy-7 - Sets top and bottom margins to 7
.mwt-7 - Sets top margin to 7
.mwr-7 - Sets right margin to 7
.mwb-7 - Sets bottom margin to 7
.mwl-7 - Sets left margin to 7

Styleguide Margin - Width - 7
*/
.mwt-7,
.mwy-7,
.mwa-7 {
  margin-top: var(--margin-width-7);
}

.mwr-7,
.mwx-7,
.mwa-7 {
  margin-right: var(--margin-width-7);
}

.mwb-7,
.mwy-7,
.mwa-7 {
  margin-bottom: var(--margin-width-7);
}

.mwl-7,
.mwx-7,
.mwa-7 {
  margin-left: var(--margin-width-7);
}

/*
.mw*r-7

Sets the margin of element to the negative value of the css var for 7.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-7 - Sets all margins to m7
.mwxr-7 - Sets left and right margins to m7
.mwyr-7 - Sets top and bottom margins to m7
.mwtr-7 - Sets top margin to m7
.mwrr-7 - Sets right margin to m7
.mwbr-7 - Sets bottom margin to m7
.mwlr-7 - Sets left margin to m7

Styleguide Margin - Width - 7
*/
.mwtr-7,
.mwyr-7,
.mwar-7 {
  margin-top: calc(-1 * var(--margin-width-7));
}

.mwrr-7,
.mwxr-7,
.mwar-7 {
  margin-right: calc(-1 * var(--margin-width-7));
}

.mwbr-7,
.mwyr-7,
.mwar-7 {
  margin-bottom: calc(-1 * var(--margin-width-7));
}

.mwlr-7,
.mwxr-7,
.mwar-7 {
  margin-left: calc(-1 * var(--margin-width-7));
}

/*
.mw*-p10

Sets the margin of element to css var for p10.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p10 - Sets all margins to p10
.mwx-p10 - Sets left and right margins to p10
.mwy-p10 - Sets top and bottom margins to p10
.mwt-p10 - Sets top margin to p10
.mwr-p10 - Sets right margin to p10
.mwb-p10 - Sets bottom margin to p10
.mwl-p10 - Sets left margin to p10

Styleguide Margin - Width - p10
*/
.mwt-p10,
.mwy-p10,
.mwa-p10 {
  margin-top: var(--margin-width-p10);
}

.mwr-p10,
.mwx-p10,
.mwa-p10 {
  margin-right: var(--margin-width-p10);
}

.mwb-p10,
.mwy-p10,
.mwa-p10 {
  margin-bottom: var(--margin-width-p10);
}

.mwl-p10,
.mwx-p10,
.mwa-p10 {
  margin-left: var(--margin-width-p10);
}

/*
.mw*r-p10

Sets the margin of element to the negative value of the css var for p10.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p10 - Sets all margins to mp10
.mwxr-p10 - Sets left and right margins to mp10
.mwyr-p10 - Sets top and bottom margins to mp10
.mwtr-p10 - Sets top margin to mp10
.mwrr-p10 - Sets right margin to mp10
.mwbr-p10 - Sets bottom margin to mp10
.mwlr-p10 - Sets left margin to mp10

Styleguide Margin - Width - p10
*/
.mwtr-p10,
.mwyr-p10,
.mwar-p10 {
  margin-top: calc(-1 * var(--margin-width-p10));
}

.mwrr-p10,
.mwxr-p10,
.mwar-p10 {
  margin-right: calc(-1 * var(--margin-width-p10));
}

.mwbr-p10,
.mwyr-p10,
.mwar-p10 {
  margin-bottom: calc(-1 * var(--margin-width-p10));
}

.mwlr-p10,
.mwxr-p10,
.mwar-p10 {
  margin-left: calc(-1 * var(--margin-width-p10));
}

/*
.mw*-p20

Sets the margin of element to css var for p20.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p20 - Sets all margins to p20
.mwx-p20 - Sets left and right margins to p20
.mwy-p20 - Sets top and bottom margins to p20
.mwt-p20 - Sets top margin to p20
.mwr-p20 - Sets right margin to p20
.mwb-p20 - Sets bottom margin to p20
.mwl-p20 - Sets left margin to p20

Styleguide Margin - Width - p20
*/
.mwt-p20,
.mwy-p20,
.mwa-p20 {
  margin-top: var(--margin-width-p20);
}

.mwr-p20,
.mwx-p20,
.mwa-p20 {
  margin-right: var(--margin-width-p20);
}

.mwb-p20,
.mwy-p20,
.mwa-p20 {
  margin-bottom: var(--margin-width-p20);
}

.mwl-p20,
.mwx-p20,
.mwa-p20 {
  margin-left: var(--margin-width-p20);
}

/*
.mw*r-p20

Sets the margin of element to the negative value of the css var for p20.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p20 - Sets all margins to mp20
.mwxr-p20 - Sets left and right margins to mp20
.mwyr-p20 - Sets top and bottom margins to mp20
.mwtr-p20 - Sets top margin to mp20
.mwrr-p20 - Sets right margin to mp20
.mwbr-p20 - Sets bottom margin to mp20
.mwlr-p20 - Sets left margin to mp20

Styleguide Margin - Width - p20
*/
.mwtr-p20,
.mwyr-p20,
.mwar-p20 {
  margin-top: calc(-1 * var(--margin-width-p20));
}

.mwrr-p20,
.mwxr-p20,
.mwar-p20 {
  margin-right: calc(-1 * var(--margin-width-p20));
}

.mwbr-p20,
.mwyr-p20,
.mwar-p20 {
  margin-bottom: calc(-1 * var(--margin-width-p20));
}

.mwlr-p20,
.mwxr-p20,
.mwar-p20 {
  margin-left: calc(-1 * var(--margin-width-p20));
}

/*
.mw*-p25

Sets the margin of element to css var for p25.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p25 - Sets all margins to p25
.mwx-p25 - Sets left and right margins to p25
.mwy-p25 - Sets top and bottom margins to p25
.mwt-p25 - Sets top margin to p25
.mwr-p25 - Sets right margin to p25
.mwb-p25 - Sets bottom margin to p25
.mwl-p25 - Sets left margin to p25

Styleguide Margin - Width - p25
*/
.mwt-p25,
.mwy-p25,
.mwa-p25 {
  margin-top: var(--margin-width-p25);
}

.mwr-p25,
.mwx-p25,
.mwa-p25 {
  margin-right: var(--margin-width-p25);
}

.mwb-p25,
.mwy-p25,
.mwa-p25 {
  margin-bottom: var(--margin-width-p25);
}

.mwl-p25,
.mwx-p25,
.mwa-p25 {
  margin-left: var(--margin-width-p25);
}

/*
.mw*r-p25

Sets the margin of element to the negative value of the css var for p25.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p25 - Sets all margins to mp25
.mwxr-p25 - Sets left and right margins to mp25
.mwyr-p25 - Sets top and bottom margins to mp25
.mwtr-p25 - Sets top margin to mp25
.mwrr-p25 - Sets right margin to mp25
.mwbr-p25 - Sets bottom margin to mp25
.mwlr-p25 - Sets left margin to mp25

Styleguide Margin - Width - p25
*/
.mwtr-p25,
.mwyr-p25,
.mwar-p25 {
  margin-top: calc(-1 * var(--margin-width-p25));
}

.mwrr-p25,
.mwxr-p25,
.mwar-p25 {
  margin-right: calc(-1 * var(--margin-width-p25));
}

.mwbr-p25,
.mwyr-p25,
.mwar-p25 {
  margin-bottom: calc(-1 * var(--margin-width-p25));
}

.mwlr-p25,
.mwxr-p25,
.mwar-p25 {
  margin-left: calc(-1 * var(--margin-width-p25));
}

/*
.mw*-p30

Sets the margin of element to css var for p30.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p30 - Sets all margins to p30
.mwx-p30 - Sets left and right margins to p30
.mwy-p30 - Sets top and bottom margins to p30
.mwt-p30 - Sets top margin to p30
.mwr-p30 - Sets right margin to p30
.mwb-p30 - Sets bottom margin to p30
.mwl-p30 - Sets left margin to p30

Styleguide Margin - Width - p30
*/
.mwt-p30,
.mwy-p30,
.mwa-p30 {
  margin-top: var(--margin-width-p30);
}

.mwr-p30,
.mwx-p30,
.mwa-p30 {
  margin-right: var(--margin-width-p30);
}

.mwb-p30,
.mwy-p30,
.mwa-p30 {
  margin-bottom: var(--margin-width-p30);
}

.mwl-p30,
.mwx-p30,
.mwa-p30 {
  margin-left: var(--margin-width-p30);
}

/*
.mw*r-p30

Sets the margin of element to the negative value of the css var for p30.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p30 - Sets all margins to mp30
.mwxr-p30 - Sets left and right margins to mp30
.mwyr-p30 - Sets top and bottom margins to mp30
.mwtr-p30 - Sets top margin to mp30
.mwrr-p30 - Sets right margin to mp30
.mwbr-p30 - Sets bottom margin to mp30
.mwlr-p30 - Sets left margin to mp30

Styleguide Margin - Width - p30
*/
.mwtr-p30,
.mwyr-p30,
.mwar-p30 {
  margin-top: calc(-1 * var(--margin-width-p30));
}

.mwrr-p30,
.mwxr-p30,
.mwar-p30 {
  margin-right: calc(-1 * var(--margin-width-p30));
}

.mwbr-p30,
.mwyr-p30,
.mwar-p30 {
  margin-bottom: calc(-1 * var(--margin-width-p30));
}

.mwlr-p30,
.mwxr-p30,
.mwar-p30 {
  margin-left: calc(-1 * var(--margin-width-p30));
}

/*
.mw*-p40

Sets the margin of element to css var for p40.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p40 - Sets all margins to p40
.mwx-p40 - Sets left and right margins to p40
.mwy-p40 - Sets top and bottom margins to p40
.mwt-p40 - Sets top margin to p40
.mwr-p40 - Sets right margin to p40
.mwb-p40 - Sets bottom margin to p40
.mwl-p40 - Sets left margin to p40

Styleguide Margin - Width - p40
*/
.mwt-p40,
.mwy-p40,
.mwa-p40 {
  margin-top: var(--margin-width-p40);
}

.mwr-p40,
.mwx-p40,
.mwa-p40 {
  margin-right: var(--margin-width-p40);
}

.mwb-p40,
.mwy-p40,
.mwa-p40 {
  margin-bottom: var(--margin-width-p40);
}

.mwl-p40,
.mwx-p40,
.mwa-p40 {
  margin-left: var(--margin-width-p40);
}

/*
.mw*r-p40

Sets the margin of element to the negative value of the css var for p40.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p40 - Sets all margins to mp40
.mwxr-p40 - Sets left and right margins to mp40
.mwyr-p40 - Sets top and bottom margins to mp40
.mwtr-p40 - Sets top margin to mp40
.mwrr-p40 - Sets right margin to mp40
.mwbr-p40 - Sets bottom margin to mp40
.mwlr-p40 - Sets left margin to mp40

Styleguide Margin - Width - p40
*/
.mwtr-p40,
.mwyr-p40,
.mwar-p40 {
  margin-top: calc(-1 * var(--margin-width-p40));
}

.mwrr-p40,
.mwxr-p40,
.mwar-p40 {
  margin-right: calc(-1 * var(--margin-width-p40));
}

.mwbr-p40,
.mwyr-p40,
.mwar-p40 {
  margin-bottom: calc(-1 * var(--margin-width-p40));
}

.mwlr-p40,
.mwxr-p40,
.mwar-p40 {
  margin-left: calc(-1 * var(--margin-width-p40));
}

/*
.mw*-p50

Sets the margin of element to css var for p50.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p50 - Sets all margins to p50
.mwx-p50 - Sets left and right margins to p50
.mwy-p50 - Sets top and bottom margins to p50
.mwt-p50 - Sets top margin to p50
.mwr-p50 - Sets right margin to p50
.mwb-p50 - Sets bottom margin to p50
.mwl-p50 - Sets left margin to p50

Styleguide Margin - Width - p50
*/
.mwt-p50,
.mwy-p50,
.mwa-p50 {
  margin-top: var(--margin-width-p50);
}

.mwr-p50,
.mwx-p50,
.mwa-p50 {
  margin-right: var(--margin-width-p50);
}

.mwb-p50,
.mwy-p50,
.mwa-p50 {
  margin-bottom: var(--margin-width-p50);
}

.mwl-p50,
.mwx-p50,
.mwa-p50 {
  margin-left: var(--margin-width-p50);
}

/*
.mw*r-p50

Sets the margin of element to the negative value of the css var for p50.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p50 - Sets all margins to mp50
.mwxr-p50 - Sets left and right margins to mp50
.mwyr-p50 - Sets top and bottom margins to mp50
.mwtr-p50 - Sets top margin to mp50
.mwrr-p50 - Sets right margin to mp50
.mwbr-p50 - Sets bottom margin to mp50
.mwlr-p50 - Sets left margin to mp50

Styleguide Margin - Width - p50
*/
.mwtr-p50,
.mwyr-p50,
.mwar-p50 {
  margin-top: calc(-1 * var(--margin-width-p50));
}

.mwrr-p50,
.mwxr-p50,
.mwar-p50 {
  margin-right: calc(-1 * var(--margin-width-p50));
}

.mwbr-p50,
.mwyr-p50,
.mwar-p50 {
  margin-bottom: calc(-1 * var(--margin-width-p50));
}

.mwlr-p50,
.mwxr-p50,
.mwar-p50 {
  margin-left: calc(-1 * var(--margin-width-p50));
}

/*
.mw*-p60

Sets the margin of element to css var for p60.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p60 - Sets all margins to p60
.mwx-p60 - Sets left and right margins to p60
.mwy-p60 - Sets top and bottom margins to p60
.mwt-p60 - Sets top margin to p60
.mwr-p60 - Sets right margin to p60
.mwb-p60 - Sets bottom margin to p60
.mwl-p60 - Sets left margin to p60

Styleguide Margin - Width - p60
*/
.mwt-p60,
.mwy-p60,
.mwa-p60 {
  margin-top: var(--margin-width-p60);
}

.mwr-p60,
.mwx-p60,
.mwa-p60 {
  margin-right: var(--margin-width-p60);
}

.mwb-p60,
.mwy-p60,
.mwa-p60 {
  margin-bottom: var(--margin-width-p60);
}

.mwl-p60,
.mwx-p60,
.mwa-p60 {
  margin-left: var(--margin-width-p60);
}

/*
.mw*r-p60

Sets the margin of element to the negative value of the css var for p60.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p60 - Sets all margins to mp60
.mwxr-p60 - Sets left and right margins to mp60
.mwyr-p60 - Sets top and bottom margins to mp60
.mwtr-p60 - Sets top margin to mp60
.mwrr-p60 - Sets right margin to mp60
.mwbr-p60 - Sets bottom margin to mp60
.mwlr-p60 - Sets left margin to mp60

Styleguide Margin - Width - p60
*/
.mwtr-p60,
.mwyr-p60,
.mwar-p60 {
  margin-top: calc(-1 * var(--margin-width-p60));
}

.mwrr-p60,
.mwxr-p60,
.mwar-p60 {
  margin-right: calc(-1 * var(--margin-width-p60));
}

.mwbr-p60,
.mwyr-p60,
.mwar-p60 {
  margin-bottom: calc(-1 * var(--margin-width-p60));
}

.mwlr-p60,
.mwxr-p60,
.mwar-p60 {
  margin-left: calc(-1 * var(--margin-width-p60));
}

/*
.mw*-p70

Sets the margin of element to css var for p70.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p70 - Sets all margins to p70
.mwx-p70 - Sets left and right margins to p70
.mwy-p70 - Sets top and bottom margins to p70
.mwt-p70 - Sets top margin to p70
.mwr-p70 - Sets right margin to p70
.mwb-p70 - Sets bottom margin to p70
.mwl-p70 - Sets left margin to p70

Styleguide Margin - Width - p70
*/
.mwt-p70,
.mwy-p70,
.mwa-p70 {
  margin-top: var(--margin-width-p70);
}

.mwr-p70,
.mwx-p70,
.mwa-p70 {
  margin-right: var(--margin-width-p70);
}

.mwb-p70,
.mwy-p70,
.mwa-p70 {
  margin-bottom: var(--margin-width-p70);
}

.mwl-p70,
.mwx-p70,
.mwa-p70 {
  margin-left: var(--margin-width-p70);
}

/*
.mw*r-p70

Sets the margin of element to the negative value of the css var for p70.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p70 - Sets all margins to mp70
.mwxr-p70 - Sets left and right margins to mp70
.mwyr-p70 - Sets top and bottom margins to mp70
.mwtr-p70 - Sets top margin to mp70
.mwrr-p70 - Sets right margin to mp70
.mwbr-p70 - Sets bottom margin to mp70
.mwlr-p70 - Sets left margin to mp70

Styleguide Margin - Width - p70
*/
.mwtr-p70,
.mwyr-p70,
.mwar-p70 {
  margin-top: calc(-1 * var(--margin-width-p70));
}

.mwrr-p70,
.mwxr-p70,
.mwar-p70 {
  margin-right: calc(-1 * var(--margin-width-p70));
}

.mwbr-p70,
.mwyr-p70,
.mwar-p70 {
  margin-bottom: calc(-1 * var(--margin-width-p70));
}

.mwlr-p70,
.mwxr-p70,
.mwar-p70 {
  margin-left: calc(-1 * var(--margin-width-p70));
}

/*
.mw*-p75

Sets the margin of element to css var for p75.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p75 - Sets all margins to p75
.mwx-p75 - Sets left and right margins to p75
.mwy-p75 - Sets top and bottom margins to p75
.mwt-p75 - Sets top margin to p75
.mwr-p75 - Sets right margin to p75
.mwb-p75 - Sets bottom margin to p75
.mwl-p75 - Sets left margin to p75

Styleguide Margin - Width - p75
*/
.mwt-p75,
.mwy-p75,
.mwa-p75 {
  margin-top: var(--margin-width-p75);
}

.mwr-p75,
.mwx-p75,
.mwa-p75 {
  margin-right: var(--margin-width-p75);
}

.mwb-p75,
.mwy-p75,
.mwa-p75 {
  margin-bottom: var(--margin-width-p75);
}

.mwl-p75,
.mwx-p75,
.mwa-p75 {
  margin-left: var(--margin-width-p75);
}

/*
.mw*r-p75

Sets the margin of element to the negative value of the css var for p75.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p75 - Sets all margins to mp75
.mwxr-p75 - Sets left and right margins to mp75
.mwyr-p75 - Sets top and bottom margins to mp75
.mwtr-p75 - Sets top margin to mp75
.mwrr-p75 - Sets right margin to mp75
.mwbr-p75 - Sets bottom margin to mp75
.mwlr-p75 - Sets left margin to mp75

Styleguide Margin - Width - p75
*/
.mwtr-p75,
.mwyr-p75,
.mwar-p75 {
  margin-top: calc(-1 * var(--margin-width-p75));
}

.mwrr-p75,
.mwxr-p75,
.mwar-p75 {
  margin-right: calc(-1 * var(--margin-width-p75));
}

.mwbr-p75,
.mwyr-p75,
.mwar-p75 {
  margin-bottom: calc(-1 * var(--margin-width-p75));
}

.mwlr-p75,
.mwxr-p75,
.mwar-p75 {
  margin-left: calc(-1 * var(--margin-width-p75));
}

/*
.mw*-p80

Sets the margin of element to css var for p80.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p80 - Sets all margins to p80
.mwx-p80 - Sets left and right margins to p80
.mwy-p80 - Sets top and bottom margins to p80
.mwt-p80 - Sets top margin to p80
.mwr-p80 - Sets right margin to p80
.mwb-p80 - Sets bottom margin to p80
.mwl-p80 - Sets left margin to p80

Styleguide Margin - Width - p80
*/
.mwt-p80,
.mwy-p80,
.mwa-p80 {
  margin-top: var(--margin-width-p80);
}

.mwr-p80,
.mwx-p80,
.mwa-p80 {
  margin-right: var(--margin-width-p80);
}

.mwb-p80,
.mwy-p80,
.mwa-p80 {
  margin-bottom: var(--margin-width-p80);
}

.mwl-p80,
.mwx-p80,
.mwa-p80 {
  margin-left: var(--margin-width-p80);
}

/*
.mw*r-p80

Sets the margin of element to the negative value of the css var for p80.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p80 - Sets all margins to mp80
.mwxr-p80 - Sets left and right margins to mp80
.mwyr-p80 - Sets top and bottom margins to mp80
.mwtr-p80 - Sets top margin to mp80
.mwrr-p80 - Sets right margin to mp80
.mwbr-p80 - Sets bottom margin to mp80
.mwlr-p80 - Sets left margin to mp80

Styleguide Margin - Width - p80
*/
.mwtr-p80,
.mwyr-p80,
.mwar-p80 {
  margin-top: calc(-1 * var(--margin-width-p80));
}

.mwrr-p80,
.mwxr-p80,
.mwar-p80 {
  margin-right: calc(-1 * var(--margin-width-p80));
}

.mwbr-p80,
.mwyr-p80,
.mwar-p80 {
  margin-bottom: calc(-1 * var(--margin-width-p80));
}

.mwlr-p80,
.mwxr-p80,
.mwar-p80 {
  margin-left: calc(-1 * var(--margin-width-p80));
}

/*
.mw*-p90

Sets the margin of element to css var for p90.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p90 - Sets all margins to p90
.mwx-p90 - Sets left and right margins to p90
.mwy-p90 - Sets top and bottom margins to p90
.mwt-p90 - Sets top margin to p90
.mwr-p90 - Sets right margin to p90
.mwb-p90 - Sets bottom margin to p90
.mwl-p90 - Sets left margin to p90

Styleguide Margin - Width - p90
*/
.mwt-p90,
.mwy-p90,
.mwa-p90 {
  margin-top: var(--margin-width-p90);
}

.mwr-p90,
.mwx-p90,
.mwa-p90 {
  margin-right: var(--margin-width-p90);
}

.mwb-p90,
.mwy-p90,
.mwa-p90 {
  margin-bottom: var(--margin-width-p90);
}

.mwl-p90,
.mwx-p90,
.mwa-p90 {
  margin-left: var(--margin-width-p90);
}

/*
.mw*r-p90

Sets the margin of element to the negative value of the css var for p90.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p90 - Sets all margins to mp90
.mwxr-p90 - Sets left and right margins to mp90
.mwyr-p90 - Sets top and bottom margins to mp90
.mwtr-p90 - Sets top margin to mp90
.mwrr-p90 - Sets right margin to mp90
.mwbr-p90 - Sets bottom margin to mp90
.mwlr-p90 - Sets left margin to mp90

Styleguide Margin - Width - p90
*/
.mwtr-p90,
.mwyr-p90,
.mwar-p90 {
  margin-top: calc(-1 * var(--margin-width-p90));
}

.mwrr-p90,
.mwxr-p90,
.mwar-p90 {
  margin-right: calc(-1 * var(--margin-width-p90));
}

.mwbr-p90,
.mwyr-p90,
.mwar-p90 {
  margin-bottom: calc(-1 * var(--margin-width-p90));
}

.mwlr-p90,
.mwxr-p90,
.mwar-p90 {
  margin-left: calc(-1 * var(--margin-width-p90));
}

/*
.mw*-p100

Sets the margin of element to css var for p100.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwa-p100 - Sets all margins to p100
.mwx-p100 - Sets left and right margins to p100
.mwy-p100 - Sets top and bottom margins to p100
.mwt-p100 - Sets top margin to p100
.mwr-p100 - Sets right margin to p100
.mwb-p100 - Sets bottom margin to p100
.mwl-p100 - Sets left margin to p100

Styleguide Margin - Width - p100
*/
.mwt-p100,
.mwy-p100,
.mwa-p100 {
  margin-top: var(--margin-width-p100);
}

.mwr-p100,
.mwx-p100,
.mwa-p100 {
  margin-right: var(--margin-width-p100);
}

.mwb-p100,
.mwy-p100,
.mwa-p100 {
  margin-bottom: var(--margin-width-p100);
}

.mwl-p100,
.mwx-p100,
.mwa-p100 {
  margin-left: var(--margin-width-p100);
}

/*
.mw*r-p100

Sets the margin of element to the negative value of the css var for p100.

Markup:
<div class="{{modifier_class}}">A div</div>

.mwar-p100 - Sets all margins to mp100
.mwxr-p100 - Sets left and right margins to mp100
.mwyr-p100 - Sets top and bottom margins to mp100
.mwtr-p100 - Sets top margin to mp100
.mwrr-p100 - Sets right margin to mp100
.mwbr-p100 - Sets bottom margin to mp100
.mwlr-p100 - Sets left margin to mp100

Styleguide Margin - Width - p100
*/
.mwtr-p100,
.mwyr-p100,
.mwar-p100 {
  margin-top: calc(-1 * var(--margin-width-p100));
}

.mwrr-p100,
.mwxr-p100,
.mwar-p100 {
  margin-right: calc(-1 * var(--margin-width-p100));
}

.mwbr-p100,
.mwyr-p100,
.mwar-p100 {
  margin-bottom: calc(-1 * var(--margin-width-p100));
}

.mwlr-p100,
.mwxr-p100,
.mwar-p100 {
  margin-left: calc(-1 * var(--margin-width-p100));
}

@media (min-width: 340px) {
  /*
  .mw*-sm-0

  Sets the margin of element to css var for 0 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-0 - Sets all margins to 0 on sm
  .mwx-sm-0 - Sets left and right margins to 0 on sm
  .mwy-sm-0 - Sets top and bottom margins to 0 on sm
  .mwt-sm-0 - Sets top margin to 0 on sm
  .mwr-sm-0 - Sets right margin to 0 on sm
  .mwb-sm-0 - Sets bottom margin to 0 on sm
  .mwl-sm-0 - Sets left margin to 0 on sm

  Styleguide Margin - Width - 0
  */
  .mwt-sm-0,
  .mwy-sm-0,
  .mwa-sm-0 {
    margin-top: var(--margin-width-0);
  }

  .mwr-sm-0,
  .mwx-sm-0,
  .mwa-sm-0 {
    margin-right: var(--margin-width-0);
  }

  .mwb-sm-0,
  .mwy-sm-0,
  .mwa-sm-0 {
    margin-bottom: var(--margin-width-0);
  }

  .mwl-sm-0,
  .mwx-sm-0,
  .mwa-sm-0 {
    margin-left: var(--margin-width-0);
  }

  /*
  .mw*r-sm-0

  Sets the margin of element to negative value of the css var for 0 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-0 - Sets all margins to m0 on sm
  .mwxr-sm-0 - Sets left and right margins to m0 on sm
  .mwyr-sm-0 - Sets top and bottom margins to m0 on sm
  .mwtr-sm-0 - Sets top margin to m0 on sm
  .mwrr-sm-0 - Sets right margin to m0 on sm
  .mwbr-sm-0 - Sets bottom margin to m0 on sm
  .mwlr-sm-0 - Sets left margin to m0 on sm

  Styleguide Margin - Width - m0
  */
  .mwtr-sm-0,
  .mwyr-sm-0,
  .mwar-sm-0 {
    margin-top: calc(-1 * var(--margin-width-0));
  }

  .mwrr-sm-0,
  .mwxr-sm-0,
  .mwar-sm-0 {
    margin-right: calc(-1 * var(--margin-width-0));
  }

  .mwbr-sm-0,
  .mwyr-sm-0,
  .mwar-sm-0 {
    margin-bottom: calc(-1 * var(--margin-width-0));
  }

  .mwlr-sm-0,
  .mwxr-sm-0,
  .mwar-sm-0 {
    margin-left: calc(-1 * var(--margin-width-0));
  }

  /*
  .mw*-sm-1

  Sets the margin of element to css var for 1 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-1 - Sets all margins to 1 on sm
  .mwx-sm-1 - Sets left and right margins to 1 on sm
  .mwy-sm-1 - Sets top and bottom margins to 1 on sm
  .mwt-sm-1 - Sets top margin to 1 on sm
  .mwr-sm-1 - Sets right margin to 1 on sm
  .mwb-sm-1 - Sets bottom margin to 1 on sm
  .mwl-sm-1 - Sets left margin to 1 on sm

  Styleguide Margin - Width - 1
  */
  .mwt-sm-1,
  .mwy-sm-1,
  .mwa-sm-1 {
    margin-top: var(--margin-width-1);
  }

  .mwr-sm-1,
  .mwx-sm-1,
  .mwa-sm-1 {
    margin-right: var(--margin-width-1);
  }

  .mwb-sm-1,
  .mwy-sm-1,
  .mwa-sm-1 {
    margin-bottom: var(--margin-width-1);
  }

  .mwl-sm-1,
  .mwx-sm-1,
  .mwa-sm-1 {
    margin-left: var(--margin-width-1);
  }

  /*
  .mw*r-sm-1

  Sets the margin of element to negative value of the css var for 1 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-1 - Sets all margins to m1 on sm
  .mwxr-sm-1 - Sets left and right margins to m1 on sm
  .mwyr-sm-1 - Sets top and bottom margins to m1 on sm
  .mwtr-sm-1 - Sets top margin to m1 on sm
  .mwrr-sm-1 - Sets right margin to m1 on sm
  .mwbr-sm-1 - Sets bottom margin to m1 on sm
  .mwlr-sm-1 - Sets left margin to m1 on sm

  Styleguide Margin - Width - m1
  */
  .mwtr-sm-1,
  .mwyr-sm-1,
  .mwar-sm-1 {
    margin-top: calc(-1 * var(--margin-width-1));
  }

  .mwrr-sm-1,
  .mwxr-sm-1,
  .mwar-sm-1 {
    margin-right: calc(-1 * var(--margin-width-1));
  }

  .mwbr-sm-1,
  .mwyr-sm-1,
  .mwar-sm-1 {
    margin-bottom: calc(-1 * var(--margin-width-1));
  }

  .mwlr-sm-1,
  .mwxr-sm-1,
  .mwar-sm-1 {
    margin-left: calc(-1 * var(--margin-width-1));
  }

  /*
  .mw*-sm-2

  Sets the margin of element to css var for 2 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-2 - Sets all margins to 2 on sm
  .mwx-sm-2 - Sets left and right margins to 2 on sm
  .mwy-sm-2 - Sets top and bottom margins to 2 on sm
  .mwt-sm-2 - Sets top margin to 2 on sm
  .mwr-sm-2 - Sets right margin to 2 on sm
  .mwb-sm-2 - Sets bottom margin to 2 on sm
  .mwl-sm-2 - Sets left margin to 2 on sm

  Styleguide Margin - Width - 2
  */
  .mwt-sm-2,
  .mwy-sm-2,
  .mwa-sm-2 {
    margin-top: var(--margin-width-2);
  }

  .mwr-sm-2,
  .mwx-sm-2,
  .mwa-sm-2 {
    margin-right: var(--margin-width-2);
  }

  .mwb-sm-2,
  .mwy-sm-2,
  .mwa-sm-2 {
    margin-bottom: var(--margin-width-2);
  }

  .mwl-sm-2,
  .mwx-sm-2,
  .mwa-sm-2 {
    margin-left: var(--margin-width-2);
  }

  /*
  .mw*r-sm-2

  Sets the margin of element to negative value of the css var for 2 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-2 - Sets all margins to m2 on sm
  .mwxr-sm-2 - Sets left and right margins to m2 on sm
  .mwyr-sm-2 - Sets top and bottom margins to m2 on sm
  .mwtr-sm-2 - Sets top margin to m2 on sm
  .mwrr-sm-2 - Sets right margin to m2 on sm
  .mwbr-sm-2 - Sets bottom margin to m2 on sm
  .mwlr-sm-2 - Sets left margin to m2 on sm

  Styleguide Margin - Width - m2
  */
  .mwtr-sm-2,
  .mwyr-sm-2,
  .mwar-sm-2 {
    margin-top: calc(-1 * var(--margin-width-2));
  }

  .mwrr-sm-2,
  .mwxr-sm-2,
  .mwar-sm-2 {
    margin-right: calc(-1 * var(--margin-width-2));
  }

  .mwbr-sm-2,
  .mwyr-sm-2,
  .mwar-sm-2 {
    margin-bottom: calc(-1 * var(--margin-width-2));
  }

  .mwlr-sm-2,
  .mwxr-sm-2,
  .mwar-sm-2 {
    margin-left: calc(-1 * var(--margin-width-2));
  }

  /*
  .mw*-sm-3

  Sets the margin of element to css var for 3 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-3 - Sets all margins to 3 on sm
  .mwx-sm-3 - Sets left and right margins to 3 on sm
  .mwy-sm-3 - Sets top and bottom margins to 3 on sm
  .mwt-sm-3 - Sets top margin to 3 on sm
  .mwr-sm-3 - Sets right margin to 3 on sm
  .mwb-sm-3 - Sets bottom margin to 3 on sm
  .mwl-sm-3 - Sets left margin to 3 on sm

  Styleguide Margin - Width - 3
  */
  .mwt-sm-3,
  .mwy-sm-3,
  .mwa-sm-3 {
    margin-top: var(--margin-width-3);
  }

  .mwr-sm-3,
  .mwx-sm-3,
  .mwa-sm-3 {
    margin-right: var(--margin-width-3);
  }

  .mwb-sm-3,
  .mwy-sm-3,
  .mwa-sm-3 {
    margin-bottom: var(--margin-width-3);
  }

  .mwl-sm-3,
  .mwx-sm-3,
  .mwa-sm-3 {
    margin-left: var(--margin-width-3);
  }

  /*
  .mw*r-sm-3

  Sets the margin of element to negative value of the css var for 3 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-3 - Sets all margins to m3 on sm
  .mwxr-sm-3 - Sets left and right margins to m3 on sm
  .mwyr-sm-3 - Sets top and bottom margins to m3 on sm
  .mwtr-sm-3 - Sets top margin to m3 on sm
  .mwrr-sm-3 - Sets right margin to m3 on sm
  .mwbr-sm-3 - Sets bottom margin to m3 on sm
  .mwlr-sm-3 - Sets left margin to m3 on sm

  Styleguide Margin - Width - m3
  */
  .mwtr-sm-3,
  .mwyr-sm-3,
  .mwar-sm-3 {
    margin-top: calc(-1 * var(--margin-width-3));
  }

  .mwrr-sm-3,
  .mwxr-sm-3,
  .mwar-sm-3 {
    margin-right: calc(-1 * var(--margin-width-3));
  }

  .mwbr-sm-3,
  .mwyr-sm-3,
  .mwar-sm-3 {
    margin-bottom: calc(-1 * var(--margin-width-3));
  }

  .mwlr-sm-3,
  .mwxr-sm-3,
  .mwar-sm-3 {
    margin-left: calc(-1 * var(--margin-width-3));
  }

  /*
  .mw*-sm-4

  Sets the margin of element to css var for 4 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-4 - Sets all margins to 4 on sm
  .mwx-sm-4 - Sets left and right margins to 4 on sm
  .mwy-sm-4 - Sets top and bottom margins to 4 on sm
  .mwt-sm-4 - Sets top margin to 4 on sm
  .mwr-sm-4 - Sets right margin to 4 on sm
  .mwb-sm-4 - Sets bottom margin to 4 on sm
  .mwl-sm-4 - Sets left margin to 4 on sm

  Styleguide Margin - Width - 4
  */
  .mwt-sm-4,
  .mwy-sm-4,
  .mwa-sm-4 {
    margin-top: var(--margin-width-4);
  }

  .mwr-sm-4,
  .mwx-sm-4,
  .mwa-sm-4 {
    margin-right: var(--margin-width-4);
  }

  .mwb-sm-4,
  .mwy-sm-4,
  .mwa-sm-4 {
    margin-bottom: var(--margin-width-4);
  }

  .mwl-sm-4,
  .mwx-sm-4,
  .mwa-sm-4 {
    margin-left: var(--margin-width-4);
  }

  /*
  .mw*r-sm-4

  Sets the margin of element to negative value of the css var for 4 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-4 - Sets all margins to m4 on sm
  .mwxr-sm-4 - Sets left and right margins to m4 on sm
  .mwyr-sm-4 - Sets top and bottom margins to m4 on sm
  .mwtr-sm-4 - Sets top margin to m4 on sm
  .mwrr-sm-4 - Sets right margin to m4 on sm
  .mwbr-sm-4 - Sets bottom margin to m4 on sm
  .mwlr-sm-4 - Sets left margin to m4 on sm

  Styleguide Margin - Width - m4
  */
  .mwtr-sm-4,
  .mwyr-sm-4,
  .mwar-sm-4 {
    margin-top: calc(-1 * var(--margin-width-4));
  }

  .mwrr-sm-4,
  .mwxr-sm-4,
  .mwar-sm-4 {
    margin-right: calc(-1 * var(--margin-width-4));
  }

  .mwbr-sm-4,
  .mwyr-sm-4,
  .mwar-sm-4 {
    margin-bottom: calc(-1 * var(--margin-width-4));
  }

  .mwlr-sm-4,
  .mwxr-sm-4,
  .mwar-sm-4 {
    margin-left: calc(-1 * var(--margin-width-4));
  }

  /*
  .mw*-sm-5

  Sets the margin of element to css var for 5 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-5 - Sets all margins to 5 on sm
  .mwx-sm-5 - Sets left and right margins to 5 on sm
  .mwy-sm-5 - Sets top and bottom margins to 5 on sm
  .mwt-sm-5 - Sets top margin to 5 on sm
  .mwr-sm-5 - Sets right margin to 5 on sm
  .mwb-sm-5 - Sets bottom margin to 5 on sm
  .mwl-sm-5 - Sets left margin to 5 on sm

  Styleguide Margin - Width - 5
  */
  .mwt-sm-5,
  .mwy-sm-5,
  .mwa-sm-5 {
    margin-top: var(--margin-width-5);
  }

  .mwr-sm-5,
  .mwx-sm-5,
  .mwa-sm-5 {
    margin-right: var(--margin-width-5);
  }

  .mwb-sm-5,
  .mwy-sm-5,
  .mwa-sm-5 {
    margin-bottom: var(--margin-width-5);
  }

  .mwl-sm-5,
  .mwx-sm-5,
  .mwa-sm-5 {
    margin-left: var(--margin-width-5);
  }

  /*
  .mw*r-sm-5

  Sets the margin of element to negative value of the css var for 5 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-5 - Sets all margins to m5 on sm
  .mwxr-sm-5 - Sets left and right margins to m5 on sm
  .mwyr-sm-5 - Sets top and bottom margins to m5 on sm
  .mwtr-sm-5 - Sets top margin to m5 on sm
  .mwrr-sm-5 - Sets right margin to m5 on sm
  .mwbr-sm-5 - Sets bottom margin to m5 on sm
  .mwlr-sm-5 - Sets left margin to m5 on sm

  Styleguide Margin - Width - m5
  */
  .mwtr-sm-5,
  .mwyr-sm-5,
  .mwar-sm-5 {
    margin-top: calc(-1 * var(--margin-width-5));
  }

  .mwrr-sm-5,
  .mwxr-sm-5,
  .mwar-sm-5 {
    margin-right: calc(-1 * var(--margin-width-5));
  }

  .mwbr-sm-5,
  .mwyr-sm-5,
  .mwar-sm-5 {
    margin-bottom: calc(-1 * var(--margin-width-5));
  }

  .mwlr-sm-5,
  .mwxr-sm-5,
  .mwar-sm-5 {
    margin-left: calc(-1 * var(--margin-width-5));
  }

  /*
  .mw*-sm-6

  Sets the margin of element to css var for 6 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-6 - Sets all margins to 6 on sm
  .mwx-sm-6 - Sets left and right margins to 6 on sm
  .mwy-sm-6 - Sets top and bottom margins to 6 on sm
  .mwt-sm-6 - Sets top margin to 6 on sm
  .mwr-sm-6 - Sets right margin to 6 on sm
  .mwb-sm-6 - Sets bottom margin to 6 on sm
  .mwl-sm-6 - Sets left margin to 6 on sm

  Styleguide Margin - Width - 6
  */
  .mwt-sm-6,
  .mwy-sm-6,
  .mwa-sm-6 {
    margin-top: var(--margin-width-6);
  }

  .mwr-sm-6,
  .mwx-sm-6,
  .mwa-sm-6 {
    margin-right: var(--margin-width-6);
  }

  .mwb-sm-6,
  .mwy-sm-6,
  .mwa-sm-6 {
    margin-bottom: var(--margin-width-6);
  }

  .mwl-sm-6,
  .mwx-sm-6,
  .mwa-sm-6 {
    margin-left: var(--margin-width-6);
  }

  /*
  .mw*r-sm-6

  Sets the margin of element to negative value of the css var for 6 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-6 - Sets all margins to m6 on sm
  .mwxr-sm-6 - Sets left and right margins to m6 on sm
  .mwyr-sm-6 - Sets top and bottom margins to m6 on sm
  .mwtr-sm-6 - Sets top margin to m6 on sm
  .mwrr-sm-6 - Sets right margin to m6 on sm
  .mwbr-sm-6 - Sets bottom margin to m6 on sm
  .mwlr-sm-6 - Sets left margin to m6 on sm

  Styleguide Margin - Width - m6
  */
  .mwtr-sm-6,
  .mwyr-sm-6,
  .mwar-sm-6 {
    margin-top: calc(-1 * var(--margin-width-6));
  }

  .mwrr-sm-6,
  .mwxr-sm-6,
  .mwar-sm-6 {
    margin-right: calc(-1 * var(--margin-width-6));
  }

  .mwbr-sm-6,
  .mwyr-sm-6,
  .mwar-sm-6 {
    margin-bottom: calc(-1 * var(--margin-width-6));
  }

  .mwlr-sm-6,
  .mwxr-sm-6,
  .mwar-sm-6 {
    margin-left: calc(-1 * var(--margin-width-6));
  }

  /*
  .mw*-sm-7

  Sets the margin of element to css var for 7 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-7 - Sets all margins to 7 on sm
  .mwx-sm-7 - Sets left and right margins to 7 on sm
  .mwy-sm-7 - Sets top and bottom margins to 7 on sm
  .mwt-sm-7 - Sets top margin to 7 on sm
  .mwr-sm-7 - Sets right margin to 7 on sm
  .mwb-sm-7 - Sets bottom margin to 7 on sm
  .mwl-sm-7 - Sets left margin to 7 on sm

  Styleguide Margin - Width - 7
  */
  .mwt-sm-7,
  .mwy-sm-7,
  .mwa-sm-7 {
    margin-top: var(--margin-width-7);
  }

  .mwr-sm-7,
  .mwx-sm-7,
  .mwa-sm-7 {
    margin-right: var(--margin-width-7);
  }

  .mwb-sm-7,
  .mwy-sm-7,
  .mwa-sm-7 {
    margin-bottom: var(--margin-width-7);
  }

  .mwl-sm-7,
  .mwx-sm-7,
  .mwa-sm-7 {
    margin-left: var(--margin-width-7);
  }

  /*
  .mw*r-sm-7

  Sets the margin of element to negative value of the css var for 7 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-7 - Sets all margins to m7 on sm
  .mwxr-sm-7 - Sets left and right margins to m7 on sm
  .mwyr-sm-7 - Sets top and bottom margins to m7 on sm
  .mwtr-sm-7 - Sets top margin to m7 on sm
  .mwrr-sm-7 - Sets right margin to m7 on sm
  .mwbr-sm-7 - Sets bottom margin to m7 on sm
  .mwlr-sm-7 - Sets left margin to m7 on sm

  Styleguide Margin - Width - m7
  */
  .mwtr-sm-7,
  .mwyr-sm-7,
  .mwar-sm-7 {
    margin-top: calc(-1 * var(--margin-width-7));
  }

  .mwrr-sm-7,
  .mwxr-sm-7,
  .mwar-sm-7 {
    margin-right: calc(-1 * var(--margin-width-7));
  }

  .mwbr-sm-7,
  .mwyr-sm-7,
  .mwar-sm-7 {
    margin-bottom: calc(-1 * var(--margin-width-7));
  }

  .mwlr-sm-7,
  .mwxr-sm-7,
  .mwar-sm-7 {
    margin-left: calc(-1 * var(--margin-width-7));
  }

  /*
  .mw*-sm-p10

  Sets the margin of element to css var for p10 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p10 - Sets all margins to p10 on sm
  .mwx-sm-p10 - Sets left and right margins to p10 on sm
  .mwy-sm-p10 - Sets top and bottom margins to p10 on sm
  .mwt-sm-p10 - Sets top margin to p10 on sm
  .mwr-sm-p10 - Sets right margin to p10 on sm
  .mwb-sm-p10 - Sets bottom margin to p10 on sm
  .mwl-sm-p10 - Sets left margin to p10 on sm

  Styleguide Margin - Width - p10
  */
  .mwt-sm-p10,
  .mwy-sm-p10,
  .mwa-sm-p10 {
    margin-top: var(--margin-width-p10);
  }

  .mwr-sm-p10,
  .mwx-sm-p10,
  .mwa-sm-p10 {
    margin-right: var(--margin-width-p10);
  }

  .mwb-sm-p10,
  .mwy-sm-p10,
  .mwa-sm-p10 {
    margin-bottom: var(--margin-width-p10);
  }

  .mwl-sm-p10,
  .mwx-sm-p10,
  .mwa-sm-p10 {
    margin-left: var(--margin-width-p10);
  }

  /*
  .mw*r-sm-p10

  Sets the margin of element to negative value of the css var for p10 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p10 - Sets all margins to mp10 on sm
  .mwxr-sm-p10 - Sets left and right margins to mp10 on sm
  .mwyr-sm-p10 - Sets top and bottom margins to mp10 on sm
  .mwtr-sm-p10 - Sets top margin to mp10 on sm
  .mwrr-sm-p10 - Sets right margin to mp10 on sm
  .mwbr-sm-p10 - Sets bottom margin to mp10 on sm
  .mwlr-sm-p10 - Sets left margin to mp10 on sm

  Styleguide Margin - Width - mp10
  */
  .mwtr-sm-p10,
  .mwyr-sm-p10,
  .mwar-sm-p10 {
    margin-top: calc(-1 * var(--margin-width-p10));
  }

  .mwrr-sm-p10,
  .mwxr-sm-p10,
  .mwar-sm-p10 {
    margin-right: calc(-1 * var(--margin-width-p10));
  }

  .mwbr-sm-p10,
  .mwyr-sm-p10,
  .mwar-sm-p10 {
    margin-bottom: calc(-1 * var(--margin-width-p10));
  }

  .mwlr-sm-p10,
  .mwxr-sm-p10,
  .mwar-sm-p10 {
    margin-left: calc(-1 * var(--margin-width-p10));
  }

  /*
  .mw*-sm-p20

  Sets the margin of element to css var for p20 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p20 - Sets all margins to p20 on sm
  .mwx-sm-p20 - Sets left and right margins to p20 on sm
  .mwy-sm-p20 - Sets top and bottom margins to p20 on sm
  .mwt-sm-p20 - Sets top margin to p20 on sm
  .mwr-sm-p20 - Sets right margin to p20 on sm
  .mwb-sm-p20 - Sets bottom margin to p20 on sm
  .mwl-sm-p20 - Sets left margin to p20 on sm

  Styleguide Margin - Width - p20
  */
  .mwt-sm-p20,
  .mwy-sm-p20,
  .mwa-sm-p20 {
    margin-top: var(--margin-width-p20);
  }

  .mwr-sm-p20,
  .mwx-sm-p20,
  .mwa-sm-p20 {
    margin-right: var(--margin-width-p20);
  }

  .mwb-sm-p20,
  .mwy-sm-p20,
  .mwa-sm-p20 {
    margin-bottom: var(--margin-width-p20);
  }

  .mwl-sm-p20,
  .mwx-sm-p20,
  .mwa-sm-p20 {
    margin-left: var(--margin-width-p20);
  }

  /*
  .mw*r-sm-p20

  Sets the margin of element to negative value of the css var for p20 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p20 - Sets all margins to mp20 on sm
  .mwxr-sm-p20 - Sets left and right margins to mp20 on sm
  .mwyr-sm-p20 - Sets top and bottom margins to mp20 on sm
  .mwtr-sm-p20 - Sets top margin to mp20 on sm
  .mwrr-sm-p20 - Sets right margin to mp20 on sm
  .mwbr-sm-p20 - Sets bottom margin to mp20 on sm
  .mwlr-sm-p20 - Sets left margin to mp20 on sm

  Styleguide Margin - Width - mp20
  */
  .mwtr-sm-p20,
  .mwyr-sm-p20,
  .mwar-sm-p20 {
    margin-top: calc(-1 * var(--margin-width-p20));
  }

  .mwrr-sm-p20,
  .mwxr-sm-p20,
  .mwar-sm-p20 {
    margin-right: calc(-1 * var(--margin-width-p20));
  }

  .mwbr-sm-p20,
  .mwyr-sm-p20,
  .mwar-sm-p20 {
    margin-bottom: calc(-1 * var(--margin-width-p20));
  }

  .mwlr-sm-p20,
  .mwxr-sm-p20,
  .mwar-sm-p20 {
    margin-left: calc(-1 * var(--margin-width-p20));
  }

  /*
  .mw*-sm-p25

  Sets the margin of element to css var for p25 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p25 - Sets all margins to p25 on sm
  .mwx-sm-p25 - Sets left and right margins to p25 on sm
  .mwy-sm-p25 - Sets top and bottom margins to p25 on sm
  .mwt-sm-p25 - Sets top margin to p25 on sm
  .mwr-sm-p25 - Sets right margin to p25 on sm
  .mwb-sm-p25 - Sets bottom margin to p25 on sm
  .mwl-sm-p25 - Sets left margin to p25 on sm

  Styleguide Margin - Width - p25
  */
  .mwt-sm-p25,
  .mwy-sm-p25,
  .mwa-sm-p25 {
    margin-top: var(--margin-width-p25);
  }

  .mwr-sm-p25,
  .mwx-sm-p25,
  .mwa-sm-p25 {
    margin-right: var(--margin-width-p25);
  }

  .mwb-sm-p25,
  .mwy-sm-p25,
  .mwa-sm-p25 {
    margin-bottom: var(--margin-width-p25);
  }

  .mwl-sm-p25,
  .mwx-sm-p25,
  .mwa-sm-p25 {
    margin-left: var(--margin-width-p25);
  }

  /*
  .mw*r-sm-p25

  Sets the margin of element to negative value of the css var for p25 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p25 - Sets all margins to mp25 on sm
  .mwxr-sm-p25 - Sets left and right margins to mp25 on sm
  .mwyr-sm-p25 - Sets top and bottom margins to mp25 on sm
  .mwtr-sm-p25 - Sets top margin to mp25 on sm
  .mwrr-sm-p25 - Sets right margin to mp25 on sm
  .mwbr-sm-p25 - Sets bottom margin to mp25 on sm
  .mwlr-sm-p25 - Sets left margin to mp25 on sm

  Styleguide Margin - Width - mp25
  */
  .mwtr-sm-p25,
  .mwyr-sm-p25,
  .mwar-sm-p25 {
    margin-top: calc(-1 * var(--margin-width-p25));
  }

  .mwrr-sm-p25,
  .mwxr-sm-p25,
  .mwar-sm-p25 {
    margin-right: calc(-1 * var(--margin-width-p25));
  }

  .mwbr-sm-p25,
  .mwyr-sm-p25,
  .mwar-sm-p25 {
    margin-bottom: calc(-1 * var(--margin-width-p25));
  }

  .mwlr-sm-p25,
  .mwxr-sm-p25,
  .mwar-sm-p25 {
    margin-left: calc(-1 * var(--margin-width-p25));
  }

  /*
  .mw*-sm-p30

  Sets the margin of element to css var for p30 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p30 - Sets all margins to p30 on sm
  .mwx-sm-p30 - Sets left and right margins to p30 on sm
  .mwy-sm-p30 - Sets top and bottom margins to p30 on sm
  .mwt-sm-p30 - Sets top margin to p30 on sm
  .mwr-sm-p30 - Sets right margin to p30 on sm
  .mwb-sm-p30 - Sets bottom margin to p30 on sm
  .mwl-sm-p30 - Sets left margin to p30 on sm

  Styleguide Margin - Width - p30
  */
  .mwt-sm-p30,
  .mwy-sm-p30,
  .mwa-sm-p30 {
    margin-top: var(--margin-width-p30);
  }

  .mwr-sm-p30,
  .mwx-sm-p30,
  .mwa-sm-p30 {
    margin-right: var(--margin-width-p30);
  }

  .mwb-sm-p30,
  .mwy-sm-p30,
  .mwa-sm-p30 {
    margin-bottom: var(--margin-width-p30);
  }

  .mwl-sm-p30,
  .mwx-sm-p30,
  .mwa-sm-p30 {
    margin-left: var(--margin-width-p30);
  }

  /*
  .mw*r-sm-p30

  Sets the margin of element to negative value of the css var for p30 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p30 - Sets all margins to mp30 on sm
  .mwxr-sm-p30 - Sets left and right margins to mp30 on sm
  .mwyr-sm-p30 - Sets top and bottom margins to mp30 on sm
  .mwtr-sm-p30 - Sets top margin to mp30 on sm
  .mwrr-sm-p30 - Sets right margin to mp30 on sm
  .mwbr-sm-p30 - Sets bottom margin to mp30 on sm
  .mwlr-sm-p30 - Sets left margin to mp30 on sm

  Styleguide Margin - Width - mp30
  */
  .mwtr-sm-p30,
  .mwyr-sm-p30,
  .mwar-sm-p30 {
    margin-top: calc(-1 * var(--margin-width-p30));
  }

  .mwrr-sm-p30,
  .mwxr-sm-p30,
  .mwar-sm-p30 {
    margin-right: calc(-1 * var(--margin-width-p30));
  }

  .mwbr-sm-p30,
  .mwyr-sm-p30,
  .mwar-sm-p30 {
    margin-bottom: calc(-1 * var(--margin-width-p30));
  }

  .mwlr-sm-p30,
  .mwxr-sm-p30,
  .mwar-sm-p30 {
    margin-left: calc(-1 * var(--margin-width-p30));
  }

  /*
  .mw*-sm-p40

  Sets the margin of element to css var for p40 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p40 - Sets all margins to p40 on sm
  .mwx-sm-p40 - Sets left and right margins to p40 on sm
  .mwy-sm-p40 - Sets top and bottom margins to p40 on sm
  .mwt-sm-p40 - Sets top margin to p40 on sm
  .mwr-sm-p40 - Sets right margin to p40 on sm
  .mwb-sm-p40 - Sets bottom margin to p40 on sm
  .mwl-sm-p40 - Sets left margin to p40 on sm

  Styleguide Margin - Width - p40
  */
  .mwt-sm-p40,
  .mwy-sm-p40,
  .mwa-sm-p40 {
    margin-top: var(--margin-width-p40);
  }

  .mwr-sm-p40,
  .mwx-sm-p40,
  .mwa-sm-p40 {
    margin-right: var(--margin-width-p40);
  }

  .mwb-sm-p40,
  .mwy-sm-p40,
  .mwa-sm-p40 {
    margin-bottom: var(--margin-width-p40);
  }

  .mwl-sm-p40,
  .mwx-sm-p40,
  .mwa-sm-p40 {
    margin-left: var(--margin-width-p40);
  }

  /*
  .mw*r-sm-p40

  Sets the margin of element to negative value of the css var for p40 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p40 - Sets all margins to mp40 on sm
  .mwxr-sm-p40 - Sets left and right margins to mp40 on sm
  .mwyr-sm-p40 - Sets top and bottom margins to mp40 on sm
  .mwtr-sm-p40 - Sets top margin to mp40 on sm
  .mwrr-sm-p40 - Sets right margin to mp40 on sm
  .mwbr-sm-p40 - Sets bottom margin to mp40 on sm
  .mwlr-sm-p40 - Sets left margin to mp40 on sm

  Styleguide Margin - Width - mp40
  */
  .mwtr-sm-p40,
  .mwyr-sm-p40,
  .mwar-sm-p40 {
    margin-top: calc(-1 * var(--margin-width-p40));
  }

  .mwrr-sm-p40,
  .mwxr-sm-p40,
  .mwar-sm-p40 {
    margin-right: calc(-1 * var(--margin-width-p40));
  }

  .mwbr-sm-p40,
  .mwyr-sm-p40,
  .mwar-sm-p40 {
    margin-bottom: calc(-1 * var(--margin-width-p40));
  }

  .mwlr-sm-p40,
  .mwxr-sm-p40,
  .mwar-sm-p40 {
    margin-left: calc(-1 * var(--margin-width-p40));
  }

  /*
  .mw*-sm-p50

  Sets the margin of element to css var for p50 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p50 - Sets all margins to p50 on sm
  .mwx-sm-p50 - Sets left and right margins to p50 on sm
  .mwy-sm-p50 - Sets top and bottom margins to p50 on sm
  .mwt-sm-p50 - Sets top margin to p50 on sm
  .mwr-sm-p50 - Sets right margin to p50 on sm
  .mwb-sm-p50 - Sets bottom margin to p50 on sm
  .mwl-sm-p50 - Sets left margin to p50 on sm

  Styleguide Margin - Width - p50
  */
  .mwt-sm-p50,
  .mwy-sm-p50,
  .mwa-sm-p50 {
    margin-top: var(--margin-width-p50);
  }

  .mwr-sm-p50,
  .mwx-sm-p50,
  .mwa-sm-p50 {
    margin-right: var(--margin-width-p50);
  }

  .mwb-sm-p50,
  .mwy-sm-p50,
  .mwa-sm-p50 {
    margin-bottom: var(--margin-width-p50);
  }

  .mwl-sm-p50,
  .mwx-sm-p50,
  .mwa-sm-p50 {
    margin-left: var(--margin-width-p50);
  }

  /*
  .mw*r-sm-p50

  Sets the margin of element to negative value of the css var for p50 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p50 - Sets all margins to mp50 on sm
  .mwxr-sm-p50 - Sets left and right margins to mp50 on sm
  .mwyr-sm-p50 - Sets top and bottom margins to mp50 on sm
  .mwtr-sm-p50 - Sets top margin to mp50 on sm
  .mwrr-sm-p50 - Sets right margin to mp50 on sm
  .mwbr-sm-p50 - Sets bottom margin to mp50 on sm
  .mwlr-sm-p50 - Sets left margin to mp50 on sm

  Styleguide Margin - Width - mp50
  */
  .mwtr-sm-p50,
  .mwyr-sm-p50,
  .mwar-sm-p50 {
    margin-top: calc(-1 * var(--margin-width-p50));
  }

  .mwrr-sm-p50,
  .mwxr-sm-p50,
  .mwar-sm-p50 {
    margin-right: calc(-1 * var(--margin-width-p50));
  }

  .mwbr-sm-p50,
  .mwyr-sm-p50,
  .mwar-sm-p50 {
    margin-bottom: calc(-1 * var(--margin-width-p50));
  }

  .mwlr-sm-p50,
  .mwxr-sm-p50,
  .mwar-sm-p50 {
    margin-left: calc(-1 * var(--margin-width-p50));
  }

  /*
  .mw*-sm-p60

  Sets the margin of element to css var for p60 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p60 - Sets all margins to p60 on sm
  .mwx-sm-p60 - Sets left and right margins to p60 on sm
  .mwy-sm-p60 - Sets top and bottom margins to p60 on sm
  .mwt-sm-p60 - Sets top margin to p60 on sm
  .mwr-sm-p60 - Sets right margin to p60 on sm
  .mwb-sm-p60 - Sets bottom margin to p60 on sm
  .mwl-sm-p60 - Sets left margin to p60 on sm

  Styleguide Margin - Width - p60
  */
  .mwt-sm-p60,
  .mwy-sm-p60,
  .mwa-sm-p60 {
    margin-top: var(--margin-width-p60);
  }

  .mwr-sm-p60,
  .mwx-sm-p60,
  .mwa-sm-p60 {
    margin-right: var(--margin-width-p60);
  }

  .mwb-sm-p60,
  .mwy-sm-p60,
  .mwa-sm-p60 {
    margin-bottom: var(--margin-width-p60);
  }

  .mwl-sm-p60,
  .mwx-sm-p60,
  .mwa-sm-p60 {
    margin-left: var(--margin-width-p60);
  }

  /*
  .mw*r-sm-p60

  Sets the margin of element to negative value of the css var for p60 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p60 - Sets all margins to mp60 on sm
  .mwxr-sm-p60 - Sets left and right margins to mp60 on sm
  .mwyr-sm-p60 - Sets top and bottom margins to mp60 on sm
  .mwtr-sm-p60 - Sets top margin to mp60 on sm
  .mwrr-sm-p60 - Sets right margin to mp60 on sm
  .mwbr-sm-p60 - Sets bottom margin to mp60 on sm
  .mwlr-sm-p60 - Sets left margin to mp60 on sm

  Styleguide Margin - Width - mp60
  */
  .mwtr-sm-p60,
  .mwyr-sm-p60,
  .mwar-sm-p60 {
    margin-top: calc(-1 * var(--margin-width-p60));
  }

  .mwrr-sm-p60,
  .mwxr-sm-p60,
  .mwar-sm-p60 {
    margin-right: calc(-1 * var(--margin-width-p60));
  }

  .mwbr-sm-p60,
  .mwyr-sm-p60,
  .mwar-sm-p60 {
    margin-bottom: calc(-1 * var(--margin-width-p60));
  }

  .mwlr-sm-p60,
  .mwxr-sm-p60,
  .mwar-sm-p60 {
    margin-left: calc(-1 * var(--margin-width-p60));
  }

  /*
  .mw*-sm-p70

  Sets the margin of element to css var for p70 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p70 - Sets all margins to p70 on sm
  .mwx-sm-p70 - Sets left and right margins to p70 on sm
  .mwy-sm-p70 - Sets top and bottom margins to p70 on sm
  .mwt-sm-p70 - Sets top margin to p70 on sm
  .mwr-sm-p70 - Sets right margin to p70 on sm
  .mwb-sm-p70 - Sets bottom margin to p70 on sm
  .mwl-sm-p70 - Sets left margin to p70 on sm

  Styleguide Margin - Width - p70
  */
  .mwt-sm-p70,
  .mwy-sm-p70,
  .mwa-sm-p70 {
    margin-top: var(--margin-width-p70);
  }

  .mwr-sm-p70,
  .mwx-sm-p70,
  .mwa-sm-p70 {
    margin-right: var(--margin-width-p70);
  }

  .mwb-sm-p70,
  .mwy-sm-p70,
  .mwa-sm-p70 {
    margin-bottom: var(--margin-width-p70);
  }

  .mwl-sm-p70,
  .mwx-sm-p70,
  .mwa-sm-p70 {
    margin-left: var(--margin-width-p70);
  }

  /*
  .mw*r-sm-p70

  Sets the margin of element to negative value of the css var for p70 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p70 - Sets all margins to mp70 on sm
  .mwxr-sm-p70 - Sets left and right margins to mp70 on sm
  .mwyr-sm-p70 - Sets top and bottom margins to mp70 on sm
  .mwtr-sm-p70 - Sets top margin to mp70 on sm
  .mwrr-sm-p70 - Sets right margin to mp70 on sm
  .mwbr-sm-p70 - Sets bottom margin to mp70 on sm
  .mwlr-sm-p70 - Sets left margin to mp70 on sm

  Styleguide Margin - Width - mp70
  */
  .mwtr-sm-p70,
  .mwyr-sm-p70,
  .mwar-sm-p70 {
    margin-top: calc(-1 * var(--margin-width-p70));
  }

  .mwrr-sm-p70,
  .mwxr-sm-p70,
  .mwar-sm-p70 {
    margin-right: calc(-1 * var(--margin-width-p70));
  }

  .mwbr-sm-p70,
  .mwyr-sm-p70,
  .mwar-sm-p70 {
    margin-bottom: calc(-1 * var(--margin-width-p70));
  }

  .mwlr-sm-p70,
  .mwxr-sm-p70,
  .mwar-sm-p70 {
    margin-left: calc(-1 * var(--margin-width-p70));
  }

  /*
  .mw*-sm-p75

  Sets the margin of element to css var for p75 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p75 - Sets all margins to p75 on sm
  .mwx-sm-p75 - Sets left and right margins to p75 on sm
  .mwy-sm-p75 - Sets top and bottom margins to p75 on sm
  .mwt-sm-p75 - Sets top margin to p75 on sm
  .mwr-sm-p75 - Sets right margin to p75 on sm
  .mwb-sm-p75 - Sets bottom margin to p75 on sm
  .mwl-sm-p75 - Sets left margin to p75 on sm

  Styleguide Margin - Width - p75
  */
  .mwt-sm-p75,
  .mwy-sm-p75,
  .mwa-sm-p75 {
    margin-top: var(--margin-width-p75);
  }

  .mwr-sm-p75,
  .mwx-sm-p75,
  .mwa-sm-p75 {
    margin-right: var(--margin-width-p75);
  }

  .mwb-sm-p75,
  .mwy-sm-p75,
  .mwa-sm-p75 {
    margin-bottom: var(--margin-width-p75);
  }

  .mwl-sm-p75,
  .mwx-sm-p75,
  .mwa-sm-p75 {
    margin-left: var(--margin-width-p75);
  }

  /*
  .mw*r-sm-p75

  Sets the margin of element to negative value of the css var for p75 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p75 - Sets all margins to mp75 on sm
  .mwxr-sm-p75 - Sets left and right margins to mp75 on sm
  .mwyr-sm-p75 - Sets top and bottom margins to mp75 on sm
  .mwtr-sm-p75 - Sets top margin to mp75 on sm
  .mwrr-sm-p75 - Sets right margin to mp75 on sm
  .mwbr-sm-p75 - Sets bottom margin to mp75 on sm
  .mwlr-sm-p75 - Sets left margin to mp75 on sm

  Styleguide Margin - Width - mp75
  */
  .mwtr-sm-p75,
  .mwyr-sm-p75,
  .mwar-sm-p75 {
    margin-top: calc(-1 * var(--margin-width-p75));
  }

  .mwrr-sm-p75,
  .mwxr-sm-p75,
  .mwar-sm-p75 {
    margin-right: calc(-1 * var(--margin-width-p75));
  }

  .mwbr-sm-p75,
  .mwyr-sm-p75,
  .mwar-sm-p75 {
    margin-bottom: calc(-1 * var(--margin-width-p75));
  }

  .mwlr-sm-p75,
  .mwxr-sm-p75,
  .mwar-sm-p75 {
    margin-left: calc(-1 * var(--margin-width-p75));
  }

  /*
  .mw*-sm-p80

  Sets the margin of element to css var for p80 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p80 - Sets all margins to p80 on sm
  .mwx-sm-p80 - Sets left and right margins to p80 on sm
  .mwy-sm-p80 - Sets top and bottom margins to p80 on sm
  .mwt-sm-p80 - Sets top margin to p80 on sm
  .mwr-sm-p80 - Sets right margin to p80 on sm
  .mwb-sm-p80 - Sets bottom margin to p80 on sm
  .mwl-sm-p80 - Sets left margin to p80 on sm

  Styleguide Margin - Width - p80
  */
  .mwt-sm-p80,
  .mwy-sm-p80,
  .mwa-sm-p80 {
    margin-top: var(--margin-width-p80);
  }

  .mwr-sm-p80,
  .mwx-sm-p80,
  .mwa-sm-p80 {
    margin-right: var(--margin-width-p80);
  }

  .mwb-sm-p80,
  .mwy-sm-p80,
  .mwa-sm-p80 {
    margin-bottom: var(--margin-width-p80);
  }

  .mwl-sm-p80,
  .mwx-sm-p80,
  .mwa-sm-p80 {
    margin-left: var(--margin-width-p80);
  }

  /*
  .mw*r-sm-p80

  Sets the margin of element to negative value of the css var for p80 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p80 - Sets all margins to mp80 on sm
  .mwxr-sm-p80 - Sets left and right margins to mp80 on sm
  .mwyr-sm-p80 - Sets top and bottom margins to mp80 on sm
  .mwtr-sm-p80 - Sets top margin to mp80 on sm
  .mwrr-sm-p80 - Sets right margin to mp80 on sm
  .mwbr-sm-p80 - Sets bottom margin to mp80 on sm
  .mwlr-sm-p80 - Sets left margin to mp80 on sm

  Styleguide Margin - Width - mp80
  */
  .mwtr-sm-p80,
  .mwyr-sm-p80,
  .mwar-sm-p80 {
    margin-top: calc(-1 * var(--margin-width-p80));
  }

  .mwrr-sm-p80,
  .mwxr-sm-p80,
  .mwar-sm-p80 {
    margin-right: calc(-1 * var(--margin-width-p80));
  }

  .mwbr-sm-p80,
  .mwyr-sm-p80,
  .mwar-sm-p80 {
    margin-bottom: calc(-1 * var(--margin-width-p80));
  }

  .mwlr-sm-p80,
  .mwxr-sm-p80,
  .mwar-sm-p80 {
    margin-left: calc(-1 * var(--margin-width-p80));
  }

  /*
  .mw*-sm-p90

  Sets the margin of element to css var for p90 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p90 - Sets all margins to p90 on sm
  .mwx-sm-p90 - Sets left and right margins to p90 on sm
  .mwy-sm-p90 - Sets top and bottom margins to p90 on sm
  .mwt-sm-p90 - Sets top margin to p90 on sm
  .mwr-sm-p90 - Sets right margin to p90 on sm
  .mwb-sm-p90 - Sets bottom margin to p90 on sm
  .mwl-sm-p90 - Sets left margin to p90 on sm

  Styleguide Margin - Width - p90
  */
  .mwt-sm-p90,
  .mwy-sm-p90,
  .mwa-sm-p90 {
    margin-top: var(--margin-width-p90);
  }

  .mwr-sm-p90,
  .mwx-sm-p90,
  .mwa-sm-p90 {
    margin-right: var(--margin-width-p90);
  }

  .mwb-sm-p90,
  .mwy-sm-p90,
  .mwa-sm-p90 {
    margin-bottom: var(--margin-width-p90);
  }

  .mwl-sm-p90,
  .mwx-sm-p90,
  .mwa-sm-p90 {
    margin-left: var(--margin-width-p90);
  }

  /*
  .mw*r-sm-p90

  Sets the margin of element to negative value of the css var for p90 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p90 - Sets all margins to mp90 on sm
  .mwxr-sm-p90 - Sets left and right margins to mp90 on sm
  .mwyr-sm-p90 - Sets top and bottom margins to mp90 on sm
  .mwtr-sm-p90 - Sets top margin to mp90 on sm
  .mwrr-sm-p90 - Sets right margin to mp90 on sm
  .mwbr-sm-p90 - Sets bottom margin to mp90 on sm
  .mwlr-sm-p90 - Sets left margin to mp90 on sm

  Styleguide Margin - Width - mp90
  */
  .mwtr-sm-p90,
  .mwyr-sm-p90,
  .mwar-sm-p90 {
    margin-top: calc(-1 * var(--margin-width-p90));
  }

  .mwrr-sm-p90,
  .mwxr-sm-p90,
  .mwar-sm-p90 {
    margin-right: calc(-1 * var(--margin-width-p90));
  }

  .mwbr-sm-p90,
  .mwyr-sm-p90,
  .mwar-sm-p90 {
    margin-bottom: calc(-1 * var(--margin-width-p90));
  }

  .mwlr-sm-p90,
  .mwxr-sm-p90,
  .mwar-sm-p90 {
    margin-left: calc(-1 * var(--margin-width-p90));
  }

  /*
  .mw*-sm-p100

  Sets the margin of element to css var for p100 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwa-sm-p100 - Sets all margins to p100 on sm
  .mwx-sm-p100 - Sets left and right margins to p100 on sm
  .mwy-sm-p100 - Sets top and bottom margins to p100 on sm
  .mwt-sm-p100 - Sets top margin to p100 on sm
  .mwr-sm-p100 - Sets right margin to p100 on sm
  .mwb-sm-p100 - Sets bottom margin to p100 on sm
  .mwl-sm-p100 - Sets left margin to p100 on sm

  Styleguide Margin - Width - p100
  */
  .mwt-sm-p100,
  .mwy-sm-p100,
  .mwa-sm-p100 {
    margin-top: var(--margin-width-p100);
  }

  .mwr-sm-p100,
  .mwx-sm-p100,
  .mwa-sm-p100 {
    margin-right: var(--margin-width-p100);
  }

  .mwb-sm-p100,
  .mwy-sm-p100,
  .mwa-sm-p100 {
    margin-bottom: var(--margin-width-p100);
  }

  .mwl-sm-p100,
  .mwx-sm-p100,
  .mwa-sm-p100 {
    margin-left: var(--margin-width-p100);
  }

  /*
  .mw*r-sm-p100

  Sets the margin of element to negative value of the css var for p100 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .mwar-sm-p100 - Sets all margins to mp100 on sm
  .mwxr-sm-p100 - Sets left and right margins to mp100 on sm
  .mwyr-sm-p100 - Sets top and bottom margins to mp100 on sm
  .mwtr-sm-p100 - Sets top margin to mp100 on sm
  .mwrr-sm-p100 - Sets right margin to mp100 on sm
  .mwbr-sm-p100 - Sets bottom margin to mp100 on sm
  .mwlr-sm-p100 - Sets left margin to mp100 on sm

  Styleguide Margin - Width - mp100
  */
  .mwtr-sm-p100,
  .mwyr-sm-p100,
  .mwar-sm-p100 {
    margin-top: calc(-1 * var(--margin-width-p100));
  }

  .mwrr-sm-p100,
  .mwxr-sm-p100,
  .mwar-sm-p100 {
    margin-right: calc(-1 * var(--margin-width-p100));
  }

  .mwbr-sm-p100,
  .mwyr-sm-p100,
  .mwar-sm-p100 {
    margin-bottom: calc(-1 * var(--margin-width-p100));
  }

  .mwlr-sm-p100,
  .mwxr-sm-p100,
  .mwar-sm-p100 {
    margin-left: calc(-1 * var(--margin-width-p100));
  }
}
/*
Overflow

Classes for setting the overflow of an element.

Styleguide Overflow
*/
/*
.ov*-vi

Sets the overflow of an element to visible.

Markup:
<div class="{{modifier_class}}">A div</div>

.ova-vi - Sets overflow to visible
.ovx-vi - Sets x-overflow to visible
.ovy-vi - Sets y-overflow to visible

Styleguide Overflow - vi
*/
.ova-vi {
  overflow: visible;
}

.ovx-vi {
  overflow-x: visible;
}

.ovy-vi {
  overflow-y: visible;
}

/*
.ov*-hi

Sets the overflow of an element to hidden.

Markup:
<div class="{{modifier_class}}">A div</div>

.ova-hi - Sets overflow to hidden
.ovx-hi - Sets x-overflow to hidden
.ovy-hi - Sets y-overflow to hidden

Styleguide Overflow - hi
*/
.ova-hi {
  overflow: hidden;
}

.ovx-hi {
  overflow-x: hidden;
}

.ovy-hi {
  overflow-y: hidden;
}

/*
.ov*-sc

Sets the overflow of an element to scroll.

Markup:
<div class="{{modifier_class}}">A div</div>

.ova-sc - Sets overflow to scroll
.ovx-sc - Sets x-overflow to scroll
.ovy-sc - Sets y-overflow to scroll

Styleguide Overflow - sc
*/
.ova-sc {
  overflow: scroll;
}

.ovx-sc {
  overflow-x: scroll;
}

.ovy-sc {
  overflow-y: scroll;
}

/*
.ov*-au

Sets the overflow of an element to auto.

Markup:
<div class="{{modifier_class}}">A div</div>

.ova-au - Sets overflow to auto
.ovx-au - Sets x-overflow to auto
.ovy-au - Sets y-overflow to auto

Styleguide Overflow - au
*/
.ova-au {
  overflow: auto;
}

.ovx-au {
  overflow-x: auto;
}

.ovy-au {
  overflow-y: auto;
}

/*
.ov*-un

Sets the overflow of an element to unset.

Markup:
<div class="{{modifier_class}}">A div</div>

.ova-un - Sets overflow to unset
.ovx-un - Sets x-overflow to unset
.ovy-un - Sets y-overflow to unset

Styleguide Overflow - un
*/
.ova-un {
  overflow: unset;
}

.ovx-un {
  overflow-x: unset;
}

.ovy-un {
  overflow-y: unset;
}

/*
Object

Classes for setting the object properties of an element.

Styleguide Object
*/
/*
Object Fit

Classes for setting the object-fit property of an element.

Styleguide Object - Fit
*/
/*
.of-fil

Sets the object-fit property of an element to fill.

Markup:
<div class="{{modifier_class}}">A div</div>

.of-fil - Sets object-fit to fill

Styleguide Object - Fit - fil
*/
.of-fil {
  object-fit: fill;
}

/*
.of-con

Sets the object-fit property of an element to contain.

Markup:
<div class="{{modifier_class}}">A div</div>

.of-con - Sets object-fit to contain

Styleguide Object - Fit - con
*/
.of-con {
  object-fit: contain;
}

/*
.of-cov

Sets the object-fit property of an element to cover.

Markup:
<div class="{{modifier_class}}">A div</div>

.of-cov - Sets object-fit to cover

Styleguide Object - Fit - cov
*/
.of-cov {
  object-fit: cover;
}

/*
.of-non

Sets the object-fit property of an element to none.

Markup:
<div class="{{modifier_class}}">A div</div>

.of-non - Sets object-fit to none

Styleguide Object - Fit - non
*/
.of-non {
  object-fit: none;
}

/*
.of-scd

Sets the object-fit property of an element to scale-down.

Markup:
<div class="{{modifier_class}}">A div</div>

.of-scd - Sets object-fit to scale-down

Styleguide Object - Fit - scd
*/
.of-scd {
  object-fit: scale-down;
}

/*
Object Position

Classes for setting the object-position property of an element.

Styleguide Object - Position
*/
/*
.op-lt

Sets the object-position property of an element to left top.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-lt - Sets object-position to left top

Styleguide Object - Position - lt
*/
.op-lt {
  object-position: left top;
}

/*
.op-lc

Sets the object-position property of an element to left center.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-lc - Sets object-position to left center

Styleguide Object - Position - lc
*/
.op-lc {
  object-position: left center;
}

/*
.op-lb

Sets the object-position property of an element to left bottom.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-lb - Sets object-position to left bottom

Styleguide Object - Position - lb
*/
.op-lb {
  object-position: left bottom;
}

/*
.op-ct

Sets the object-position property of an element to center top.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-ct - Sets object-position to center top

Styleguide Object - Position - ct
*/
.op-ct {
  object-position: center top;
}

/*
.op-cc

Sets the object-position property of an element to center center.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-cc - Sets object-position to center center

Styleguide Object - Position - cc
*/
.op-cc {
  object-position: center center;
}

/*
.op-cb

Sets the object-position property of an element to center bottom.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-cb - Sets object-position to center bottom

Styleguide Object - Position - cb
*/
.op-cb {
  object-position: center bottom;
}

/*
.op-rt

Sets the object-position property of an element to right top.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-rt - Sets object-position to right top

Styleguide Object - Position - rt
*/
.op-rt {
  object-position: right top;
}

/*
.op-rc

Sets the object-position property of an element to right center.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-rc - Sets object-position to right center

Styleguide Object - Position - rc
*/
.op-rc {
  object-position: right center;
}

/*
.op-rb

Sets the object-position property of an element to right bottom.

Markup:
<div class="{{modifier_class}}">A div</div>

.op-rb - Sets object-position to right bottom

Styleguide Object - Position - rb
*/
.op-rb {
  object-position: right bottom;
}

:root {
  --padding-width-0: calc(var(--fill-size) * 0);
  --padding-width-1: calc(var(--fill-size) * 1);
  --padding-width-2: calc(var(--fill-size) * 2);
  --padding-width-3: calc(var(--fill-size) * 4);
  --padding-width-4: calc(var(--fill-size) * 8);
  --padding-width-5: calc(var(--fill-size) * 16);
  --padding-width-6: calc(var(--fill-size) * 32);
  --padding-width-7: calc(var(--fill-size) * 64);
}

/*
Padding

Classes for setting the padding of an element.

Styleguide Padding
*/
/*
Padding Width

Classes for setting the padding width of an element.

Styleguide Padding - Width
*/
/*
.pw*-0

Sets the padding of element to css var for 0.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-0 - Sets all paddings to 0
.pwx-0 - Sets left and right paddings to 0
.pwy-0 - Sets top and bottom paddings to 0
.pwt-0 - Sets top padding to 0
.pwr-0 - Sets right padding to 0
.pwb-0 - Sets bottom padding to 0
.pwl-0 - Sets left padding to 0

Styleguide Padding - Width - 0
*/
.pwt-0,
.pwy-0,
.pwa-0 {
  padding-top: var(--padding-width-0);
}

.pwr-0,
.pwx-0,
.pwa-0 {
  padding-right: var(--padding-width-0);
}

.pwb-0,
.pwy-0,
.pwa-0 {
  padding-bottom: var(--padding-width-0);
}

.pwl-0,
.pwx-0,
.pwa-0 {
  padding-left: var(--padding-width-0);
}

/*
.pw*-1

Sets the padding of element to css var for 1.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-1 - Sets all paddings to 1
.pwx-1 - Sets left and right paddings to 1
.pwy-1 - Sets top and bottom paddings to 1
.pwt-1 - Sets top padding to 1
.pwr-1 - Sets right padding to 1
.pwb-1 - Sets bottom padding to 1
.pwl-1 - Sets left padding to 1

Styleguide Padding - Width - 1
*/
.pwt-1,
.pwy-1,
.pwa-1 {
  padding-top: var(--padding-width-1);
}

.pwr-1,
.pwx-1,
.pwa-1 {
  padding-right: var(--padding-width-1);
}

.pwb-1,
.pwy-1,
.pwa-1 {
  padding-bottom: var(--padding-width-1);
}

.pwl-1,
.pwx-1,
.pwa-1 {
  padding-left: var(--padding-width-1);
}

/*
.pw*-2

Sets the padding of element to css var for 2.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-2 - Sets all paddings to 2
.pwx-2 - Sets left and right paddings to 2
.pwy-2 - Sets top and bottom paddings to 2
.pwt-2 - Sets top padding to 2
.pwr-2 - Sets right padding to 2
.pwb-2 - Sets bottom padding to 2
.pwl-2 - Sets left padding to 2

Styleguide Padding - Width - 2
*/
.pwt-2,
.pwy-2,
.pwa-2 {
  padding-top: var(--padding-width-2);
}

.pwr-2,
.pwx-2,
.pwa-2 {
  padding-right: var(--padding-width-2);
}

.pwb-2,
.pwy-2,
.pwa-2 {
  padding-bottom: var(--padding-width-2);
}

.pwl-2,
.pwx-2,
.pwa-2 {
  padding-left: var(--padding-width-2);
}

/*
.pw*-3

Sets the padding of element to css var for 3.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-3 - Sets all paddings to 3
.pwx-3 - Sets left and right paddings to 3
.pwy-3 - Sets top and bottom paddings to 3
.pwt-3 - Sets top padding to 3
.pwr-3 - Sets right padding to 3
.pwb-3 - Sets bottom padding to 3
.pwl-3 - Sets left padding to 3

Styleguide Padding - Width - 3
*/
.pwt-3,
.pwy-3,
.pwa-3 {
  padding-top: var(--padding-width-3);
}

.pwr-3,
.pwx-3,
.pwa-3 {
  padding-right: var(--padding-width-3);
}

.pwb-3,
.pwy-3,
.pwa-3 {
  padding-bottom: var(--padding-width-3);
}

.pwl-3,
.pwx-3,
.pwa-3 {
  padding-left: var(--padding-width-3);
}

/*
.pw*-4

Sets the padding of element to css var for 4.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-4 - Sets all paddings to 4
.pwx-4 - Sets left and right paddings to 4
.pwy-4 - Sets top and bottom paddings to 4
.pwt-4 - Sets top padding to 4
.pwr-4 - Sets right padding to 4
.pwb-4 - Sets bottom padding to 4
.pwl-4 - Sets left padding to 4

Styleguide Padding - Width - 4
*/
.pwt-4,
.pwy-4,
.pwa-4 {
  padding-top: var(--padding-width-4);
}

.pwr-4,
.pwx-4,
.pwa-4 {
  padding-right: var(--padding-width-4);
}

.pwb-4,
.pwy-4,
.pwa-4 {
  padding-bottom: var(--padding-width-4);
}

.pwl-4,
.pwx-4,
.pwa-4 {
  padding-left: var(--padding-width-4);
}

/*
.pw*-5

Sets the padding of element to css var for 5.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-5 - Sets all paddings to 5
.pwx-5 - Sets left and right paddings to 5
.pwy-5 - Sets top and bottom paddings to 5
.pwt-5 - Sets top padding to 5
.pwr-5 - Sets right padding to 5
.pwb-5 - Sets bottom padding to 5
.pwl-5 - Sets left padding to 5

Styleguide Padding - Width - 5
*/
.pwt-5,
.pwy-5,
.pwa-5 {
  padding-top: var(--padding-width-5);
}

.pwr-5,
.pwx-5,
.pwa-5 {
  padding-right: var(--padding-width-5);
}

.pwb-5,
.pwy-5,
.pwa-5 {
  padding-bottom: var(--padding-width-5);
}

.pwl-5,
.pwx-5,
.pwa-5 {
  padding-left: var(--padding-width-5);
}

/*
.pw*-6

Sets the padding of element to css var for 6.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-6 - Sets all paddings to 6
.pwx-6 - Sets left and right paddings to 6
.pwy-6 - Sets top and bottom paddings to 6
.pwt-6 - Sets top padding to 6
.pwr-6 - Sets right padding to 6
.pwb-6 - Sets bottom padding to 6
.pwl-6 - Sets left padding to 6

Styleguide Padding - Width - 6
*/
.pwt-6,
.pwy-6,
.pwa-6 {
  padding-top: var(--padding-width-6);
}

.pwr-6,
.pwx-6,
.pwa-6 {
  padding-right: var(--padding-width-6);
}

.pwb-6,
.pwy-6,
.pwa-6 {
  padding-bottom: var(--padding-width-6);
}

.pwl-6,
.pwx-6,
.pwa-6 {
  padding-left: var(--padding-width-6);
}

/*
.pw*-7

Sets the padding of element to css var for 7.

Markup:
<div class="{{modifier_class}}">A div</div>

.pwa-7 - Sets all paddings to 7
.pwx-7 - Sets left and right paddings to 7
.pwy-7 - Sets top and bottom paddings to 7
.pwt-7 - Sets top padding to 7
.pwr-7 - Sets right padding to 7
.pwb-7 - Sets bottom padding to 7
.pwl-7 - Sets left padding to 7

Styleguide Padding - Width - 7
*/
.pwt-7,
.pwy-7,
.pwa-7 {
  padding-top: var(--padding-width-7);
}

.pwr-7,
.pwx-7,
.pwa-7 {
  padding-right: var(--padding-width-7);
}

.pwb-7,
.pwy-7,
.pwa-7 {
  padding-bottom: var(--padding-width-7);
}

.pwl-7,
.pwx-7,
.pwa-7 {
  padding-left: var(--padding-width-7);
}

@media (min-width: 340px) {
  /*
  .pw*-sm-0

  Sets the padding of element to css var for 0 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-0 - Sets all paddings to 0 on sm
  .pwx-sm-0 - Sets left and right paddings to 0 on sm
  .pwy-sm-0 - Sets top and bottom paddings to 0 on sm
  .pwt-sm-0 - Sets top padding to 0 on sm
  .pwr-sm-0 - Sets right padding to 0 on sm
  .pwb-sm-0 - Sets bottom padding to 0 on sm
  .pwl-sm-0 - Sets left padding to 0 on sm

  Styleguide Padding - Width - 0 - sm
  */
  .pwt-sm-0,
  .pwy-sm-0,
  .pwa-sm-0 {
    padding-top: var(--padding-width-0);
  }

  .pwr-sm-0,
  .pwx-sm-0,
  .pwa-sm-0 {
    padding-right: var(--padding-width-0);
  }

  .pwb-sm-0,
  .pwy-sm-0,
  .pwa-sm-0 {
    padding-bottom: var(--padding-width-0);
  }

  .pwl-sm-0,
  .pwx-sm-0,
  .pwa-sm-0 {
    padding-left: var(--padding-width-0);
  }

  /*
  .pw*-sm-1

  Sets the padding of element to css var for 1 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-1 - Sets all paddings to 1 on sm
  .pwx-sm-1 - Sets left and right paddings to 1 on sm
  .pwy-sm-1 - Sets top and bottom paddings to 1 on sm
  .pwt-sm-1 - Sets top padding to 1 on sm
  .pwr-sm-1 - Sets right padding to 1 on sm
  .pwb-sm-1 - Sets bottom padding to 1 on sm
  .pwl-sm-1 - Sets left padding to 1 on sm

  Styleguide Padding - Width - 1 - sm
  */
  .pwt-sm-1,
  .pwy-sm-1,
  .pwa-sm-1 {
    padding-top: var(--padding-width-1);
  }

  .pwr-sm-1,
  .pwx-sm-1,
  .pwa-sm-1 {
    padding-right: var(--padding-width-1);
  }

  .pwb-sm-1,
  .pwy-sm-1,
  .pwa-sm-1 {
    padding-bottom: var(--padding-width-1);
  }

  .pwl-sm-1,
  .pwx-sm-1,
  .pwa-sm-1 {
    padding-left: var(--padding-width-1);
  }

  /*
  .pw*-sm-2

  Sets the padding of element to css var for 2 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-2 - Sets all paddings to 2 on sm
  .pwx-sm-2 - Sets left and right paddings to 2 on sm
  .pwy-sm-2 - Sets top and bottom paddings to 2 on sm
  .pwt-sm-2 - Sets top padding to 2 on sm
  .pwr-sm-2 - Sets right padding to 2 on sm
  .pwb-sm-2 - Sets bottom padding to 2 on sm
  .pwl-sm-2 - Sets left padding to 2 on sm

  Styleguide Padding - Width - 2 - sm
  */
  .pwt-sm-2,
  .pwy-sm-2,
  .pwa-sm-2 {
    padding-top: var(--padding-width-2);
  }

  .pwr-sm-2,
  .pwx-sm-2,
  .pwa-sm-2 {
    padding-right: var(--padding-width-2);
  }

  .pwb-sm-2,
  .pwy-sm-2,
  .pwa-sm-2 {
    padding-bottom: var(--padding-width-2);
  }

  .pwl-sm-2,
  .pwx-sm-2,
  .pwa-sm-2 {
    padding-left: var(--padding-width-2);
  }

  /*
  .pw*-sm-3

  Sets the padding of element to css var for 3 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-3 - Sets all paddings to 3 on sm
  .pwx-sm-3 - Sets left and right paddings to 3 on sm
  .pwy-sm-3 - Sets top and bottom paddings to 3 on sm
  .pwt-sm-3 - Sets top padding to 3 on sm
  .pwr-sm-3 - Sets right padding to 3 on sm
  .pwb-sm-3 - Sets bottom padding to 3 on sm
  .pwl-sm-3 - Sets left padding to 3 on sm

  Styleguide Padding - Width - 3 - sm
  */
  .pwt-sm-3,
  .pwy-sm-3,
  .pwa-sm-3 {
    padding-top: var(--padding-width-3);
  }

  .pwr-sm-3,
  .pwx-sm-3,
  .pwa-sm-3 {
    padding-right: var(--padding-width-3);
  }

  .pwb-sm-3,
  .pwy-sm-3,
  .pwa-sm-3 {
    padding-bottom: var(--padding-width-3);
  }

  .pwl-sm-3,
  .pwx-sm-3,
  .pwa-sm-3 {
    padding-left: var(--padding-width-3);
  }

  /*
  .pw*-sm-4

  Sets the padding of element to css var for 4 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-4 - Sets all paddings to 4 on sm
  .pwx-sm-4 - Sets left and right paddings to 4 on sm
  .pwy-sm-4 - Sets top and bottom paddings to 4 on sm
  .pwt-sm-4 - Sets top padding to 4 on sm
  .pwr-sm-4 - Sets right padding to 4 on sm
  .pwb-sm-4 - Sets bottom padding to 4 on sm
  .pwl-sm-4 - Sets left padding to 4 on sm

  Styleguide Padding - Width - 4 - sm
  */
  .pwt-sm-4,
  .pwy-sm-4,
  .pwa-sm-4 {
    padding-top: var(--padding-width-4);
  }

  .pwr-sm-4,
  .pwx-sm-4,
  .pwa-sm-4 {
    padding-right: var(--padding-width-4);
  }

  .pwb-sm-4,
  .pwy-sm-4,
  .pwa-sm-4 {
    padding-bottom: var(--padding-width-4);
  }

  .pwl-sm-4,
  .pwx-sm-4,
  .pwa-sm-4 {
    padding-left: var(--padding-width-4);
  }

  /*
  .pw*-sm-5

  Sets the padding of element to css var for 5 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-5 - Sets all paddings to 5 on sm
  .pwx-sm-5 - Sets left and right paddings to 5 on sm
  .pwy-sm-5 - Sets top and bottom paddings to 5 on sm
  .pwt-sm-5 - Sets top padding to 5 on sm
  .pwr-sm-5 - Sets right padding to 5 on sm
  .pwb-sm-5 - Sets bottom padding to 5 on sm
  .pwl-sm-5 - Sets left padding to 5 on sm

  Styleguide Padding - Width - 5 - sm
  */
  .pwt-sm-5,
  .pwy-sm-5,
  .pwa-sm-5 {
    padding-top: var(--padding-width-5);
  }

  .pwr-sm-5,
  .pwx-sm-5,
  .pwa-sm-5 {
    padding-right: var(--padding-width-5);
  }

  .pwb-sm-5,
  .pwy-sm-5,
  .pwa-sm-5 {
    padding-bottom: var(--padding-width-5);
  }

  .pwl-sm-5,
  .pwx-sm-5,
  .pwa-sm-5 {
    padding-left: var(--padding-width-5);
  }

  /*
  .pw*-sm-6

  Sets the padding of element to css var for 6 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-6 - Sets all paddings to 6 on sm
  .pwx-sm-6 - Sets left and right paddings to 6 on sm
  .pwy-sm-6 - Sets top and bottom paddings to 6 on sm
  .pwt-sm-6 - Sets top padding to 6 on sm
  .pwr-sm-6 - Sets right padding to 6 on sm
  .pwb-sm-6 - Sets bottom padding to 6 on sm
  .pwl-sm-6 - Sets left padding to 6 on sm

  Styleguide Padding - Width - 6 - sm
  */
  .pwt-sm-6,
  .pwy-sm-6,
  .pwa-sm-6 {
    padding-top: var(--padding-width-6);
  }

  .pwr-sm-6,
  .pwx-sm-6,
  .pwa-sm-6 {
    padding-right: var(--padding-width-6);
  }

  .pwb-sm-6,
  .pwy-sm-6,
  .pwa-sm-6 {
    padding-bottom: var(--padding-width-6);
  }

  .pwl-sm-6,
  .pwx-sm-6,
  .pwa-sm-6 {
    padding-left: var(--padding-width-6);
  }

  /*
  .pw*-sm-7

  Sets the padding of element to css var for 7 on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .pwa-sm-7 - Sets all paddings to 7 on sm
  .pwx-sm-7 - Sets left and right paddings to 7 on sm
  .pwy-sm-7 - Sets top and bottom paddings to 7 on sm
  .pwt-sm-7 - Sets top padding to 7 on sm
  .pwr-sm-7 - Sets right padding to 7 on sm
  .pwb-sm-7 - Sets bottom padding to 7 on sm
  .pwl-sm-7 - Sets left padding to 7 on sm

  Styleguide Padding - Width - 7 - sm
  */
  .pwt-sm-7,
  .pwy-sm-7,
  .pwa-sm-7 {
    padding-top: var(--padding-width-7);
  }

  .pwr-sm-7,
  .pwx-sm-7,
  .pwa-sm-7 {
    padding-right: var(--padding-width-7);
  }

  .pwb-sm-7,
  .pwy-sm-7,
  .pwa-sm-7 {
    padding-bottom: var(--padding-width-7);
  }

  .pwl-sm-7,
  .pwx-sm-7,
  .pwa-sm-7 {
    padding-left: var(--padding-width-7);
  }
}
:root {
  --shadow-0: calc(var(--fill-size) * 0);
  --shadow-1: calc(var(--fill-size) * 1);
  --shadow-2: calc(var(--fill-size) * 2);
  --shadow-3: calc(var(--fill-size) * 4);
  --shadow-4: calc(var(--fill-size) * 8);
  --shadow-5: calc(var(--fill-size) * 16);
  --shadow-6: calc(var(--fill-size) * 32);
  --shadow-7: calc(var(--fill-size) * 64);
}

:root {
  --shadow-blk: rgba(0, 0, 0, 0.2);
}

/*
Shadow

Classes for setting the box-shadow an element.

Styleguide Shadow
*/
/*
.sh-blk-0

Sets the shadow of element to css var for 0 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-0 - Sets the shadow of element to css var for 0 with blk

Styleguide Shadow - 0:blk
*/
.sh-blk-0 {
  box-shadow: 0 var(--shadow-0) calc(var(--shadow-0) * 2) var(--shadow-blk);
}

/*
.sh-blk-1

Sets the shadow of element to css var for 1 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-1 - Sets the shadow of element to css var for 1 with blk

Styleguide Shadow - 1:blk
*/
.sh-blk-1 {
  box-shadow: 0 var(--shadow-1) calc(var(--shadow-1) * 2) var(--shadow-blk);
}

/*
.sh-blk-2

Sets the shadow of element to css var for 2 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-2 - Sets the shadow of element to css var for 2 with blk

Styleguide Shadow - 2:blk
*/
.sh-blk-2 {
  box-shadow: 0 var(--shadow-2) calc(var(--shadow-2) * 2) var(--shadow-blk);
}

/*
.sh-blk-3

Sets the shadow of element to css var for 3 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-3 - Sets the shadow of element to css var for 3 with blk

Styleguide Shadow - 3:blk
*/
.sh-blk-3 {
  box-shadow: 0 var(--shadow-3) calc(var(--shadow-3) * 2) var(--shadow-blk);
}

/*
.sh-blk-4

Sets the shadow of element to css var for 4 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-4 - Sets the shadow of element to css var for 4 with blk

Styleguide Shadow - 4:blk
*/
.sh-blk-4 {
  box-shadow: 0 var(--shadow-4) calc(var(--shadow-4) * 2) var(--shadow-blk);
}

/*
.sh-blk-5

Sets the shadow of element to css var for 5 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-5 - Sets the shadow of element to css var for 5 with blk

Styleguide Shadow - 5:blk
*/
.sh-blk-5 {
  box-shadow: 0 var(--shadow-5) calc(var(--shadow-5) * 2) var(--shadow-blk);
}

/*
.sh-blk-6

Sets the shadow of element to css var for 6 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-6 - Sets the shadow of element to css var for 6 with blk

Styleguide Shadow - 6:blk
*/
.sh-blk-6 {
  box-shadow: 0 var(--shadow-6) calc(var(--shadow-6) * 2) var(--shadow-blk);
}

/*
.sh-blk-7

Sets the shadow of element to css var for 7 with blk.

Markup:
<div class="{{modifier_class}}">A div</div>

.sh-blk-7 - Sets the shadow of element to css var for 7 with blk

Styleguide Shadow - 7:blk
*/
.sh-blk-7 {
  box-shadow: 0 var(--shadow-7) calc(var(--shadow-7) * 2) var(--shadow-blk);
}

/*
Text

Classes for setting text properties of an element.

Styleguide Text
*/
/*
Text Align

Classes for setting text-align of an element.

Styleguide Text - Align
*/
/*
.ta-l

Classes for setting text-align of an element to left.

Markup:
<div class="{{modifier_class}}">A div</div>

.ta-l - Sets text-align to left

Styleguide Text - Align - l
*/
.ta-l {
  text-align: left;
}

/*
.ta-r

Classes for setting text-align of an element to right.

Markup:
<div class="{{modifier_class}}">A div</div>

.ta-r - Sets text-align to right

Styleguide Text - Align - r
*/
.ta-r {
  text-align: right;
}

/*
.ta-c

Classes for setting text-align of an element to center.

Markup:
<div class="{{modifier_class}}">A div</div>

.ta-c - Sets text-align to center

Styleguide Text - Align - c
*/
.ta-c {
  text-align: center;
}

/*
.ta-j

Classes for setting text-align of an element to justify.

Markup:
<div class="{{modifier_class}}">A div</div>

.ta-j - Sets text-align to justify

Styleguide Text - Align - j
*/
.ta-j {
  text-align: justify;
}

@media (min-width: 340px) {
  /*
  .ta-sm-l

  Classes for setting text-align of an element to left on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ta-sm-l - Sets text-align to left on sm

  Styleguide Text - Align - l - sm
  */
  .ta-sm-l {
    text-align: left;
  }

  /*
  .ta-sm-r

  Classes for setting text-align of an element to right on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ta-sm-r - Sets text-align to right on sm

  Styleguide Text - Align - r - sm
  */
  .ta-sm-r {
    text-align: right;
  }

  /*
  .ta-sm-c

  Classes for setting text-align of an element to center on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ta-sm-c - Sets text-align to center on sm

  Styleguide Text - Align - c - sm
  */
  .ta-sm-c {
    text-align: center;
  }

  /*
  .ta-sm-j

  Classes for setting text-align of an element to justify on sm.

  Markup:
  <div class="{{modifier_class}}">A div</div>

  .ta-sm-j - Sets text-align to justify on sm

  Styleguide Text - Align - j - sm
  */
  .ta-sm-j {
    text-align: justify;
  }
}
:root {
  --width-p10: 10%;
  --width-p20: 20%;
  --width-p25: 25%;
  --width-p30: 30%;
  --width-p40: 40%;
  --width-p50: 50%;
  --width-p60: 60%;
  --width-p70: 70%;
  --width-p75: 75%;
  --width-p80: 80%;
  --width-p90: 90%;
  --width-p100: 100%;
  --width-ch0: 30ch;
  --width-ch1: 50ch;
  --width-ch2: 70ch;
  --width-ch3: 90ch;
  --width-ch4: 120ch;
  --width-px0: 400px;
  --width-px1: 600px;
  --width-px2: 960px;
  --width-px3: 1200px;
  --width-em0: 340px;
  --width-em1: 75em;
  --width-em2: 90em;
  --width-rem0: 50rem;
  --width-rem1: 75rem;
  --width-rem2: 90rem;
}

/*
Width

Classes for setting the width, max-width, an min-width of an element (or its children).

Styleguide Width
*/
/*
Child Width

Classes for setting the width, max-width, an min-width of an element's immediate Children.

Styleguide Child Width
*/
/*
.vw[mx,mn]-p10

Sets the width, max-width, or min-width of element to css var for p10.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p10 - Sets width to p10
.vwmn-p10 - Sets min-width to p10
.vwmx-p10 - Sets max-width to p10

Styleguide Width - p10
*/
/*
.vwc[mx,mn]-p10

Sets the width, max-width, or min-width of an elements immediate children to css var for p10.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p10 - Sets width of immediate children to p10
.vwcmn-p10 - Sets min-width of immediate children to p10
.vwcmx-p10 - Sets max-width of immediate children to p10

Styleguide Child Width - p10
*/
.vw-p10,
.vwc-p10 > * {
  width: var(--width-p10);
}

.vwmn-p10,
.vwcmn-p10 > * {
  min-width: var(--width-p10);
}

.vwmx-p10,
.vwcmx-p10 > * {
  max-width: var(--width-p10);
}

/*
.vw[mx,mn]-p20

Sets the width, max-width, or min-width of element to css var for p20.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p20 - Sets width to p20
.vwmn-p20 - Sets min-width to p20
.vwmx-p20 - Sets max-width to p20

Styleguide Width - p20
*/
/*
.vwc[mx,mn]-p20

Sets the width, max-width, or min-width of an elements immediate children to css var for p20.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p20 - Sets width of immediate children to p20
.vwcmn-p20 - Sets min-width of immediate children to p20
.vwcmx-p20 - Sets max-width of immediate children to p20

Styleguide Child Width - p20
*/
.vw-p20,
.vwc-p20 > * {
  width: var(--width-p20);
}

.vwmn-p20,
.vwcmn-p20 > * {
  min-width: var(--width-p20);
}

.vwmx-p20,
.vwcmx-p20 > * {
  max-width: var(--width-p20);
}

/*
.vw[mx,mn]-p25

Sets the width, max-width, or min-width of element to css var for p25.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p25 - Sets width to p25
.vwmn-p25 - Sets min-width to p25
.vwmx-p25 - Sets max-width to p25

Styleguide Width - p25
*/
/*
.vwc[mx,mn]-p25

Sets the width, max-width, or min-width of an elements immediate children to css var for p25.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p25 - Sets width of immediate children to p25
.vwcmn-p25 - Sets min-width of immediate children to p25
.vwcmx-p25 - Sets max-width of immediate children to p25

Styleguide Child Width - p25
*/
.vw-p25,
.vwc-p25 > * {
  width: var(--width-p25);
}

.vwmn-p25,
.vwcmn-p25 > * {
  min-width: var(--width-p25);
}

.vwmx-p25,
.vwcmx-p25 > * {
  max-width: var(--width-p25);
}

/*
.vw[mx,mn]-p30

Sets the width, max-width, or min-width of element to css var for p30.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p30 - Sets width to p30
.vwmn-p30 - Sets min-width to p30
.vwmx-p30 - Sets max-width to p30

Styleguide Width - p30
*/
/*
.vwc[mx,mn]-p30

Sets the width, max-width, or min-width of an elements immediate children to css var for p30.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p30 - Sets width of immediate children to p30
.vwcmn-p30 - Sets min-width of immediate children to p30
.vwcmx-p30 - Sets max-width of immediate children to p30

Styleguide Child Width - p30
*/
.vw-p30,
.vwc-p30 > * {
  width: var(--width-p30);
}

.vwmn-p30,
.vwcmn-p30 > * {
  min-width: var(--width-p30);
}

.vwmx-p30,
.vwcmx-p30 > * {
  max-width: var(--width-p30);
}

/*
.vw[mx,mn]-p40

Sets the width, max-width, or min-width of element to css var for p40.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p40 - Sets width to p40
.vwmn-p40 - Sets min-width to p40
.vwmx-p40 - Sets max-width to p40

Styleguide Width - p40
*/
/*
.vwc[mx,mn]-p40

Sets the width, max-width, or min-width of an elements immediate children to css var for p40.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p40 - Sets width of immediate children to p40
.vwcmn-p40 - Sets min-width of immediate children to p40
.vwcmx-p40 - Sets max-width of immediate children to p40

Styleguide Child Width - p40
*/
.vw-p40,
.vwc-p40 > * {
  width: var(--width-p40);
}

.vwmn-p40,
.vwcmn-p40 > * {
  min-width: var(--width-p40);
}

.vwmx-p40,
.vwcmx-p40 > * {
  max-width: var(--width-p40);
}

/*
.vw[mx,mn]-p50

Sets the width, max-width, or min-width of element to css var for p50.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p50 - Sets width to p50
.vwmn-p50 - Sets min-width to p50
.vwmx-p50 - Sets max-width to p50

Styleguide Width - p50
*/
/*
.vwc[mx,mn]-p50

Sets the width, max-width, or min-width of an elements immediate children to css var for p50.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p50 - Sets width of immediate children to p50
.vwcmn-p50 - Sets min-width of immediate children to p50
.vwcmx-p50 - Sets max-width of immediate children to p50

Styleguide Child Width - p50
*/
.vw-p50,
.vwc-p50 > * {
  width: var(--width-p50);
}

.vwmn-p50,
.vwcmn-p50 > * {
  min-width: var(--width-p50);
}

.vwmx-p50,
.vwcmx-p50 > * {
  max-width: var(--width-p50);
}

/*
.vw[mx,mn]-p60

Sets the width, max-width, or min-width of element to css var for p60.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p60 - Sets width to p60
.vwmn-p60 - Sets min-width to p60
.vwmx-p60 - Sets max-width to p60

Styleguide Width - p60
*/
/*
.vwc[mx,mn]-p60

Sets the width, max-width, or min-width of an elements immediate children to css var for p60.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p60 - Sets width of immediate children to p60
.vwcmn-p60 - Sets min-width of immediate children to p60
.vwcmx-p60 - Sets max-width of immediate children to p60

Styleguide Child Width - p60
*/
.vw-p60,
.vwc-p60 > * {
  width: var(--width-p60);
}

.vwmn-p60,
.vwcmn-p60 > * {
  min-width: var(--width-p60);
}

.vwmx-p60,
.vwcmx-p60 > * {
  max-width: var(--width-p60);
}

/*
.vw[mx,mn]-p70

Sets the width, max-width, or min-width of element to css var for p70.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p70 - Sets width to p70
.vwmn-p70 - Sets min-width to p70
.vwmx-p70 - Sets max-width to p70

Styleguide Width - p70
*/
/*
.vwc[mx,mn]-p70

Sets the width, max-width, or min-width of an elements immediate children to css var for p70.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p70 - Sets width of immediate children to p70
.vwcmn-p70 - Sets min-width of immediate children to p70
.vwcmx-p70 - Sets max-width of immediate children to p70

Styleguide Child Width - p70
*/
.vw-p70,
.vwc-p70 > * {
  width: var(--width-p70);
}

.vwmn-p70,
.vwcmn-p70 > * {
  min-width: var(--width-p70);
}

.vwmx-p70,
.vwcmx-p70 > * {
  max-width: var(--width-p70);
}

/*
.vw[mx,mn]-p75

Sets the width, max-width, or min-width of element to css var for p75.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p75 - Sets width to p75
.vwmn-p75 - Sets min-width to p75
.vwmx-p75 - Sets max-width to p75

Styleguide Width - p75
*/
/*
.vwc[mx,mn]-p75

Sets the width, max-width, or min-width of an elements immediate children to css var for p75.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p75 - Sets width of immediate children to p75
.vwcmn-p75 - Sets min-width of immediate children to p75
.vwcmx-p75 - Sets max-width of immediate children to p75

Styleguide Child Width - p75
*/
.vw-p75,
.vwc-p75 > * {
  width: var(--width-p75);
}

.vwmn-p75,
.vwcmn-p75 > * {
  min-width: var(--width-p75);
}

.vwmx-p75,
.vwcmx-p75 > * {
  max-width: var(--width-p75);
}

/*
.vw[mx,mn]-p80

Sets the width, max-width, or min-width of element to css var for p80.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p80 - Sets width to p80
.vwmn-p80 - Sets min-width to p80
.vwmx-p80 - Sets max-width to p80

Styleguide Width - p80
*/
/*
.vwc[mx,mn]-p80

Sets the width, max-width, or min-width of an elements immediate children to css var for p80.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p80 - Sets width of immediate children to p80
.vwcmn-p80 - Sets min-width of immediate children to p80
.vwcmx-p80 - Sets max-width of immediate children to p80

Styleguide Child Width - p80
*/
.vw-p80,
.vwc-p80 > * {
  width: var(--width-p80);
}

.vwmn-p80,
.vwcmn-p80 > * {
  min-width: var(--width-p80);
}

.vwmx-p80,
.vwcmx-p80 > * {
  max-width: var(--width-p80);
}

/*
.vw[mx,mn]-p90

Sets the width, max-width, or min-width of element to css var for p90.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p90 - Sets width to p90
.vwmn-p90 - Sets min-width to p90
.vwmx-p90 - Sets max-width to p90

Styleguide Width - p90
*/
/*
.vwc[mx,mn]-p90

Sets the width, max-width, or min-width of an elements immediate children to css var for p90.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p90 - Sets width of immediate children to p90
.vwcmn-p90 - Sets min-width of immediate children to p90
.vwcmx-p90 - Sets max-width of immediate children to p90

Styleguide Child Width - p90
*/
.vw-p90,
.vwc-p90 > * {
  width: var(--width-p90);
}

.vwmn-p90,
.vwcmn-p90 > * {
  min-width: var(--width-p90);
}

.vwmx-p90,
.vwcmx-p90 > * {
  max-width: var(--width-p90);
}

/*
.vw[mx,mn]-p100

Sets the width, max-width, or min-width of element to css var for p100.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-p100 - Sets width to p100
.vwmn-p100 - Sets min-width to p100
.vwmx-p100 - Sets max-width to p100

Styleguide Width - p100
*/
/*
.vwc[mx,mn]-p100

Sets the width, max-width, or min-width of an elements immediate children to css var for p100.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-p100 - Sets width of immediate children to p100
.vwcmn-p100 - Sets min-width of immediate children to p100
.vwcmx-p100 - Sets max-width of immediate children to p100

Styleguide Child Width - p100
*/
.vw-p100,
.vwc-p100 > * {
  width: var(--width-p100);
}

.vwmn-p100,
.vwcmn-p100 > * {
  min-width: var(--width-p100);
}

.vwmx-p100,
.vwcmx-p100 > * {
  max-width: var(--width-p100);
}

/*
.vw[mx,mn]-ch0

Sets the width, max-width, or min-width of element to css var for ch0.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-ch0 - Sets width to ch0
.vwmn-ch0 - Sets min-width to ch0
.vwmx-ch0 - Sets max-width to ch0

Styleguide Width - ch0
*/
/*
.vwc[mx,mn]-ch0

Sets the width, max-width, or min-width of an elements immediate children to css var for ch0.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-ch0 - Sets width of immediate children to ch0
.vwcmn-ch0 - Sets min-width of immediate children to ch0
.vwcmx-ch0 - Sets max-width of immediate children to ch0

Styleguide Child Width - ch0
*/
.vw-ch0,
.vwc-ch0 > * {
  width: var(--width-ch0);
}

.vwmn-ch0,
.vwcmn-ch0 > * {
  min-width: var(--width-ch0);
}

.vwmx-ch0,
.vwcmx-ch0 > * {
  max-width: var(--width-ch0);
}

/*
.vw[mx,mn]-ch1

Sets the width, max-width, or min-width of element to css var for ch1.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-ch1 - Sets width to ch1
.vwmn-ch1 - Sets min-width to ch1
.vwmx-ch1 - Sets max-width to ch1

Styleguide Width - ch1
*/
/*
.vwc[mx,mn]-ch1

Sets the width, max-width, or min-width of an elements immediate children to css var for ch1.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-ch1 - Sets width of immediate children to ch1
.vwcmn-ch1 - Sets min-width of immediate children to ch1
.vwcmx-ch1 - Sets max-width of immediate children to ch1

Styleguide Child Width - ch1
*/
.vw-ch1,
.vwc-ch1 > * {
  width: var(--width-ch1);
}

.vwmn-ch1,
.vwcmn-ch1 > * {
  min-width: var(--width-ch1);
}

.vwmx-ch1,
.vwcmx-ch1 > * {
  max-width: var(--width-ch1);
}

/*
.vw[mx,mn]-ch2

Sets the width, max-width, or min-width of element to css var for ch2.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-ch2 - Sets width to ch2
.vwmn-ch2 - Sets min-width to ch2
.vwmx-ch2 - Sets max-width to ch2

Styleguide Width - ch2
*/
/*
.vwc[mx,mn]-ch2

Sets the width, max-width, or min-width of an elements immediate children to css var for ch2.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-ch2 - Sets width of immediate children to ch2
.vwcmn-ch2 - Sets min-width of immediate children to ch2
.vwcmx-ch2 - Sets max-width of immediate children to ch2

Styleguide Child Width - ch2
*/
.vw-ch2,
.vwc-ch2 > * {
  width: var(--width-ch2);
}

.vwmn-ch2,
.vwcmn-ch2 > * {
  min-width: var(--width-ch2);
}

.vwmx-ch2,
.vwcmx-ch2 > * {
  max-width: var(--width-ch2);
}

/*
.vw[mx,mn]-ch3

Sets the width, max-width, or min-width of element to css var for ch3.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-ch3 - Sets width to ch3
.vwmn-ch3 - Sets min-width to ch3
.vwmx-ch3 - Sets max-width to ch3

Styleguide Width - ch3
*/
/*
.vwc[mx,mn]-ch3

Sets the width, max-width, or min-width of an elements immediate children to css var for ch3.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-ch3 - Sets width of immediate children to ch3
.vwcmn-ch3 - Sets min-width of immediate children to ch3
.vwcmx-ch3 - Sets max-width of immediate children to ch3

Styleguide Child Width - ch3
*/
.vw-ch3,
.vwc-ch3 > * {
  width: var(--width-ch3);
}

.vwmn-ch3,
.vwcmn-ch3 > * {
  min-width: var(--width-ch3);
}

.vwmx-ch3,
.vwcmx-ch3 > * {
  max-width: var(--width-ch3);
}

/*
.vw[mx,mn]-ch4

Sets the width, max-width, or min-width of element to css var for ch4.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-ch4 - Sets width to ch4
.vwmn-ch4 - Sets min-width to ch4
.vwmx-ch4 - Sets max-width to ch4

Styleguide Width - ch4
*/
/*
.vwc[mx,mn]-ch4

Sets the width, max-width, or min-width of an elements immediate children to css var for ch4.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-ch4 - Sets width of immediate children to ch4
.vwcmn-ch4 - Sets min-width of immediate children to ch4
.vwcmx-ch4 - Sets max-width of immediate children to ch4

Styleguide Child Width - ch4
*/
.vw-ch4,
.vwc-ch4 > * {
  width: var(--width-ch4);
}

.vwmn-ch4,
.vwcmn-ch4 > * {
  min-width: var(--width-ch4);
}

.vwmx-ch4,
.vwcmx-ch4 > * {
  max-width: var(--width-ch4);
}

/*
.vw[mx,mn]-px0

Sets the width, max-width, or min-width of element to css var for px0.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-px0 - Sets width to px0
.vwmn-px0 - Sets min-width to px0
.vwmx-px0 - Sets max-width to px0

Styleguide Width - px0
*/
/*
.vwc[mx,mn]-px0

Sets the width, max-width, or min-width of an elements immediate children to css var for px0.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-px0 - Sets width of immediate children to px0
.vwcmn-px0 - Sets min-width of immediate children to px0
.vwcmx-px0 - Sets max-width of immediate children to px0

Styleguide Child Width - px0
*/
.vw-px0,
.vwc-px0 > * {
  width: var(--width-px0);
}

.vwmn-px0,
.vwcmn-px0 > * {
  min-width: var(--width-px0);
}

.vwmx-px0,
.vwcmx-px0 > * {
  max-width: var(--width-px0);
}

/*
.vw[mx,mn]-px1

Sets the width, max-width, or min-width of element to css var for px1.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-px1 - Sets width to px1
.vwmn-px1 - Sets min-width to px1
.vwmx-px1 - Sets max-width to px1

Styleguide Width - px1
*/
/*
.vwc[mx,mn]-px1

Sets the width, max-width, or min-width of an elements immediate children to css var for px1.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-px1 - Sets width of immediate children to px1
.vwcmn-px1 - Sets min-width of immediate children to px1
.vwcmx-px1 - Sets max-width of immediate children to px1

Styleguide Child Width - px1
*/
.vw-px1,
.vwc-px1 > * {
  width: var(--width-px1);
}

.vwmn-px1,
.vwcmn-px1 > * {
  min-width: var(--width-px1);
}

.vwmx-px1,
.vwcmx-px1 > * {
  max-width: var(--width-px1);
}

/*
.vw[mx,mn]-px2

Sets the width, max-width, or min-width of element to css var for px2.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-px2 - Sets width to px2
.vwmn-px2 - Sets min-width to px2
.vwmx-px2 - Sets max-width to px2

Styleguide Width - px2
*/
/*
.vwc[mx,mn]-px2

Sets the width, max-width, or min-width of an elements immediate children to css var for px2.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-px2 - Sets width of immediate children to px2
.vwcmn-px2 - Sets min-width of immediate children to px2
.vwcmx-px2 - Sets max-width of immediate children to px2

Styleguide Child Width - px2
*/
.vw-px2,
.vwc-px2 > * {
  width: var(--width-px2);
}

.vwmn-px2,
.vwcmn-px2 > * {
  min-width: var(--width-px2);
}

.vwmx-px2,
.vwcmx-px2 > * {
  max-width: var(--width-px2);
}

/*
.vw[mx,mn]-px3

Sets the width, max-width, or min-width of element to css var for px3.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-px3 - Sets width to px3
.vwmn-px3 - Sets min-width to px3
.vwmx-px3 - Sets max-width to px3

Styleguide Width - px3
*/
/*
.vwc[mx,mn]-px3

Sets the width, max-width, or min-width of an elements immediate children to css var for px3.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-px3 - Sets width of immediate children to px3
.vwcmn-px3 - Sets min-width of immediate children to px3
.vwcmx-px3 - Sets max-width of immediate children to px3

Styleguide Child Width - px3
*/
.vw-px3,
.vwc-px3 > * {
  width: var(--width-px3);
}

.vwmn-px3,
.vwcmn-px3 > * {
  min-width: var(--width-px3);
}

.vwmx-px3,
.vwcmx-px3 > * {
  max-width: var(--width-px3);
}

/*
.vw[mx,mn]-em0

Sets the width, max-width, or min-width of element to css var for em0.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-em0 - Sets width to em0
.vwmn-em0 - Sets min-width to em0
.vwmx-em0 - Sets max-width to em0

Styleguide Width - em0
*/
/*
.vwc[mx,mn]-em0

Sets the width, max-width, or min-width of an elements immediate children to css var for em0.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-em0 - Sets width of immediate children to em0
.vwcmn-em0 - Sets min-width of immediate children to em0
.vwcmx-em0 - Sets max-width of immediate children to em0

Styleguide Child Width - em0
*/
.vw-em0,
.vwc-em0 > * {
  width: var(--width-em0);
}

.vwmn-em0,
.vwcmn-em0 > * {
  min-width: var(--width-em0);
}

.vwmx-em0,
.vwcmx-em0 > * {
  max-width: var(--width-em0);
}

/*
.vw[mx,mn]-em1

Sets the width, max-width, or min-width of element to css var for em1.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-em1 - Sets width to em1
.vwmn-em1 - Sets min-width to em1
.vwmx-em1 - Sets max-width to em1

Styleguide Width - em1
*/
/*
.vwc[mx,mn]-em1

Sets the width, max-width, or min-width of an elements immediate children to css var for em1.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-em1 - Sets width of immediate children to em1
.vwcmn-em1 - Sets min-width of immediate children to em1
.vwcmx-em1 - Sets max-width of immediate children to em1

Styleguide Child Width - em1
*/
.vw-em1,
.vwc-em1 > * {
  width: var(--width-em1);
}

.vwmn-em1,
.vwcmn-em1 > * {
  min-width: var(--width-em1);
}

.vwmx-em1,
.vwcmx-em1 > * {
  max-width: var(--width-em1);
}

/*
.vw[mx,mn]-em2

Sets the width, max-width, or min-width of element to css var for em2.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-em2 - Sets width to em2
.vwmn-em2 - Sets min-width to em2
.vwmx-em2 - Sets max-width to em2

Styleguide Width - em2
*/
/*
.vwc[mx,mn]-em2

Sets the width, max-width, or min-width of an elements immediate children to css var for em2.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-em2 - Sets width of immediate children to em2
.vwcmn-em2 - Sets min-width of immediate children to em2
.vwcmx-em2 - Sets max-width of immediate children to em2

Styleguide Child Width - em2
*/
.vw-em2,
.vwc-em2 > * {
  width: var(--width-em2);
}

.vwmn-em2,
.vwcmn-em2 > * {
  min-width: var(--width-em2);
}

.vwmx-em2,
.vwcmx-em2 > * {
  max-width: var(--width-em2);
}

/*
.vw[mx,mn]-rem0

Sets the width, max-width, or min-width of element to css var for rem0.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-rem0 - Sets width to rem0
.vwmn-rem0 - Sets min-width to rem0
.vwmx-rem0 - Sets max-width to rem0

Styleguide Width - rem0
*/
/*
.vwc[mx,mn]-rem0

Sets the width, max-width, or min-width of an elements immediate children to css var for rem0.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-rem0 - Sets width of immediate children to rem0
.vwcmn-rem0 - Sets min-width of immediate children to rem0
.vwcmx-rem0 - Sets max-width of immediate children to rem0

Styleguide Child Width - rem0
*/
.vw-rem0,
.vwc-rem0 > * {
  width: var(--width-rem0);
}

.vwmn-rem0,
.vwcmn-rem0 > * {
  min-width: var(--width-rem0);
}

.vwmx-rem0,
.vwcmx-rem0 > * {
  max-width: var(--width-rem0);
}

/*
.vw[mx,mn]-rem1

Sets the width, max-width, or min-width of element to css var for rem1.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-rem1 - Sets width to rem1
.vwmn-rem1 - Sets min-width to rem1
.vwmx-rem1 - Sets max-width to rem1

Styleguide Width - rem1
*/
/*
.vwc[mx,mn]-rem1

Sets the width, max-width, or min-width of an elements immediate children to css var for rem1.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-rem1 - Sets width of immediate children to rem1
.vwcmn-rem1 - Sets min-width of immediate children to rem1
.vwcmx-rem1 - Sets max-width of immediate children to rem1

Styleguide Child Width - rem1
*/
.vw-rem1,
.vwc-rem1 > * {
  width: var(--width-rem1);
}

.vwmn-rem1,
.vwcmn-rem1 > * {
  min-width: var(--width-rem1);
}

.vwmx-rem1,
.vwcmx-rem1 > * {
  max-width: var(--width-rem1);
}

/*
.vw[mx,mn]-rem2

Sets the width, max-width, or min-width of element to css var for rem2.

Markup:
<div class="bwa-1 {{modifier_class}}">A div</div>

.vw-rem2 - Sets width to rem2
.vwmn-rem2 - Sets min-width to rem2
.vwmx-rem2 - Sets max-width to rem2

Styleguide Width - rem2
*/
/*
.vwc[mx,mn]-rem2

Sets the width, max-width, or min-width of an elements immediate children to css var for rem2.

Markup:
<div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

.vwc-rem2 - Sets width of immediate children to rem2
.vwcmn-rem2 - Sets min-width of immediate children to rem2
.vwcmx-rem2 - Sets max-width of immediate children to rem2

Styleguide Child Width - rem2
*/
.vw-rem2,
.vwc-rem2 > * {
  width: var(--width-rem2);
}

.vwmn-rem2,
.vwcmn-rem2 > * {
  min-width: var(--width-rem2);
}

.vwmx-rem2,
.vwcmx-rem2 > * {
  max-width: var(--width-rem2);
}

@media (min-width: 340px) {
  /*
  .vw[mn,mx]-sm-p10

  Sets the width, max-width, or min-width of element to css var for p10 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p10 - Sets width to p10 on sm
  .vwmn-sm-p10 - Sets min-width to p10 on sm
  .vwmx-sm-p10 - Sets max-width to p10 on sm

  Styleguide Width - p10 - sm
  */
  /*
  .vwc[mn,mx]-sm-p10

  Sets the width, max-width, or min-width of an elements immediate children to css var for p10 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p10 - Sets width of immediate children to p10 on sm
  .vwcmn-sm-p10 - Sets min-width of immediate children to p10 on sm
  .vwcmx-sm-p10 - Sets max-width of immediate children to p10 on sm

  Styleguide Child Width - p10 - sm
  */
  .vw-sm-p10,
  .vwc-sm-p10 > * {
    width: var(--width-p10);
  }

  .vwmn-sm-p10,
  .vwcmn-sm-p10 > * {
    min-width: var(--width-p10);
  }

  .vwmx-sm-p10,
  .vwcmx-sm-p10 > * {
    max-width: var(--width-p10);
  }

  /*
  .vw[mn,mx]-sm-p20

  Sets the width, max-width, or min-width of element to css var for p20 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p20 - Sets width to p20 on sm
  .vwmn-sm-p20 - Sets min-width to p20 on sm
  .vwmx-sm-p20 - Sets max-width to p20 on sm

  Styleguide Width - p20 - sm
  */
  /*
  .vwc[mn,mx]-sm-p20

  Sets the width, max-width, or min-width of an elements immediate children to css var for p20 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p20 - Sets width of immediate children to p20 on sm
  .vwcmn-sm-p20 - Sets min-width of immediate children to p20 on sm
  .vwcmx-sm-p20 - Sets max-width of immediate children to p20 on sm

  Styleguide Child Width - p20 - sm
  */
  .vw-sm-p20,
  .vwc-sm-p20 > * {
    width: var(--width-p20);
  }

  .vwmn-sm-p20,
  .vwcmn-sm-p20 > * {
    min-width: var(--width-p20);
  }

  .vwmx-sm-p20,
  .vwcmx-sm-p20 > * {
    max-width: var(--width-p20);
  }

  /*
  .vw[mn,mx]-sm-p25

  Sets the width, max-width, or min-width of element to css var for p25 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p25 - Sets width to p25 on sm
  .vwmn-sm-p25 - Sets min-width to p25 on sm
  .vwmx-sm-p25 - Sets max-width to p25 on sm

  Styleguide Width - p25 - sm
  */
  /*
  .vwc[mn,mx]-sm-p25

  Sets the width, max-width, or min-width of an elements immediate children to css var for p25 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p25 - Sets width of immediate children to p25 on sm
  .vwcmn-sm-p25 - Sets min-width of immediate children to p25 on sm
  .vwcmx-sm-p25 - Sets max-width of immediate children to p25 on sm

  Styleguide Child Width - p25 - sm
  */
  .vw-sm-p25,
  .vwc-sm-p25 > * {
    width: var(--width-p25);
  }

  .vwmn-sm-p25,
  .vwcmn-sm-p25 > * {
    min-width: var(--width-p25);
  }

  .vwmx-sm-p25,
  .vwcmx-sm-p25 > * {
    max-width: var(--width-p25);
  }

  /*
  .vw[mn,mx]-sm-p30

  Sets the width, max-width, or min-width of element to css var for p30 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p30 - Sets width to p30 on sm
  .vwmn-sm-p30 - Sets min-width to p30 on sm
  .vwmx-sm-p30 - Sets max-width to p30 on sm

  Styleguide Width - p30 - sm
  */
  /*
  .vwc[mn,mx]-sm-p30

  Sets the width, max-width, or min-width of an elements immediate children to css var for p30 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p30 - Sets width of immediate children to p30 on sm
  .vwcmn-sm-p30 - Sets min-width of immediate children to p30 on sm
  .vwcmx-sm-p30 - Sets max-width of immediate children to p30 on sm

  Styleguide Child Width - p30 - sm
  */
  .vw-sm-p30,
  .vwc-sm-p30 > * {
    width: var(--width-p30);
  }

  .vwmn-sm-p30,
  .vwcmn-sm-p30 > * {
    min-width: var(--width-p30);
  }

  .vwmx-sm-p30,
  .vwcmx-sm-p30 > * {
    max-width: var(--width-p30);
  }

  /*
  .vw[mn,mx]-sm-p40

  Sets the width, max-width, or min-width of element to css var for p40 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p40 - Sets width to p40 on sm
  .vwmn-sm-p40 - Sets min-width to p40 on sm
  .vwmx-sm-p40 - Sets max-width to p40 on sm

  Styleguide Width - p40 - sm
  */
  /*
  .vwc[mn,mx]-sm-p40

  Sets the width, max-width, or min-width of an elements immediate children to css var for p40 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p40 - Sets width of immediate children to p40 on sm
  .vwcmn-sm-p40 - Sets min-width of immediate children to p40 on sm
  .vwcmx-sm-p40 - Sets max-width of immediate children to p40 on sm

  Styleguide Child Width - p40 - sm
  */
  .vw-sm-p40,
  .vwc-sm-p40 > * {
    width: var(--width-p40);
  }

  .vwmn-sm-p40,
  .vwcmn-sm-p40 > * {
    min-width: var(--width-p40);
  }

  .vwmx-sm-p40,
  .vwcmx-sm-p40 > * {
    max-width: var(--width-p40);
  }

  /*
  .vw[mn,mx]-sm-p50

  Sets the width, max-width, or min-width of element to css var for p50 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p50 - Sets width to p50 on sm
  .vwmn-sm-p50 - Sets min-width to p50 on sm
  .vwmx-sm-p50 - Sets max-width to p50 on sm

  Styleguide Width - p50 - sm
  */
  /*
  .vwc[mn,mx]-sm-p50

  Sets the width, max-width, or min-width of an elements immediate children to css var for p50 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p50 - Sets width of immediate children to p50 on sm
  .vwcmn-sm-p50 - Sets min-width of immediate children to p50 on sm
  .vwcmx-sm-p50 - Sets max-width of immediate children to p50 on sm

  Styleguide Child Width - p50 - sm
  */
  .vw-sm-p50,
  .vwc-sm-p50 > * {
    width: var(--width-p50);
  }

  .vwmn-sm-p50,
  .vwcmn-sm-p50 > * {
    min-width: var(--width-p50);
  }

  .vwmx-sm-p50,
  .vwcmx-sm-p50 > * {
    max-width: var(--width-p50);
  }

  /*
  .vw[mn,mx]-sm-p60

  Sets the width, max-width, or min-width of element to css var for p60 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p60 - Sets width to p60 on sm
  .vwmn-sm-p60 - Sets min-width to p60 on sm
  .vwmx-sm-p60 - Sets max-width to p60 on sm

  Styleguide Width - p60 - sm
  */
  /*
  .vwc[mn,mx]-sm-p60

  Sets the width, max-width, or min-width of an elements immediate children to css var for p60 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p60 - Sets width of immediate children to p60 on sm
  .vwcmn-sm-p60 - Sets min-width of immediate children to p60 on sm
  .vwcmx-sm-p60 - Sets max-width of immediate children to p60 on sm

  Styleguide Child Width - p60 - sm
  */
  .vw-sm-p60,
  .vwc-sm-p60 > * {
    width: var(--width-p60);
  }

  .vwmn-sm-p60,
  .vwcmn-sm-p60 > * {
    min-width: var(--width-p60);
  }

  .vwmx-sm-p60,
  .vwcmx-sm-p60 > * {
    max-width: var(--width-p60);
  }

  /*
  .vw[mn,mx]-sm-p70

  Sets the width, max-width, or min-width of element to css var for p70 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p70 - Sets width to p70 on sm
  .vwmn-sm-p70 - Sets min-width to p70 on sm
  .vwmx-sm-p70 - Sets max-width to p70 on sm

  Styleguide Width - p70 - sm
  */
  /*
  .vwc[mn,mx]-sm-p70

  Sets the width, max-width, or min-width of an elements immediate children to css var for p70 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p70 - Sets width of immediate children to p70 on sm
  .vwcmn-sm-p70 - Sets min-width of immediate children to p70 on sm
  .vwcmx-sm-p70 - Sets max-width of immediate children to p70 on sm

  Styleguide Child Width - p70 - sm
  */
  .vw-sm-p70,
  .vwc-sm-p70 > * {
    width: var(--width-p70);
  }

  .vwmn-sm-p70,
  .vwcmn-sm-p70 > * {
    min-width: var(--width-p70);
  }

  .vwmx-sm-p70,
  .vwcmx-sm-p70 > * {
    max-width: var(--width-p70);
  }

  /*
  .vw[mn,mx]-sm-p75

  Sets the width, max-width, or min-width of element to css var for p75 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p75 - Sets width to p75 on sm
  .vwmn-sm-p75 - Sets min-width to p75 on sm
  .vwmx-sm-p75 - Sets max-width to p75 on sm

  Styleguide Width - p75 - sm
  */
  /*
  .vwc[mn,mx]-sm-p75

  Sets the width, max-width, or min-width of an elements immediate children to css var for p75 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p75 - Sets width of immediate children to p75 on sm
  .vwcmn-sm-p75 - Sets min-width of immediate children to p75 on sm
  .vwcmx-sm-p75 - Sets max-width of immediate children to p75 on sm

  Styleguide Child Width - p75 - sm
  */
  .vw-sm-p75,
  .vwc-sm-p75 > * {
    width: var(--width-p75);
  }

  .vwmn-sm-p75,
  .vwcmn-sm-p75 > * {
    min-width: var(--width-p75);
  }

  .vwmx-sm-p75,
  .vwcmx-sm-p75 > * {
    max-width: var(--width-p75);
  }

  /*
  .vw[mn,mx]-sm-p80

  Sets the width, max-width, or min-width of element to css var for p80 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p80 - Sets width to p80 on sm
  .vwmn-sm-p80 - Sets min-width to p80 on sm
  .vwmx-sm-p80 - Sets max-width to p80 on sm

  Styleguide Width - p80 - sm
  */
  /*
  .vwc[mn,mx]-sm-p80

  Sets the width, max-width, or min-width of an elements immediate children to css var for p80 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p80 - Sets width of immediate children to p80 on sm
  .vwcmn-sm-p80 - Sets min-width of immediate children to p80 on sm
  .vwcmx-sm-p80 - Sets max-width of immediate children to p80 on sm

  Styleguide Child Width - p80 - sm
  */
  .vw-sm-p80,
  .vwc-sm-p80 > * {
    width: var(--width-p80);
  }

  .vwmn-sm-p80,
  .vwcmn-sm-p80 > * {
    min-width: var(--width-p80);
  }

  .vwmx-sm-p80,
  .vwcmx-sm-p80 > * {
    max-width: var(--width-p80);
  }

  /*
  .vw[mn,mx]-sm-p90

  Sets the width, max-width, or min-width of element to css var for p90 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p90 - Sets width to p90 on sm
  .vwmn-sm-p90 - Sets min-width to p90 on sm
  .vwmx-sm-p90 - Sets max-width to p90 on sm

  Styleguide Width - p90 - sm
  */
  /*
  .vwc[mn,mx]-sm-p90

  Sets the width, max-width, or min-width of an elements immediate children to css var for p90 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p90 - Sets width of immediate children to p90 on sm
  .vwcmn-sm-p90 - Sets min-width of immediate children to p90 on sm
  .vwcmx-sm-p90 - Sets max-width of immediate children to p90 on sm

  Styleguide Child Width - p90 - sm
  */
  .vw-sm-p90,
  .vwc-sm-p90 > * {
    width: var(--width-p90);
  }

  .vwmn-sm-p90,
  .vwcmn-sm-p90 > * {
    min-width: var(--width-p90);
  }

  .vwmx-sm-p90,
  .vwcmx-sm-p90 > * {
    max-width: var(--width-p90);
  }

  /*
  .vw[mn,mx]-sm-p100

  Sets the width, max-width, or min-width of element to css var for p100 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-p100 - Sets width to p100 on sm
  .vwmn-sm-p100 - Sets min-width to p100 on sm
  .vwmx-sm-p100 - Sets max-width to p100 on sm

  Styleguide Width - p100 - sm
  */
  /*
  .vwc[mn,mx]-sm-p100

  Sets the width, max-width, or min-width of an elements immediate children to css var for p100 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-p100 - Sets width of immediate children to p100 on sm
  .vwcmn-sm-p100 - Sets min-width of immediate children to p100 on sm
  .vwcmx-sm-p100 - Sets max-width of immediate children to p100 on sm

  Styleguide Child Width - p100 - sm
  */
  .vw-sm-p100,
  .vwc-sm-p100 > * {
    width: var(--width-p100);
  }

  .vwmn-sm-p100,
  .vwcmn-sm-p100 > * {
    min-width: var(--width-p100);
  }

  .vwmx-sm-p100,
  .vwcmx-sm-p100 > * {
    max-width: var(--width-p100);
  }

  /*
  .vw[mn,mx]-sm-ch0

  Sets the width, max-width, or min-width of element to css var for ch0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-ch0 - Sets width to ch0 on sm
  .vwmn-sm-ch0 - Sets min-width to ch0 on sm
  .vwmx-sm-ch0 - Sets max-width to ch0 on sm

  Styleguide Width - ch0 - sm
  */
  /*
  .vwc[mn,mx]-sm-ch0

  Sets the width, max-width, or min-width of an elements immediate children to css var for ch0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-ch0 - Sets width of immediate children to ch0 on sm
  .vwcmn-sm-ch0 - Sets min-width of immediate children to ch0 on sm
  .vwcmx-sm-ch0 - Sets max-width of immediate children to ch0 on sm

  Styleguide Child Width - ch0 - sm
  */
  .vw-sm-ch0,
  .vwc-sm-ch0 > * {
    width: var(--width-ch0);
  }

  .vwmn-sm-ch0,
  .vwcmn-sm-ch0 > * {
    min-width: var(--width-ch0);
  }

  .vwmx-sm-ch0,
  .vwcmx-sm-ch0 > * {
    max-width: var(--width-ch0);
  }

  /*
  .vw[mn,mx]-sm-ch1

  Sets the width, max-width, or min-width of element to css var for ch1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-ch1 - Sets width to ch1 on sm
  .vwmn-sm-ch1 - Sets min-width to ch1 on sm
  .vwmx-sm-ch1 - Sets max-width to ch1 on sm

  Styleguide Width - ch1 - sm
  */
  /*
  .vwc[mn,mx]-sm-ch1

  Sets the width, max-width, or min-width of an elements immediate children to css var for ch1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-ch1 - Sets width of immediate children to ch1 on sm
  .vwcmn-sm-ch1 - Sets min-width of immediate children to ch1 on sm
  .vwcmx-sm-ch1 - Sets max-width of immediate children to ch1 on sm

  Styleguide Child Width - ch1 - sm
  */
  .vw-sm-ch1,
  .vwc-sm-ch1 > * {
    width: var(--width-ch1);
  }

  .vwmn-sm-ch1,
  .vwcmn-sm-ch1 > * {
    min-width: var(--width-ch1);
  }

  .vwmx-sm-ch1,
  .vwcmx-sm-ch1 > * {
    max-width: var(--width-ch1);
  }

  /*
  .vw[mn,mx]-sm-ch2

  Sets the width, max-width, or min-width of element to css var for ch2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-ch2 - Sets width to ch2 on sm
  .vwmn-sm-ch2 - Sets min-width to ch2 on sm
  .vwmx-sm-ch2 - Sets max-width to ch2 on sm

  Styleguide Width - ch2 - sm
  */
  /*
  .vwc[mn,mx]-sm-ch2

  Sets the width, max-width, or min-width of an elements immediate children to css var for ch2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-ch2 - Sets width of immediate children to ch2 on sm
  .vwcmn-sm-ch2 - Sets min-width of immediate children to ch2 on sm
  .vwcmx-sm-ch2 - Sets max-width of immediate children to ch2 on sm

  Styleguide Child Width - ch2 - sm
  */
  .vw-sm-ch2,
  .vwc-sm-ch2 > * {
    width: var(--width-ch2);
  }

  .vwmn-sm-ch2,
  .vwcmn-sm-ch2 > * {
    min-width: var(--width-ch2);
  }

  .vwmx-sm-ch2,
  .vwcmx-sm-ch2 > * {
    max-width: var(--width-ch2);
  }

  /*
  .vw[mn,mx]-sm-ch3

  Sets the width, max-width, or min-width of element to css var for ch3 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-ch3 - Sets width to ch3 on sm
  .vwmn-sm-ch3 - Sets min-width to ch3 on sm
  .vwmx-sm-ch3 - Sets max-width to ch3 on sm

  Styleguide Width - ch3 - sm
  */
  /*
  .vwc[mn,mx]-sm-ch3

  Sets the width, max-width, or min-width of an elements immediate children to css var for ch3 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-ch3 - Sets width of immediate children to ch3 on sm
  .vwcmn-sm-ch3 - Sets min-width of immediate children to ch3 on sm
  .vwcmx-sm-ch3 - Sets max-width of immediate children to ch3 on sm

  Styleguide Child Width - ch3 - sm
  */
  .vw-sm-ch3,
  .vwc-sm-ch3 > * {
    width: var(--width-ch3);
  }

  .vwmn-sm-ch3,
  .vwcmn-sm-ch3 > * {
    min-width: var(--width-ch3);
  }

  .vwmx-sm-ch3,
  .vwcmx-sm-ch3 > * {
    max-width: var(--width-ch3);
  }

  /*
  .vw[mn,mx]-sm-ch4

  Sets the width, max-width, or min-width of element to css var for ch4 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-ch4 - Sets width to ch4 on sm
  .vwmn-sm-ch4 - Sets min-width to ch4 on sm
  .vwmx-sm-ch4 - Sets max-width to ch4 on sm

  Styleguide Width - ch4 - sm
  */
  /*
  .vwc[mn,mx]-sm-ch4

  Sets the width, max-width, or min-width of an elements immediate children to css var for ch4 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-ch4 - Sets width of immediate children to ch4 on sm
  .vwcmn-sm-ch4 - Sets min-width of immediate children to ch4 on sm
  .vwcmx-sm-ch4 - Sets max-width of immediate children to ch4 on sm

  Styleguide Child Width - ch4 - sm
  */
  .vw-sm-ch4,
  .vwc-sm-ch4 > * {
    width: var(--width-ch4);
  }

  .vwmn-sm-ch4,
  .vwcmn-sm-ch4 > * {
    min-width: var(--width-ch4);
  }

  .vwmx-sm-ch4,
  .vwcmx-sm-ch4 > * {
    max-width: var(--width-ch4);
  }

  /*
  .vw[mn,mx]-sm-px0

  Sets the width, max-width, or min-width of element to css var for px0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-px0 - Sets width to px0 on sm
  .vwmn-sm-px0 - Sets min-width to px0 on sm
  .vwmx-sm-px0 - Sets max-width to px0 on sm

  Styleguide Width - px0 - sm
  */
  /*
  .vwc[mn,mx]-sm-px0

  Sets the width, max-width, or min-width of an elements immediate children to css var for px0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-px0 - Sets width of immediate children to px0 on sm
  .vwcmn-sm-px0 - Sets min-width of immediate children to px0 on sm
  .vwcmx-sm-px0 - Sets max-width of immediate children to px0 on sm

  Styleguide Child Width - px0 - sm
  */
  .vw-sm-px0,
  .vwc-sm-px0 > * {
    width: var(--width-px0);
  }

  .vwmn-sm-px0,
  .vwcmn-sm-px0 > * {
    min-width: var(--width-px0);
  }

  .vwmx-sm-px0,
  .vwcmx-sm-px0 > * {
    max-width: var(--width-px0);
  }

  /*
  .vw[mn,mx]-sm-px1

  Sets the width, max-width, or min-width of element to css var for px1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-px1 - Sets width to px1 on sm
  .vwmn-sm-px1 - Sets min-width to px1 on sm
  .vwmx-sm-px1 - Sets max-width to px1 on sm

  Styleguide Width - px1 - sm
  */
  /*
  .vwc[mn,mx]-sm-px1

  Sets the width, max-width, or min-width of an elements immediate children to css var for px1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-px1 - Sets width of immediate children to px1 on sm
  .vwcmn-sm-px1 - Sets min-width of immediate children to px1 on sm
  .vwcmx-sm-px1 - Sets max-width of immediate children to px1 on sm

  Styleguide Child Width - px1 - sm
  */
  .vw-sm-px1,
  .vwc-sm-px1 > * {
    width: var(--width-px1);
  }

  .vwmn-sm-px1,
  .vwcmn-sm-px1 > * {
    min-width: var(--width-px1);
  }

  .vwmx-sm-px1,
  .vwcmx-sm-px1 > * {
    max-width: var(--width-px1);
  }

  /*
  .vw[mn,mx]-sm-px2

  Sets the width, max-width, or min-width of element to css var for px2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-px2 - Sets width to px2 on sm
  .vwmn-sm-px2 - Sets min-width to px2 on sm
  .vwmx-sm-px2 - Sets max-width to px2 on sm

  Styleguide Width - px2 - sm
  */
  /*
  .vwc[mn,mx]-sm-px2

  Sets the width, max-width, or min-width of an elements immediate children to css var for px2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-px2 - Sets width of immediate children to px2 on sm
  .vwcmn-sm-px2 - Sets min-width of immediate children to px2 on sm
  .vwcmx-sm-px2 - Sets max-width of immediate children to px2 on sm

  Styleguide Child Width - px2 - sm
  */
  .vw-sm-px2,
  .vwc-sm-px2 > * {
    width: var(--width-px2);
  }

  .vwmn-sm-px2,
  .vwcmn-sm-px2 > * {
    min-width: var(--width-px2);
  }

  .vwmx-sm-px2,
  .vwcmx-sm-px2 > * {
    max-width: var(--width-px2);
  }

  /*
  .vw[mn,mx]-sm-px3

  Sets the width, max-width, or min-width of element to css var for px3 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-px3 - Sets width to px3 on sm
  .vwmn-sm-px3 - Sets min-width to px3 on sm
  .vwmx-sm-px3 - Sets max-width to px3 on sm

  Styleguide Width - px3 - sm
  */
  /*
  .vwc[mn,mx]-sm-px3

  Sets the width, max-width, or min-width of an elements immediate children to css var for px3 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-px3 - Sets width of immediate children to px3 on sm
  .vwcmn-sm-px3 - Sets min-width of immediate children to px3 on sm
  .vwcmx-sm-px3 - Sets max-width of immediate children to px3 on sm

  Styleguide Child Width - px3 - sm
  */
  .vw-sm-px3,
  .vwc-sm-px3 > * {
    width: var(--width-px3);
  }

  .vwmn-sm-px3,
  .vwcmn-sm-px3 > * {
    min-width: var(--width-px3);
  }

  .vwmx-sm-px3,
  .vwcmx-sm-px3 > * {
    max-width: var(--width-px3);
  }

  /*
  .vw[mn,mx]-sm-em0

  Sets the width, max-width, or min-width of element to css var for em0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-em0 - Sets width to em0 on sm
  .vwmn-sm-em0 - Sets min-width to em0 on sm
  .vwmx-sm-em0 - Sets max-width to em0 on sm

  Styleguide Width - em0 - sm
  */
  /*
  .vwc[mn,mx]-sm-em0

  Sets the width, max-width, or min-width of an elements immediate children to css var for em0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-em0 - Sets width of immediate children to em0 on sm
  .vwcmn-sm-em0 - Sets min-width of immediate children to em0 on sm
  .vwcmx-sm-em0 - Sets max-width of immediate children to em0 on sm

  Styleguide Child Width - em0 - sm
  */
  .vw-sm-em0,
  .vwc-sm-em0 > * {
    width: var(--width-em0);
  }

  .vwmn-sm-em0,
  .vwcmn-sm-em0 > * {
    min-width: var(--width-em0);
  }

  .vwmx-sm-em0,
  .vwcmx-sm-em0 > * {
    max-width: var(--width-em0);
  }

  /*
  .vw[mn,mx]-sm-em1

  Sets the width, max-width, or min-width of element to css var for em1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-em1 - Sets width to em1 on sm
  .vwmn-sm-em1 - Sets min-width to em1 on sm
  .vwmx-sm-em1 - Sets max-width to em1 on sm

  Styleguide Width - em1 - sm
  */
  /*
  .vwc[mn,mx]-sm-em1

  Sets the width, max-width, or min-width of an elements immediate children to css var for em1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-em1 - Sets width of immediate children to em1 on sm
  .vwcmn-sm-em1 - Sets min-width of immediate children to em1 on sm
  .vwcmx-sm-em1 - Sets max-width of immediate children to em1 on sm

  Styleguide Child Width - em1 - sm
  */
  .vw-sm-em1,
  .vwc-sm-em1 > * {
    width: var(--width-em1);
  }

  .vwmn-sm-em1,
  .vwcmn-sm-em1 > * {
    min-width: var(--width-em1);
  }

  .vwmx-sm-em1,
  .vwcmx-sm-em1 > * {
    max-width: var(--width-em1);
  }

  /*
  .vw[mn,mx]-sm-em2

  Sets the width, max-width, or min-width of element to css var for em2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-em2 - Sets width to em2 on sm
  .vwmn-sm-em2 - Sets min-width to em2 on sm
  .vwmx-sm-em2 - Sets max-width to em2 on sm

  Styleguide Width - em2 - sm
  */
  /*
  .vwc[mn,mx]-sm-em2

  Sets the width, max-width, or min-width of an elements immediate children to css var for em2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-em2 - Sets width of immediate children to em2 on sm
  .vwcmn-sm-em2 - Sets min-width of immediate children to em2 on sm
  .vwcmx-sm-em2 - Sets max-width of immediate children to em2 on sm

  Styleguide Child Width - em2 - sm
  */
  .vw-sm-em2,
  .vwc-sm-em2 > * {
    width: var(--width-em2);
  }

  .vwmn-sm-em2,
  .vwcmn-sm-em2 > * {
    min-width: var(--width-em2);
  }

  .vwmx-sm-em2,
  .vwcmx-sm-em2 > * {
    max-width: var(--width-em2);
  }

  /*
  .vw[mn,mx]-sm-rem0

  Sets the width, max-width, or min-width of element to css var for rem0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-rem0 - Sets width to rem0 on sm
  .vwmn-sm-rem0 - Sets min-width to rem0 on sm
  .vwmx-sm-rem0 - Sets max-width to rem0 on sm

  Styleguide Width - rem0 - sm
  */
  /*
  .vwc[mn,mx]-sm-rem0

  Sets the width, max-width, or min-width of an elements immediate children to css var for rem0 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-rem0 - Sets width of immediate children to rem0 on sm
  .vwcmn-sm-rem0 - Sets min-width of immediate children to rem0 on sm
  .vwcmx-sm-rem0 - Sets max-width of immediate children to rem0 on sm

  Styleguide Child Width - rem0 - sm
  */
  .vw-sm-rem0,
  .vwc-sm-rem0 > * {
    width: var(--width-rem0);
  }

  .vwmn-sm-rem0,
  .vwcmn-sm-rem0 > * {
    min-width: var(--width-rem0);
  }

  .vwmx-sm-rem0,
  .vwcmx-sm-rem0 > * {
    max-width: var(--width-rem0);
  }

  /*
  .vw[mn,mx]-sm-rem1

  Sets the width, max-width, or min-width of element to css var for rem1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-rem1 - Sets width to rem1 on sm
  .vwmn-sm-rem1 - Sets min-width to rem1 on sm
  .vwmx-sm-rem1 - Sets max-width to rem1 on sm

  Styleguide Width - rem1 - sm
  */
  /*
  .vwc[mn,mx]-sm-rem1

  Sets the width, max-width, or min-width of an elements immediate children to css var for rem1 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-rem1 - Sets width of immediate children to rem1 on sm
  .vwcmn-sm-rem1 - Sets min-width of immediate children to rem1 on sm
  .vwcmx-sm-rem1 - Sets max-width of immediate children to rem1 on sm

  Styleguide Child Width - rem1 - sm
  */
  .vw-sm-rem1,
  .vwc-sm-rem1 > * {
    width: var(--width-rem1);
  }

  .vwmn-sm-rem1,
  .vwcmn-sm-rem1 > * {
    min-width: var(--width-rem1);
  }

  .vwmx-sm-rem1,
  .vwcmx-sm-rem1 > * {
    max-width: var(--width-rem1);
  }

  /*
  .vw[mn,mx]-sm-rem2

  Sets the width, max-width, or min-width of element to css var for rem2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}">A div</div>

  .vw-sm-rem2 - Sets width to rem2 on sm
  .vwmn-sm-rem2 - Sets min-width to rem2 on sm
  .vwmx-sm-rem2 - Sets max-width to rem2 on sm

  Styleguide Width - rem2 - sm
  */
  /*
  .vwc[mn,mx]-sm-rem2

  Sets the width, max-width, or min-width of an elements immediate children to css var for rem2 on sm.

  Markup:
  <div class="bwa-1 {{modifier_class}}"><div class="bwa-1">A div</div><div class="bwa-1">B div</div></div>

  .vwc-sm-rem2 - Sets width of immediate children to rem2 on sm
  .vwcmn-sm-rem2 - Sets min-width of immediate children to rem2 on sm
  .vwcmx-sm-rem2 - Sets max-width of immediate children to rem2 on sm

  Styleguide Child Width - rem2 - sm
  */
  .vw-sm-rem2,
  .vwc-sm-rem2 > * {
    width: var(--width-rem2);
  }

  .vwmn-sm-rem2,
  .vwcmn-sm-rem2 > * {
    min-width: var(--width-rem2);
  }

  .vwmx-sm-rem2,
  .vwcmx-sm-rem2 > * {
    max-width: var(--width-rem2);
  }
}

/**
 * Additional Utilities
 */
.vw-px768,
.vwc-px768 > * {
  width: 768px;
}

.vwmn-px768,
.vwcmn-px768 > * {
  min-width: 768px;
}

.vwmx-px768,
.vwcmx-px768 > * {
  max-width: 768px;
}

/*
  .fld-dsk-col

  Sets display: flex and sets flex-direction of an element to column on desktop.

  Markup:
  <div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

  .fld-dsk-col - Sets flex-direction to column on desktop

  Styleguide Flex - Direction - col - dsk
  */
@media screen and (min-width: 768px) {
  .fld-dsk-col {
    display: flex;
    flex-direction: column;
  }
}

/*
  .fld-dsk-row

  Sets display: flex and sets flex-direction of an element to row on desktop.

  Markup:
  <div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

  .fld-dsk-row - Sets flex-direction to row on desktop

  Styleguide Flex - Direction - row - dsk
  */
@media screen and (min-width: 768px) {
  .fld-dsk-row {
    display: flex;
    flex-direction: row;
  }
}
/*
  .fld-dsk-rowr

  Sets display: flex and sets flex-direction of an element to row reverse on desktop.

  Markup:
  <div class="{{modifier_class}}"><div>A div</div><div>B div</div></div>

  .fld-dsk-rowr - Sets flex-direction to row reverse on desktop

  Styleguide Flex - Direction - row - dsk
  */
@media screen and (min-width: 768px) {
  .fld-dsk-rowr {
    display: flex;
    flex-direction: row-reverse;
  }
}

/*
  .ai-dsk-str

  Sets align-items to flex-start on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .ai-dsk-str - Sets align-items to flex-start on desktop

  Styleguide Flex - Align Items - str
*/

@media screen and (min-width: 768px) {
  .ai-dsk-str {
    align-items: flex-start;
  }
}

/*
  .as-dsk-str

  Sets align-items to start on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-dsk-str - Sets align-items to start on desktop

  Styleguide Flex - Align Items - start
*/

@media screen and (min-width: 768px) {
  .as-dsk-str {
    align-self: flex-start;
  }
}

/*
  .jc-dsk-str

  Sets justify-content to flex-start on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-dsk-str - Sets justify-content to flex-start on desktop

  Styleguide Flex - Align Items - str
*/

@media screen and (min-width: 768px) {
  .jc-dsk-str {
    justify-content: flex-start;
  }
}

/*
  .ai-dsk-end

  Sets align-items to flex-end on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .ai-dsk-end - Sets align-items to flex-end on desktop

  Styleguide Flex - Align Items - end
*/

@media screen and (min-width: 768px) {
  .ai-dsk-end {
    align-items: flex-end;
  }
}

/*
  .jc-dsk-end

  Sets justify-content to flex-end on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-dsk-end - Sets justify-content to flex-end on desktop

  Styleguide Flex - Align Items - end
*/

@media screen and (min-width: 768px) {
  .jc-dsk-end {
    justify-content: flex-end;
  }
}

/*
  .ai-dsk-ctr

  Sets align-items to center on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .ai-dsk-ctr - Sets align-items to center on desktop

  Styleguide Flex - Align Items - center
*/

@media screen and (min-width: 768px) {
  .ai-dsk-ctr {
    align-items: center;
  }
}

/*
  .as-dsk-ctr

  Sets align-self to center on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .as-dsk-ctr - Sets align-selfto center on desktop

  Styleguide Flex - Align Self - center
*/

@media screen and (min-width: 768px) {
  .as-dsk-ctr {
    align-self: center;
  }
}

/*
  .jc-dsk-ctr

  Sets justify-content to center on desktop.

  Markup:
  <div class="fld-row {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>
  <div class="fld-col {{modifier_class}}"><div class="fls-1-1">A div</div><div class="fls-1-1">B div</div><div class="fls-1-1">C div</div></div>

  .jc-dsk-ctr - Sets justify-content to center on desktop

  Styleguide Flex - Align Items - center
*/

@media screen and (min-width: 768px) {
  .jc-dsk-ctr {
    justify-content: center;
  }
}

/*
  some height / width adjusters for desktop-only:
*/
@media screen and (min-width: 768px) {
  .vh-dsk-p60 {
    height: var(--height-p60);
  }
  .vh-dsk-p75 {
    height: var(--height-p75);
  }
  .vh-dsk-p100 {
    height: var(--height-p100);
  }
  .vh-dsk-p100,
  .vhc-dsk-p100 > * {
    height: var(--height-p100);
  }

  .w-dsk-unset {
    width: unset !important;
  }

  .fld-dsk-colr {
    display: flex;
    flex-direction: column-reverse;
  }
}

.ds-dsk-only {
  display: none;
}

.ds-mbl-only {
  display: initial;
}

@media screen and (min-width: 768px) {
  .ds-dsk-only {
    display: initial;
  }

  .ds-mbl-only {
    display: none;
  }

  .bg-dsk-none {
    background: none;
    background-image: none !important;
  }

  .as-dsk-end {
    align-self: end;
  }
}

.bg-gray {
  background-color: var(--qq-color-light);
}
